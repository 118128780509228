import React, { FC } from "react";
import { connect } from "react-redux";

import smallPlusIcon from "../../assets/icons/small-plus.png";
import { getHexCardColorByType } from "../../helpers/bpLayers";
import { DashboardIcon } from "../core/icons/dashboard";
import { CardTag } from "./tag";
import { Dispatch, RootState } from "../../store/store";

interface ILayerCard {
  style: string;
  data: any;
  onAdd: any;
  isEditor: boolean;
  selectedActivities: string[];
  setActiveActivity?: any;
  removeActiveActivity? :any;
}

const LayerCard: FC<ILayerCard> = ({ style, data, isEditor, onAdd, selectedActivities, setActiveActivity, removeActiveActivity }) => {
  const cardData = {...data, ...data.card_type}
  const color = getHexCardColorByType(cardData.type);
  const icon = DashboardIcon[cardData.type][cardData.position]
  const onAddOverwrited = isEditor ? onAdd : null;

  const renderTag = (tag: any) => {
    let isSelected = false;
    tag?.activities?.forEach((activity: any) => {
      if (selectedActivities.includes(activity?.id)) isSelected = true;
    });
    const selectedActivityIds = tag?.activities?.map((activity: any) => activity.id) || [];
    const onMouseEnter = () => setActiveActivity(selectedActivityIds);
    const onMouseLeave = () => removeActiveActivity(selectedActivityIds);
    return <CardTag isSelected={isSelected} open={onAddOverwrited} key={tag.id} data={tag} cardData={data} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
  }

  return (
    <div
      style={{background: color}}
      className={`box font-quickStand rounded-lg min-w-full min-h-full shadow-md ${style}`}
      >
      <div className="relative h-full">
        <div className="p-2 flex items-center justify-between">
          <h3 className="text-1xl font-bold">{cardData?.title || ''}</h3>
          {icon}
        </div>

        <div className="tags-wrap my-2 mx-0 flex pl-2 left-0 right-2 flex-wrap content-start top-10 bottom-10 overflow-x-hidden absolute">
          {cardData?.tags.map(renderTag)}
        </div>

        {isEditor && (
          <div className="text-1xl font-bold flex justify-end items-center absolute right-2 bottom-2">
            <p>Tilføj</p>
            <div
              onClick={onAdd.bind(null, { card_id: cardData.card_id, card_title: cardData.title, card_description: cardData.description })}
              style={{background: "#0000001c"}}
              className="ml-2 cursor-pointer w-9 h-9 flex items-center justify-center rounded-full"
            >
              <img src={smallPlusIcon} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapState = (state: RootState) => ({
  isEditor: state.user.isEditor,
  selectedActivities: state.layer?.selectedActivities || [],
});

const mapDispatch = (dispatch: Dispatch) => ({
  setActiveActivity: dispatch.layer.setActiveActivity,
  removeActiveActivity: dispatch.layer.removeActiveActivity
});

export default connect(mapState, mapDispatch)(LayerCard);
