import React from "react";

export const NotFound404 = () => {
  return (
    <div className="h-fit flex justify-center items-center flex-grow">
    <div className="place-self-center text-center mb-32">
      <h1 className="text-9xl font-bold text-gray-300 mb-4">404</h1>
    </div>
  </div>
  );
}
