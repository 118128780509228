import { DASHBOARD_ICON_TYPES } from "../../../../constants/ui";
import { LAYER } from "../../../../helpers/bpLayers";
import { IconEight } from "./IconEight";
import { IconFive } from "./IconFive";
import { IconFour } from "./IconFour";
import { IconNine } from "./IconNine";
import { IconOne } from "./IconOne";
import { IconSeven } from "./IconSeven";
import { IconSix } from "./IconSix";
import { IconThree } from "./IconThree";
import { IconTwo } from "./IconTwo";

export const DashboardIcon: any = {
  [LAYER.BLUE_LAYER]: {
    1: <IconOne type={DASHBOARD_ICON_TYPES.BLUE} />,
    2: <IconTwo type={DASHBOARD_ICON_TYPES.BLUE} />,
    3: <IconThree type={DASHBOARD_ICON_TYPES.BLUE} />,
    4: <IconFour type={DASHBOARD_ICON_TYPES.BLUE} />,
    5: <IconFive type={DASHBOARD_ICON_TYPES.BLUE} />,
    6: <IconSix type={DASHBOARD_ICON_TYPES.BLUE} />,
    7: <IconSeven type={DASHBOARD_ICON_TYPES.BLUE} />,
    8: <IconEight type={DASHBOARD_ICON_TYPES.BLUE} />,
    9: <IconNine type={DASHBOARD_ICON_TYPES.BLUE} />,
  },
  [LAYER.GREEN_LAYER]: {
    1: <IconOne type={DASHBOARD_ICON_TYPES.GREEN} />,
    2: <IconTwo type={DASHBOARD_ICON_TYPES.GREEN} />,
    3: <IconThree type={DASHBOARD_ICON_TYPES.GREEN} />,
    4: <IconFour type={DASHBOARD_ICON_TYPES.GREEN} />,
    5: <IconFive type={DASHBOARD_ICON_TYPES.GREEN} />,
    6: <IconSix type={DASHBOARD_ICON_TYPES.GREEN} />,
    7: <IconSeven type={DASHBOARD_ICON_TYPES.GREEN} />,
    8: <IconEight type={DASHBOARD_ICON_TYPES.GREEN} />,
    9: <IconNine type={DASHBOARD_ICON_TYPES.GREEN} />,
  },
  [LAYER.YELLOW_LAEYR]: {
    1: <IconOne type={DASHBOARD_ICON_TYPES.YELLOW} />,
    2: <IconTwo type={DASHBOARD_ICON_TYPES.YELLOW} />,
    3: <IconThree type={DASHBOARD_ICON_TYPES.YELLOW} />,
    4: <IconFour type={DASHBOARD_ICON_TYPES.YELLOW} />,
    5: <IconFive type={DASHBOARD_ICON_TYPES.YELLOW} />,
    6: <IconSix type={DASHBOARD_ICON_TYPES.YELLOW} />,
    7: <IconSeven type={DASHBOARD_ICON_TYPES.YELLOW} />,
    8: <IconEight type={DASHBOARD_ICON_TYPES.YELLOW} />,
    9: <IconNine type={DASHBOARD_ICON_TYPES.YELLOW} />,
  },
}