import React from "react";

export interface Props {
  isActive: boolean;
}

export const IconFive: React.FC<Props> = ({ isActive = false }: Props) => {
  return (
    <>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 170.08 170.08" style={{ ['enableBackground' as any]: 'new 0 0 170.08 170.08', 'filter': 'none' }} xmlSpace="preserve">
        <style type="text/css">
          {`  .st05{fill:${isActive ? '#ef402c' : '#B2B2B2'};}
            svg:hover .st05{fill:#ef402c;}
        .st1{fill:#FFFFFF;}`}
        </style>
        <rect className="st05" width="170.08" height="170.08" />
        <g>
          <path className="st1" d="M27.12,25.11c-2.27,0-3.94,0.81-4.86,2.38h-0.05v-8.55h9.91v-4.7H16.95v19.32h5.26v-0.76
		c0-2.02,0.91-3.14,2.53-3.14c1.77,0,2.48,1.22,2.48,3.09v8.29c0,1.82-0.66,3.09-2.43,3.09c-1.97,0-2.68-1.32-2.68-3.34v-4.3h-5.26
		v3.89c0,5.21,2.07,8.55,7.94,8.55c5.82,0,8.19-3.09,8.19-7.84v-8.4C32.99,27.28,30.21,25.11,27.12,25.11"/>
          <path className="st1" d="M123.88,64.35h-21.14c-0.14,0-0.27,0.12-0.27,0.27v7.02c0,0.15,0.12,0.27,0.27,0.27h8.42l-9.35,9.35
		c-4.71-3.7-10.63-5.91-17.07-5.91c-15.26,0-27.69,12.42-27.69,27.69c0,14.06,10.53,25.69,24.11,27.45v8.09h-8.34
		c-0.15,0-0.27,0.12-0.27,0.27v7.53c0,0.15,0.12,0.27,0.27,0.27h8.34v7.97c0,0.15,0.12,0.27,0.27,0.27h7.53
		c0.15,0,0.27-0.12,0.27-0.27v-7.97h8.34c0.15,0,0.27-0.12,0.27-0.27v-7.53c0-0.15-0.12-0.27-0.27-0.27h-8.34v-8.23
		c13.14-2.15,23.19-13.58,23.19-27.32c0-5.93-1.88-11.42-5.06-15.92l9.21-9.21v7.86c0,0.15,0.12,0.27,0.27,0.27h7.02
		c0.15,0,0.27-0.12,0.27-0.27V64.62C124.16,64.47,124.03,64.35,123.88,64.35 M84.75,123.42c-11.24,0-20.38-9.14-20.38-20.38
		c0-11.24,9.14-20.38,20.38-20.38c11.24,0,20.38,9.14,20.38,20.38C105.13,114.28,95.98,123.42,84.75,123.42"/>
          <path className="st1" d="M97.19,94.67H72.48c-0.15,0-0.27,0.11-0.27,0.24v5.98c0,0.14,0.12,0.25,0.27,0.25h24.72
		c0.15,0,0.27-0.11,0.27-0.25v-5.98C97.47,94.78,97.35,94.67,97.19,94.67"/>
          <path className="st1" d="M97.19,105.36H72.48c-0.15,0-0.27,0.11-0.27,0.25v5.98c0,0.13,0.12,0.24,0.27,0.24h24.72
		c0.15,0,0.27-0.11,0.27-0.24v-5.98C97.47,105.47,97.35,105.36,97.19,105.36"/>
        </g>
        <polygon className="st1" points="48.89,28.15 54.16,28.15 54.16,26.19 51.29,26.19 51.29,13.85 48.89,13.85 " />
        <rect x="55.26" y="13.85" className="st1" width="2.4" height="14.3" />
        <g>
          <path className="st1" d="M62.68,22.41h1.14v2.53c0,0.93-0.4,1.37-1.18,1.37c-0.82,0-1.18-0.61-1.18-1.48v-7.68
		c0-0.87,0.38-1.48,1.24-1.48s1.2,0.51,1.2,1.39v1.46h2.19v-1.27c0-2.11-0.78-3.59-3.44-3.59c-2.53,0-3.61,1.62-3.61,3.86v6.92
		c0,2.21,0.95,3.86,3.02,3.86c1.31,0,1.98-0.65,2.3-1.73v1.56h1.73v-7.64h-3.42V22.41z"/>
          <polygon className="st1" points="67.89,28.15 73.63,28.15 73.63,26.19 70.3,26.19 70.3,21.85 72.68,21.85 72.68,19.9 70.3,19.9
		70.3,15.81 73.63,15.81 73.63,13.85 67.89,13.85 	"/>
          <path className="st1" d="M78.14,28.32c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.68-1.14-1.14-1.14-1.94V16.8c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.48c0,1.64,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.03
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96h-2.24v1.92C74.75,26.95,75.69,28.32,78.14,28.32"/>
          <polygon className="st1" points="82.59,15.81 84.57,15.81 84.57,28.15 87,28.15 87,15.81 88.98,15.81 88.98,13.85 82.59,13.85 	" />
          <rect x="90.06" y="13.85" className="st1" width="2.4" height="14.3" />
          <polygon className="st1" points="93.98,28.15 99.25,28.15 99.25,26.19 96.38,26.19 96.38,13.85 93.98,13.85 	" />
          <polygon className="st1" points="100.35,28.15 105.62,28.15 105.62,26.19 102.75,26.19 102.75,13.85 100.35,13.85 	" />
          <rect x="106.72" y="13.85" className="st1" width="2.4" height="14.3" />
          <polygon className="st1" points="115.79,28.15 117.9,28.15 117.9,13.85 115.89,13.85 115.89,22.06 113.3,13.85 110.94,13.85
		110.94,28.15 112.96,28.15 112.96,19.17 	"/>
          <path className="st1" d="M122.9,22.41h1.14v2.53c0,0.93-0.4,1.37-1.18,1.37c-0.82,0-1.18-0.61-1.18-1.48v-7.68
		c0-0.87,0.38-1.48,1.24-1.48s1.2,0.51,1.2,1.39v1.46h2.19v-1.27c0-2.11-0.78-3.59-3.44-3.59c-2.53,0-3.61,1.62-3.61,3.86v6.92
		c0,2.21,0.95,3.86,3.02,3.86c1.31,0,1.98-0.65,2.3-1.73v1.56h1.73v-7.64h-3.42V22.41z"/>
          <polygon className="st1" points="55.51,48.57 57.7,48.57 57.7,34.27 54.67,34.27 53.27,42.32 53.25,42.32 51.69,34.27 48.89,34.27
		48.89,48.57 50.87,48.57 50.87,39.29 50.91,39.29 52.71,48.57 53.82,48.57 55.45,39.29 55.51,39.29 	"/>
          <polygon className="st1" points="59.08,48.57 64.81,48.57 64.81,46.61 61.48,46.61 61.48,42.26 63.86,42.26 63.86,40.32 61.48,40.32
		61.48,36.23 64.81,36.23 64.81,34.27 59.08,34.27 	"/>
          <polygon className="st1" points="66.1,48.57 71.37,48.57 71.37,46.61 68.5,46.61 68.5,34.27 66.1,34.27 	" />
          <polygon className="st1" points="72.47,48.57 77.74,48.57 77.74,46.61 74.87,46.61 74.87,34.27 72.47,34.27 	" />
          <polygon className="st1" points="78.84,48.57 84.57,48.57 84.57,46.61 81.24,46.61 81.24,42.26 83.62,42.26 83.62,40.32 81.24,40.32
		81.24,36.23 84.57,36.23 84.57,34.27 78.84,34.27 	"/>
          <polygon className="st1" points="92.48,48.57 94.68,48.57 94.68,34.27 91.64,34.27 90.25,42.32 90.23,42.32 88.67,34.27 85.86,34.27
		85.86,48.57 87.84,48.57 87.84,39.29 87.88,39.29 89.68,48.57 90.8,48.57 92.42,39.29 92.48,39.29 	"/>
          <polygon className="st1" points="103.18,48.57 105.71,48.57 102.86,40.7 105.64,34.27 103.28,34.27 100.67,40.55 100.67,34.27
		98.26,34.27 98.26,48.57 100.67,48.57 100.67,41.61 	"/>
          <path className="st1" d="M109.71,46.79c-0.7,0-1.14-0.4-1.27-1.03l2.59-6.26v5.82C111.04,46.18,110.58,46.79,109.71,46.79
		 M108.41,37.52c0-0.87,0.46-1.48,1.31-1.48c0.72,0,1.16,0.42,1.29,1.08l-2.59,6.24V37.52z M109.71,48.74
		c2.55,0,3.65-1.64,3.65-3.86v-6.92c0-0.97-0.21-1.83-0.65-2.49l0.65-1.56h-1.03l-0.34,0.82c-0.57-0.4-1.33-0.63-2.28-0.63
		c-2.53,0-3.63,1.62-3.63,3.86v6.92c0,0.97,0.21,1.83,0.65,2.49l-0.65,1.58h1.03l0.34-0.82C108.03,48.53,108.76,48.74,109.71,48.74"
          />
          <polygon className="st1" points="119.58,48.57 121.69,48.57 121.69,34.27 119.69,34.27 119.69,42.47 117.1,34.27 114.73,34.27
		114.73,48.57 116.76,48.57 116.76,39.58 	"/>
          <polygon className="st1" points="127.92,48.57 130.03,48.57 130.03,34.27 128.02,34.27 128.02,42.47 125.43,34.27 123.06,34.27
		123.06,48.57 125.09,48.57 125.09,39.58 	"/>
          <polygon className="st1" points="131.4,48.57 137.13,48.57 137.13,46.61 133.8,46.61 133.8,42.26 136.18,42.26 136.18,40.32
		133.8,40.32 133.8,36.23 137.13,36.23 137.13,34.27 131.4,34.27 	"/>
          <polygon className="st1" points="143.27,48.57 145.38,48.57 145.38,34.27 143.37,34.27 143.37,42.47 140.78,34.27 138.42,34.27
		138.42,48.57 140.44,48.57 140.44,39.58 	"/>
          <polygon className="st1" points="146.75,48.57 152.49,48.57 152.49,46.61 149.15,46.61 149.15,42.26 151.54,42.26 151.54,40.32
		149.15,40.32 149.15,36.23 152.49,36.23 152.49,34.27 146.75,34.27 	"/>
        </g>
      </svg>
    </>
  );
};
