import React from "react";

import { Modal } from "../../Modal";
import { LeftColorPickerComponent } from "../common/LeftColorPickerComponent";
import { RightComponent } from "./RightComponent";

export interface Props {
  showModal: boolean;
  title?: string;
  introduction: string;
  inputLabel: string;
  colorPickerLabel: string;
  textareaLabel: string;
  SubmitButtonText: string;
  FormSubmitBtnDisabled: boolean;
  CancelButtonText: string;
  FormCancelBtnDisabled: boolean;
  onSubmit?: (e: any) => void;
  data?: any;
  setShowModal?: () => void;
  bgColor: string;
}

export const ActivityModal: React.FC<Props> = ({ showModal, bgColor, ...props }: Props) => {
  return (
    <>
      <Modal
        LeftComponent={LeftColorPickerComponent}
        RightComponent={RightComponent}
        rightcomponentbackgroundcolor={bgColor}
        showModal={showModal}
        {...props}
      />
    </>
  );
};
