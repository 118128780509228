import React from "react";

export interface Props {
  isActive: boolean;
}

export const IconEight: React.FC<Props> = ({ isActive = false }: Props) => {
  return (
    <>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 170.08 170.08" style={{ ['enableBackground' as any]: 'new 0 0 170.08 170.08', 'filter': 'none' }} xmlSpace="preserve">
        <style type="text/css">{`
            .st08{fill:${isActive ? '#a31c43' : '#B2B2B2'};}
          svg:hover #SVGID_1_8{fill:#a31c43;}
          .st1{clip-path:url(#SVGID_00000118394056400613515570000006948021676109845417_);fill:#FFFFFF;}
          .st2{fill:#FFFFFF;}
        `}</style>
        <rect className="st08" width="170.08" height="170.08" />
        <g>
          <defs>
            <rect id="SVGID_1_8" x="0" y="0" width="170.07" height="170.07" />
          </defs>
          <use xlinkHref="#SVGID_1_8" style={{ 'overflow': 'visible', 'fill': isActive ? '#a31c43' : '#B2B2B2' }} />
          <clipPath id="SVGID_00000118394056400613515570000006948021676109845417_">
            <use xlinkHref="#SVGID_1_8" style={{ 'overflow': 'visible' }} />
          </clipPath>
          <path className="st1" d="M33.05,40.15v-3.74
		c0-3.29-1.16-5.66-3.84-6.88c2.27-1.11,3.34-3.13,3.34-5.97v-1.42c0-5.36-2.78-8.24-8.09-8.24c-5.26,0-7.99,2.88-7.99,8.24v1.42
		c0,2.78,1.01,4.86,3.24,5.97c-2.68,1.21-3.79,3.59-3.79,6.88v3.74c0,5.21,2.48,8.85,8.55,8.85C30.57,49.01,33.05,45.36,33.05,40.15
		 M21.87,21.8c0-2.08,0.71-3.29,2.58-3.29c1.92,0,2.63,1.21,2.63,3.29v2.32c0,2.07-0.71,3.29-2.63,3.29c-1.87,0-2.58-1.22-2.58-3.29
		V21.8z M27.28,40.76c0,2.07-0.76,3.54-2.83,3.54c-2.03,0-2.78-1.47-2.78-3.54V35.2c0-2.07,0.76-3.54,2.78-3.54
		c2.07,0,2.83,1.47,2.83,3.54V40.76z"/>
          <path className="st1" d="M117.31,83.67
		l-17.29,16.44c-0.65,0.65-0.99,1.13-0.99,2.47v46.09c0,0.54,0.44,0.98,0.99,0.98h17.86c0.54,0,0.98-0.44,0.98-0.98V84.09
		C118.87,83.55,118.44,82.55,117.31,83.67"/>
          <path className="st1" d="M67.51,105.1
		l-17.29,16.44c-0.65,0.65-0.99,1.13-0.99,2.47v24.67c0,0.54,0.44,0.98,0.99,0.98h17.86c0.55,0,0.98-0.44,0.98-0.98v-43.17
		C69.06,104.97,68.63,103.97,67.51,105.1"/>
          <path className="st1" d="M125.17,63.73
		c0.01-0.13-0.04-0.27-0.15-0.37c-0.09-0.09-0.21-0.13-0.32-0.14l-0.09,0.01l-0.14,0.01l-13.24,2.99l-0.24,0.02
		c-0.09,0.02-0.18,0.07-0.26,0.14c-0.2,0.2-0.2,0.51,0,0.71l0.15,0.15l3.02,3.02l-29.99,29.99L70.06,86.4
		c-0.16-0.16-0.41-0.16-0.56,0l-3.76,3.76c0,0,0,0,0,0l-22.21,22.2c-0.15,0.16-0.15,0.41,0,0.56l3.76,3.76
		c0.15,0.16,0.4,0.16,0.56,0l21.93-21.93l10.05,10.05c0.01,0.02,0.02,0.03,0.03,0.05l3.76,3.76c0.16,0.16,0.41,0.16,0.56,0
		l34.04-34.04l3.17,3.17c0.19,0.2,0.51,0.2,0.71,0c0.09-0.09,0.14-0.21,0.14-0.33l0-0.04l2.91-13.51L125.17,63.73z"/>
          <path className="st1" d="M92.51,107.99
		l-8.36,8.43c-0.16,0.13-0.39,0.13-0.54-0.02l-7.58-7.72c-1.39-1.38-1.69-0.26-1.69,0.28v39.72c0,0.54,0.44,0.98,0.98,0.98h17.86
		c0.54,0,0.98-0.44,0.98-0.98V108.3C94.16,107.75,93.74,106.76,92.51,107.99"/>
        </g>
        <path className="st2" d="M45.4,23.62h1.92l-0.95-5.74h-0.02L45.4,23.62z M48.08,28.16l-0.44-2.62h-2.55l-0.42,2.62h-2.28l2.7-14.3h2.74
	l2.72,14.3H48.08z"/>
        <polygon className="st2" points="56.39,28.16 53.56,19.17 53.56,28.16 51.54,28.16 51.54,13.86 53.9,13.86 56.49,22.06 56.49,13.86
	58.5,13.86 58.5,28.16 "/>
        <g>
          <path className="st2" d="M63.56,28.32c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.67-1.14-1.14-1.14-1.94v-0.3c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.49c0,1.64,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.02
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96h-2.24v1.92C60.16,26.95,61.11,28.32,63.56,28.32"/>
          <polygon className="st2" points="67.73,15.82 69.72,15.82 69.72,28.16 72.14,28.16 72.14,15.82 74.12,15.82 74.12,13.86 67.73,13.86
		"/>
          <path className="st2" d="M78.66,16.07h0.02v7.55h-1.79L78.66,16.07z M75.83,28.16l0.61-2.62h2.24v2.62h5.74v-1.96h-3.33v-4.34h2.38
		v-1.94h-2.38v-4.09h3.33v-1.96H77.2l-3.65,14.3H75.83z"/>
          <polygon className="st2" points="90.98,28.16 93.08,28.16 93.08,13.86 91.08,13.86 91.08,22.06 88.49,13.86 86.13,13.86 86.13,28.16
		88.15,28.16 88.15,19.17 	"/>
          <path className="st2" d="M98.59,13.86h-3.71v14.3h3.71c2.57,0,3.48-1.64,3.48-3.84v-6.62C102.07,15.48,101.16,13.86,98.59,13.86
		 M97.28,26.19V15.82h1.01c0.99,0,1.37,0.59,1.37,1.43v7.49c0,0.86-0.38,1.45-1.37,1.45H97.28z"/>
          <rect x="103.44" y="13.86" className="st2" width="2.4" height="14.3" />
          <path className="st2" d="M110.86,22.42H112v2.53c0,0.93-0.4,1.37-1.18,1.37c-0.82,0-1.18-0.61-1.18-1.48v-7.68
		c0-0.86,0.38-1.48,1.24-1.48c0.86,0,1.2,0.51,1.2,1.39v1.45h2.19v-1.27c0-2.11-0.78-3.59-3.44-3.59c-2.53,0-3.61,1.62-3.61,3.86
		v6.92c0,2.21,0.95,3.86,3.02,3.86c1.31,0,1.98-0.65,2.3-1.73v1.56h1.73v-7.63h-3.42V22.42z"/>
          <polygon className="st2" points="115.65,28.16 121.39,28.16 121.39,26.19 118.05,26.19 118.05,21.85 120.44,21.85 120.44,19.91
		118.05,19.91 118.05,15.82 121.39,15.82 121.39,13.86 115.65,13.86 	"/>
          <path className="st2" d="M128.87,13.86v11.3c0,0.74-0.25,1.16-0.91,1.16c-0.63,0-0.89-0.42-0.89-1.14v-2.89h-2.28v2.85
		c0,1.81,0.67,3.18,3.16,3.18c2.4,0,3.31-1.35,3.31-3.42V13.86H128.87z"/>
          <path className="st2" d="M136.25,28.32c2.55,0,3.65-1.64,3.65-3.86v-6.92c0-2.24-1.1-3.86-3.65-3.86c-2.53,0-3.63,1.62-3.63,3.86v6.92
		C132.63,26.68,133.72,28.32,136.25,28.32 M136.25,26.32c-0.84,0-1.22-0.61-1.22-1.48v-7.68c0-0.86,0.38-1.48,1.22-1.48
		c0.86,0,1.24,0.61,1.24,1.48v7.68C137.5,25.71,137.12,26.32,136.25,26.32"/>
          <path className="st2" d="M143.68,15.82h0.82c0.8,0,1.16,0.38,1.16,1.16v1.75c0,0.76-0.36,1.16-1.14,1.16h-0.84V15.82z M144.71,21.72
		c0.86,0,1.22,0.4,1.22,1.16v2.15c0,0.76-0.39,1.16-1.2,1.16h-1.05v-4.47H144.71z M141.27,28.16h3.73c2.42,0,3.33-1.12,3.33-3.14
		v-1.9c0-1.31-0.59-2.11-1.79-2.38c0.98-0.32,1.41-1.08,1.41-2.28V17c0-2.02-0.8-3.14-3.21-3.14h-3.48V28.16z"/>
          <path className="st2" d="M152.79,28.32c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.67-1.14-1.14-1.14-1.94v-0.3c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.49c0,1.64,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.02
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96h-2.24v1.92C149.39,26.95,150.34,28.32,152.79,28.32"/>
          <path className="st2" d="M46.39,48.74c2.55,0,3.65-1.64,3.65-3.86v-6.92c0-2.24-1.1-3.86-3.65-3.86c-2.53,0-3.63,1.62-3.63,3.86v6.92
		C42.76,47.09,43.86,48.74,46.39,48.74 M46.39,46.73c-0.84,0-1.22-0.61-1.22-1.48v-7.68c0-0.86,0.38-1.48,1.22-1.48
		c0.86,0,1.24,0.61,1.24,1.48v7.68C47.64,46.12,47.26,46.73,46.39,46.73"/>
          <path className="st2" d="M55.04,42.83h1.14v2.53c0,0.93-0.4,1.37-1.18,1.37c-0.82,0-1.18-0.61-1.18-1.48v-7.68
		c0-0.86,0.38-1.48,1.24-1.48c0.86,0,1.2,0.51,1.2,1.39v1.45h2.19v-1.27c0-2.11-0.78-3.59-3.44-3.59c-2.53,0-3.61,1.62-3.61,3.86
		v6.92c0,2.21,0.95,3.86,3.02,3.86c1.31,0,1.98-0.65,2.3-1.73v1.56h1.73v-7.63h-3.42V42.83z"/>
          <path className="st2" d="M66.93,46.8c-0.7,0-1.14-0.4-1.27-1.03l2.59-6.26v5.82C68.26,46.19,67.8,46.8,66.93,46.8 M65.63,37.52
		c0-0.86,0.46-1.48,1.31-1.48c0.72,0,1.16,0.42,1.29,1.08l-2.59,6.24V37.52z M66.93,48.74c2.55,0,3.65-1.64,3.65-3.86v-6.92
		c0-0.97-0.21-1.83-0.65-2.49l0.65-1.56h-1.03l-0.34,0.82c-0.57-0.4-1.33-0.63-2.28-0.63c-2.53,0-3.63,1.62-3.63,3.86v6.92
		c0,0.97,0.21,1.83,0.65,2.49l-0.65,1.58h1.03l0.34-0.82C65.25,48.53,65.98,48.74,66.93,48.74"/>
          <polygon className="st2" points="76.87,48.57 79.4,48.57 76.55,40.7 79.33,34.27 76.97,34.27 74.36,40.56 74.36,34.27 71.95,34.27
		71.95,48.57 74.36,48.57 74.36,41.61 	"/>
          <path className="st2" d="M83.4,48.74c2.55,0,3.65-1.64,3.65-3.86v-6.92c0-2.24-1.1-3.86-3.65-3.86c-2.53,0-3.63,1.62-3.63,3.86v6.92
		C79.78,47.09,80.87,48.74,83.4,48.74 M83.4,46.73c-0.84,0-1.22-0.61-1.22-1.48v-7.68c0-0.86,0.38-1.48,1.22-1.48
		c0.86,0,1.24,0.61,1.24,1.48v7.68C84.65,46.12,84.27,46.73,83.4,46.73"/>
          <polygon className="st2" points="93.27,48.57 95.38,48.57 95.38,34.27 93.38,34.27 93.38,42.47 90.79,34.27 88.42,34.27 88.42,48.57
		90.45,48.57 90.45,39.59 	"/>
          <path className="st2" d="M100.38,48.74c2.55,0,3.65-1.64,3.65-3.86v-6.92c0-2.24-1.1-3.86-3.65-3.86c-2.53,0-3.63,1.62-3.63,3.86v6.92
		C96.75,47.09,97.85,48.74,100.38,48.74 M100.38,46.73c-0.84,0-1.22-0.61-1.22-1.48v-7.68c0-0.86,0.38-1.48,1.22-1.48
		c0.86,0,1.24,0.61,1.24,1.48v7.68C101.63,46.12,101.25,46.73,100.38,46.73"/>
          <polygon className="st2" points="112.44,48.57 114.64,48.57 114.64,34.27 111.6,34.27 110.21,42.33 110.19,42.33 108.63,34.27
		105.82,34.27 105.82,48.57 107.81,48.57 107.81,39.29 107.85,39.29 109.64,48.57 110.76,48.57 112.38,39.29 112.44,39.29 	"/>
          <rect x="116.43" y="34.27" className="st2" width="2.4" height="14.3" />
          <path className="st2" d="M123.5,48.74c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.67-1.14-1.14-1.14-1.94v-0.3c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.49c0,1.64,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.02
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96h-2.24v1.92C120.1,47.37,121.05,48.74,123.5,48.74"/>
          <polygon className="st2" points="133.18,48.57 135.71,48.57 132.86,40.7 135.64,34.27 133.28,34.27 130.67,40.56 130.67,34.27
		128.26,34.27 128.26,48.57 130.67,48.57 130.67,41.61 	"/>
          <polygon className="st2" points="48.21,54.69 46.52,64.66 46.5,64.66 44.85,54.69 42.41,54.69 45.13,68.98 47.74,68.98 50.46,54.69
		"/>
          <path className="st2" d="M54.66,56.9h0.02v7.55h-1.79L54.66,56.9z M51.83,68.98l0.61-2.62h2.24v2.62h5.74v-1.96h-3.33v-4.34h2.38v-1.94
		h-2.38v-4.09h3.33v-1.96H53.2l-3.65,14.3H51.83z"/>
          <polygon className="st2" points="66.62,68.98 69.15,68.98 66.3,61.12 69.08,54.69 66.72,54.69 64.11,60.97 64.11,54.69 61.7,54.69
		61.7,68.98 64.11,68.98 64.11,62.02 	"/>
          <path className="st2" d="M73.01,69.15c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.67-1.14-1.14-1.14-1.94v-0.3c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.49c0,1.64,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.02
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96h-2.24v1.92C69.61,67.78,70.56,69.15,73.01,69.15"/>
          <polygon className="st2" points="77.18,56.65 79.17,56.65 79.17,68.98 81.59,68.98 81.59,56.65 83.57,56.65 83.57,54.69 77.18,54.69
		"/>
        </g>
      </svg>
    </>
  );
};
