import React from "react";

export interface Props {
  isActive: boolean;
}

export const IconOne: React.FC<Props> = ({ isActive = false }: Props) => {
  const css = `
    .st0{fill:${isActive ? '#eb1c2d' : '#B2B2B2'};}
    svg:hover .st0{fill:#eb1c2d;}
    .st1{fill:#FFFFFF;}
  `;
  return (
    <>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 170.08 170.08" style={{['enableBackground' as any]: 'new 0 0 170.08 170.08', 'filter': 'none'}} xmlSpace="preserve">
        <style type="text/css">{css}</style>
        <rect className="st0" width="170.08" height="170.08" />
        <polygon className="st1" points="23.75,22.37 23.8,22.37 23.8,48.62 29.56,48.62 29.56,14.33 24.96,14.33 19.65,20.55 19.65,26.56
	19.7,26.56 "/>
        <g>
          <path className="st1" d="M130.98,81.14c0-2.6,2.11-4.71,4.71-4.71c2.6,0,4.7,2.11,4.7,4.71c0,2.6-2.11,4.71-4.7,4.71
		C133.09,85.85,130.98,83.74,130.98,81.14"/>
          <path className="st1" d="M141.19,87.67c3.19,0.29,5.7,2.98,5.7,6.25v1.56v0.68v10.8c0,1-0.82,1.82-1.82,1.82
		c-1.01,0-1.83-0.82-1.83-1.82V95.76c0-0.42-0.12-0.99-0.89-0.99c-0.53,0-0.64,0.56-0.66,0.92v33.6c0,1.44-1.17,2.62-2.62,2.62
		c-1.45,0-2.63-1.18-2.63-2.62v-19.36c-0.01-0.21-0.08-0.77-0.74-0.77h0c-0.67,0-0.75,0.56-0.76,0.77v19.36
		c0,1.44-1.17,2.62-2.62,2.62c-1.46,0-2.62-1.18-2.62-2.62V95.7c-0.02-0.36-0.13-0.92-0.66-0.92c-0.77,0-0.89,0.57-0.89,0.99v11.21
		c0,1-0.82,1.82-1.83,1.82c-1.01,0-1.82-0.82-1.82-1.82v-10.8v-0.68v-1.56c0-3.28,2.51-5.96,5.71-6.25c0.1-0.01,0.22-0.03,0.35-0.03
		h5.15h5.14C140.97,87.64,141.08,87.65,141.19,87.67"/>
          <path className="st1" d="M102.98,83.62c0-2.46-1.99-4.46-4.45-4.46c-2.46,0-4.46,2-4.46,4.46c0,2.46,2,4.46,4.46,4.46
		C100.99,88.08,102.98,86.08,102.98,83.62"/>
          <path className="st1" d="M89.54,93.72l-3.71,12.07c0,0-0.66,2.21,1.22,2.76c1.87,0.55,2.43-1.61,2.43-1.61l2.99-10.07
		c0,0,0.16-0.77,0.74-0.61c0.58,0.17,0.36,0.99,0.36,0.99l-5.2,18.87h4.84v13.52c0,1.28,1.04,2.32,2.33,2.32
		c1.28,0,2.32-1.04,2.32-2.32v-13.52h0.66h0.66v13.52c0,1.28,1.04,2.32,2.32,2.32c1.29,0,2.33-1.04,2.33-2.32v-13.52h4.84
		l-5.2-18.87c0,0-0.22-0.83,0.36-0.99c0.58-0.17,0.75,0.61,0.75,0.61l2.99,10.07c0,0,0.57,2.16,2.43,1.61
		c1.88-0.55,1.22-2.76,1.22-2.76l-3.71-12.07c0,0-1.32-3.49-4.26-3.49h-4.72H93.8C90.87,90.23,89.54,93.72,89.54,93.72"/>
          <path className="st1" d="M76.28,83.62c0-2.46-1.99-4.46-4.46-4.46c-2.46,0-4.46,2-4.46,4.46c0,2.46,1.99,4.46,4.46,4.46
		C74.28,88.08,76.28,86.08,76.28,83.62"/>
          <path className="st1" d="M62.84,93.72l-3.7,12.07c0,0-0.66,2.21,1.21,2.76c1.87,0.55,2.43-1.61,2.43-1.61l2.99-10.07
		c0,0,0.16-0.77,0.74-0.61c0.58,0.17,0.36,0.99,0.36,0.99l-5.2,18.87h4.84v13.52c0,1.28,1.05,2.32,2.33,2.32
		c1.28,0,2.32-1.04,2.32-2.32v-13.52h0.67h0.65v13.52c0,1.28,1.03,2.32,2.32,2.32c1.29,0,2.32-1.04,2.32-2.32v-13.52h4.84
		l-5.2-18.87c0,0-0.22-0.83,0.36-0.99c0.58-0.17,0.75,0.61,0.75,0.61l2.99,10.07c0,0,0.57,2.16,2.43,1.61
		c1.88-0.55,1.22-2.76,1.22-2.76L80.8,93.72c0,0-1.32-3.49-4.26-3.49h-4.72H67.1C64.17,90.23,62.84,93.72,62.84,93.72"/>
          <path className="st1" d="M117.96,108.57c2.05,0,3.71-1.66,3.71-3.71c0-2.05-1.66-3.71-3.71-3.71c-2.05,0-3.71,1.66-3.71,3.71
		C114.25,106.91,115.9,108.57,117.96,108.57"/>
          <path className="st1" d="M109.06,120.1c-0.18,0.43-0.33,1.35,0.54,1.76c1.02,0.47,1.59-0.52,1.59-0.52l2.02-6.43
		c0,0,0.35-0.43,0.35,0.01v15.15h0.01c0,1.05,0.85,1.9,1.9,1.9c1.05,0,1.9-0.85,1.9-1.9v-5.98c0,0-0.11-0.85,0.57-0.85
		c0.68,0,0.58,0.85,0.58,0.85v5.98c0,1.05,0.85,1.9,1.91,1.9c1.05,0,1.91-0.85,1.91-1.9v-15.15c0-0.44,0.24-0.13,0.24-0.13
		l1.58,4.41c0,0,0.69,1.97,0.9,2.05c0.71,0.3,1.54-0.04,1.84-0.76c0.2-0.47,0.12-0.97-0.15-1.35l0.01-0.01
		c-0.05-0.14-2.36-5.83-2.64-6.62c-0.84-2.44-2.36-2.42-3.83-2.46c-1.47-0.04-2.35,0-2.35,0s-0.94-0.04-2.87,0.02
		c-1.93,0.06-2.54,1.05-4.68,6.54c-0.3,0.79-1.33,3.35-1.35,3.5L109.06,120.1z"/>
          <path className="st1" d="M31.75,85.85c2.6,0,4.71-2.11,4.71-4.71c0-2.6-2.11-4.71-4.71-4.71c-2.6,0-4.7,2.11-4.7,4.71
		C27.04,83.74,29.15,85.85,31.75,85.85"/>
          <path className="st1" d="M37.29,87.64c-0.11-0.01-0.26-0.05-0.39-0.05h-5.15h-5.14c-0.13,0-0.23,0.04-0.34,0.05
		c-3.19,0.29-5.69,3-5.69,6.28v1.56v0.68v10.8c0,0.7,0.38,1.3,0.95,1.61c-0.02,0.05-0.05,0.1-0.06,0.16l-4.19,22.16
		c-0.07,0.38,0.17,0.74,0.55,0.81c0.04,0.01,0.09,0.01,0.13,0.01c0.33,0,0.61-0.23,0.68-0.56L22.82,109
		c0.02-0.09-0.01-0.17-0.03-0.26c0.79-0.2,1.36-0.92,1.36-1.77V95.76c0-0.42,0.11-0.99,0.88-0.99c0.53,0,0.65,0.56,0.65,0.92v33.6
		c0,1.44,1.18,2.62,2.63,2.62c1.45,0,2.64-1.18,2.64-2.62v-19.36c0-0.21,0.12-0.74,0.79-0.74h0c0.67,0,0.74,0.53,0.74,0.74v19.36
		c0,1.44,1.19,2.62,2.64,2.62c1.45,0,2.64-1.18,2.64-2.62V95.7c0-0.36,0.13-0.92,0.65-0.92c0.77,0,0.88,0.57,0.88,0.99v11.21
		c0,1,0.86,1.82,1.87,1.82c1.01,0,1.87-0.82,1.87-1.82v-10.8v-0.68v-1.56C43.03,90.64,40.49,87.93,37.29,87.64"/>
          <path className="st1" d="M55.47,114.7l3.44-6.25c0,0,0.42-0.9,0.42-1.11c0-0.74-0.61-1.35-1.36-1.35c-0.49,0-0.91,0.25-1.15,0.63
		l-0.02-0.01c-0.11,0.09-1.84,3.17-2.41,3.74c-0.6,0.61-1.15,0.53-1.15,0.53H51h-2.25c0,0-0.55,0.08-1.15-0.53
		c-0.58-0.57-2.3-3.65-2.41-3.75l-0.01,0.01c-0.24-0.38-0.66-0.63-1.15-0.63c-0.74,0-1.35,0.61-1.35,1.35
		c0,0.22,0.13,0.59,0.13,0.59l3.73,6.77c0,0,0.22,0.47,0.22,0.89v1.33l-1.96,7.23h1.96v5.95h0.02c0,1.02,0.85,1.84,1.86,1.84
		c1.01,0,1.86-0.8,1.86-1.82v-5.74c0,0-0.07-0.07-0.06-0.24h0.56h0.55c0.01,0.17-0.02,0.24-0.02,0.24v5.74
		c0,1.01,0.86,1.83,1.87,1.83c1.01,0,1.87-0.81,1.87-1.83v-5.98h1.92l-1.92-7.27v-1.29C55.28,115.17,55.47,114.7,55.47,114.7"/>
          <path className="st1" d="M51,108.57c2.05,0,3.71-1.66,3.71-3.71c0-2.05-1.66-3.71-3.71-3.71c-2.05,0-3.71,1.66-3.71,3.71
		C47.29,106.91,48.95,108.57,51,108.57"/>
        </g>
        <path className="st1" d="M52.47,17.88h0.02l0.95,5.74h-1.92L52.47,17.88z M50.78,28.15l0.42-2.62h2.55l0.44,2.62h2.47l-2.72-14.3h-2.74
	l-2.7,14.3H50.78z"/>
        <polygon className="st1" points="57.66,28.15 60.06,28.15 60.06,21.85 62.47,21.85 62.47,19.91 60.06,19.91 60.06,15.82 63.29,15.82
	63.29,13.85 57.66,13.85 "/>
        <g>
          <path className="st1" d="M67.61,28.32c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.67-1.14-1.14-1.14-1.94v-0.3c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.49c0,1.64,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.02
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96h-2.24v1.92C64.22,26.95,65.17,28.32,67.61,28.32"/>
          <polygon className="st1" points="77.72,28.15 80.25,28.15 77.4,20.29 80.18,13.85 77.82,13.85 75.21,20.14 75.21,13.85 72.8,13.85
		72.8,28.15 75.21,28.15 75.21,21.19 	"/>
          <path className="st1" d="M84.74,17.88h0.02l0.95,5.74h-1.92L84.74,17.88z M83.05,28.15l0.42-2.62h2.55l0.44,2.62h2.47l-2.72-14.3h-2.74
		l-2.7,14.3H83.05z"/>
          <polygon className="st1" points="90.35,28.15 92.75,28.15 92.75,21.85 95.16,21.85 95.16,19.91 92.75,19.91 92.75,15.82 95.98,15.82
		95.98,13.85 90.35,13.85 	"/>
          <polygon className="st1" points="48.89,48.57 51.29,48.57 51.29,42.26 53.69,42.26 53.69,40.32 51.29,40.32 51.29,36.23 54.52,36.23
		54.52,34.27 48.89,34.27 	"/>
          <path className="st1" d="M58.31,38.3h0.02l0.95,5.74h-1.92L58.31,38.3z M56.63,48.57l0.42-2.62h2.55l0.44,2.62h2.47l-2.72-14.3h-2.74
		l-2.7,14.3H56.63z"/>
          <polygon className="st1" points="62.38,36.23 64.37,36.23 64.37,48.57 66.79,48.57 66.79,36.23 68.77,36.23 68.77,34.27 62.38,34.27
		"/>
          <polygon className="st1" points="68.56,36.23 70.54,36.23 70.54,48.57 72.97,48.57 72.97,36.23 74.95,36.23 74.95,34.27 68.56,34.27
		"/>
          <rect x="76.49" y="34.27" className="st1" width="2.4" height="14.3" />
          <path className="st1" d="M84.34,42.83h1.14v2.53c0,0.93-0.4,1.37-1.18,1.37c-0.82,0-1.18-0.61-1.18-1.48v-7.68
		c0-0.87,0.38-1.48,1.24-1.48c0.87,0,1.2,0.51,1.2,1.39v1.46h2.19v-1.27c0-2.11-0.78-3.59-3.44-3.59c-2.53,0-3.61,1.62-3.61,3.86
		v6.92c0,2.21,0.95,3.86,3.02,3.86c1.31,0,1.98-0.65,2.3-1.73v1.56h1.73v-7.63h-3.42V42.83z"/>
          <path className="st1" d="M93.68,34.27h-3.71v14.3h3.71c2.57,0,3.48-1.65,3.48-3.84v-6.62C97.16,35.89,96.25,34.27,93.68,34.27
		 M92.37,46.61V36.23h1.01c0.99,0,1.37,0.59,1.37,1.43v7.49c0,0.87-0.38,1.46-1.37,1.46H92.37z"/>
          <path className="st1" d="M102.58,48.74c2.55,0,3.65-1.64,3.65-3.86v-6.92c0-2.23-1.1-3.86-3.65-3.86c-2.53,0-3.63,1.62-3.63,3.86v6.92
		C98.95,47.09,100.05,48.74,102.58,48.74 M102.58,46.73c-0.84,0-1.22-0.61-1.22-1.48v-7.68c0-0.87,0.38-1.48,1.22-1.48
		c0.87,0,1.24,0.61,1.24,1.48v7.68C103.82,46.12,103.45,46.73,102.58,46.73"/>
          <polygon className="st1" points="114.64,48.57 116.84,48.57 116.84,34.27 113.8,34.27 112.41,42.33 112.39,42.33 110.83,34.27
		108.02,34.27 108.02,48.57 110,48.57 110,39.29 110.05,39.29 111.84,48.57 112.96,48.57 114.58,39.29 114.64,39.29 	"/>
        </g>
      </svg>

    </>
  )
};
