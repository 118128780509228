import React from "react";

export interface Props {
  isActive: boolean;
}

export const IconTwelve: React.FC<Props> = ({ isActive = false }: Props) => {
  return (
    <>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 170.08 170.08" style={{ ['enableBackground' as any]: 'new 0 0 170.08 170.08', 'filter': 'none' }} xmlSpace="preserve">
        <style type="text/css">{`
          svg:hover #SVGID_1_12{fill:#bf8d2d;}
          .st1{clip-path:url(#SVGID_00000136387604244860597050000016150476957341929606_);fill:#FFFFFF;}
          .st2{fill:#FFFFFF;}
        `}</style>
        <rect y="0" className="st0" width="170.08" height="170.08" />
        <g>
          <defs>
            <rect id="SVGID_1_12" x="0" width="170.08" height="170.08" />
          </defs>
          <use xlinkHref="#SVGID_1_12" style={{ 'overflow': 'visible', 'fill': isActive ? '#bf8d2d' : '#B2B2B2' }} />
          <clipPath id="SVGID_00000136387604244860597050000016150476957341929606_">
            <use xlinkHref="#SVGID_1_12" style={{ 'overflow': 'visible' }} />
          </clipPath>
          <path className="st1" d="M26.23,21.38v4.15h5.26
		v-4.3c0-2.07,1.01-2.68,2.23-2.68c1.11,0,2.07,0.45,2.07,2.58v2.83c0,2.83-0.25,3.95-2.13,5.92l-3.23,3.44
		c-3.14,3.34-4.3,5.56-4.3,8.95v6.32H41.1v-4.71h-9.46v-1.62c0-2.68,0.96-3.69,2.43-5.26l3.69-3.89c2.99-3.19,3.64-5.21,3.64-8.65
		v-3.34c0-4.91-2.38-7.23-7.54-7.23C28.81,13.9,26.23,16.58,26.23,21.38"/>
          <path className="st1" d="M131.62,91.11
		c-6.94-5.48-15.25-6.07-18.51-6.07c-1.72,0-3.3,0.15-4.47,0.43l-0.46,0.11c-4.39,1.04-11.75,2.77-20.31,13.4l-0.25,0.31
		c-0.14,0.2-0.21,0.42-0.21,0.64c0,0.19,0.05,0.38,0.15,0.56l0.19,0.32c1.41,2.36,2.59,4.41,3.52,6.16
		c0.34,0.65,1.23,0.86,1.83,0.26c0.06-0.08,0.12-0.16,0.16-0.23c7.39-10.23,13.19-11.59,17.02-12.49l0.48-0.11
		c0.53-0.13,1.38-0.2,2.35-0.2c1.78,0,7.98,0.3,12.81,4.11c4.3,3.39,6.48,8.82,6.48,16.11c0,12.23-5.92,17-10.88,18.85
		c-1.89,0.71-3.94,1.07-6.07,1.07c-5.11,0-9.05-2.05-9.1-2.07L106,132.1c-4.27-1.85-10.65-5.72-16.68-17.83
		C76.97,89.45,66.59,87.1,59.71,85.54l-0.36-0.08c-0.53-0.12-2.01-0.4-4.43-0.4c-3.98,0-11.64,0.78-18.2,6.02
		c-4.48,3.57-9.82,10.52-9.82,23.29c0,7.78,1.6,12.93,5.71,18.33c0.77,1.05,7.94,10.3,21.69,10.3c2.04,0,4.15-0.21,6.28-0.62
		c2.51-0.48,6.39-1.72,10.71-4.9l3.62,3.26c0.4,0.37,1.1,0.33,1.47-0.07c0.18-0.19,0.27-0.44,0.26-0.69l2.8-17.23l0-0.2
		c0-0.3-0.13-0.57-0.34-0.75c-0.18-0.17-0.42-0.27-0.72-0.27h-0.01l-16.77,4.63l-0.32,0.05c-0.21,0.05-0.4,0.15-0.53,0.31
		c-0.19,0.21-0.29,0.48-0.27,0.76c0.01,0.28,0.14,0.54,0.34,0.72l3.48,3.14c-1.83,1.12-3.65,1.86-5.43,2.21
		c-1.51,0.29-3.01,0.44-4.45,0.44c-9.5,0-14.18-6.24-14.37-6.5l-0.09-0.13c-2.89-3.8-3.87-7.03-3.87-12.81
		c0-7.32,2.14-12.75,6.37-16.12c4.7-3.75,10.75-4.04,12.5-4.04c0.95,0,1.82,0.07,2.38,0.2l0.37,0.08c5.49,1.24,13,2.94,23.42,23.87
		c5.5,11.05,12.4,18.27,21.08,22.08c1.01,0.51,6.39,3.08,13.28,3.08c3.22,0,6.34-0.55,9.27-1.64c5.07-1.89,16.85-8.35,16.85-27.45
		C141.59,101.66,136.17,94.7,131.62,91.11"/>
        </g>
        <path className="st2" d="M57.87,17.88h0.02l0.95,5.74h-1.92L57.87,17.88z M56.19,28.15l0.42-2.62h2.55l0.44,2.62h2.47l-2.72-14.3h-2.74
	l-2.7,14.3H56.19z"/>
        <polygon className="st2" points="67.91,28.15 70.02,28.15 70.02,13.85 68.02,13.85 68.02,22.06 65.42,13.85 63.06,13.85 63.06,28.15
	65.09,28.15 65.09,19.17 "/>
        <g>
          <path className="st2" d="M74.66,28.32c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.67-1.14-1.14-1.14-1.94v-0.3c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.49c0,1.64,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.02
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96h-2.24v1.92C71.27,26.95,72.21,28.32,74.66,28.32"/>
          <polygon className="st2" points="84.55,13.85 82.87,23.83 82.84,23.83 81.2,13.85 78.75,13.85 81.47,28.15 84.09,28.15 86.81,13.85
		"/>
          <path className="st2" d="M90.12,17.88h0.02l0.95,5.74h-1.92L90.12,17.88z M88.43,28.15l0.42-2.62h2.55l0.44,2.62h2.47l-2.72-14.3h-2.74
		l-2.7,14.3H88.43z"/>
          <path className="st2" d="M98.51,15.82c0.82,0,1.18,0.38,1.18,1.16v2.11c0,0.76-0.36,1.18-1.18,1.18h-0.8v-4.45H98.51z M99.97,28.15
		h2.53l-2.3-6.5c1.37-0.32,1.92-1.22,1.92-2.76v-1.83c0-2-0.89-3.21-3.31-3.21h-3.5v14.3h2.4v-6.56L99.97,28.15z"/>
          <polygon className="st2" points="103.85,28.15 109.12,28.15 109.12,26.19 106.25,26.19 106.25,13.85 103.85,13.85 	" />
          <rect x="109.8" y="13.85" className="st2" width="2.4" height="14.3" />
          <path className="st2" d="M117.22,22.42h1.14v2.53c0,0.93-0.4,1.37-1.18,1.37c-0.82,0-1.18-0.61-1.18-1.48v-7.68
		c0-0.87,0.38-1.48,1.24-1.48c0.87,0,1.2,0.51,1.2,1.39v1.46h2.19v-1.27c0-2.11-0.78-3.59-3.44-3.59c-2.53,0-3.61,1.62-3.61,3.86
		v6.92c0,2.21,0.95,3.86,3.02,3.86c1.31,0,1.98-0.65,2.3-1.73v1.56h1.73v-7.63h-3.42V22.42z"/>
          <polygon className="st2" points="121.31,15.82 123.29,15.82 123.29,28.15 125.72,28.15 125.72,15.82 127.7,15.82 127.7,13.86
		121.31,13.86 	"/>
          <polygon className="st2" points="54.29,48.57 56.69,48.57 56.69,42.26 59.1,42.26 59.1,40.32 56.69,40.32 56.69,36.23 59.92,36.23
		59.92,34.27 54.29,34.27 	"/>
          <path className="st2" d="M63.97,48.74c2.55,0,3.65-1.65,3.65-3.86v-6.92c0-2.23-1.1-3.86-3.65-3.86c-2.53,0-3.63,1.62-3.63,3.86v6.92
		C60.34,47.09,61.44,48.74,63.97,48.74 M63.97,46.73c-0.84,0-1.22-0.61-1.22-1.48v-7.68c0-0.87,0.38-1.48,1.22-1.48
		c0.86,0,1.24,0.61,1.24,1.48v7.68C65.21,46.12,64.83,46.73,63.97,46.73"/>
          <path className="st2" d="M72.19,36.23c0.82,0,1.18,0.38,1.18,1.16v2.11c0,0.76-0.36,1.18-1.18,1.18h-0.8v-4.45H72.19z M73.65,48.57
		h2.53l-2.3-6.5c1.37-0.32,1.92-1.22,1.92-2.76v-1.83c0-2-0.89-3.21-3.31-3.21h-3.5v14.3h2.4v-6.56L73.65,48.57z"/>
          <path className="st2" d="M79.51,36.23h0.82c0.8,0,1.16,0.38,1.16,1.16v1.75c0,0.76-0.36,1.16-1.14,1.16h-0.84V36.23z M80.55,42.14
		c0.86,0,1.22,0.4,1.22,1.16v2.15c0,0.76-0.39,1.16-1.2,1.16h-1.05v-4.47H80.55z M77.11,48.57h3.73c2.42,0,3.33-1.12,3.33-3.14v-1.9
		c0-1.31-0.59-2.11-1.79-2.38c0.98-0.32,1.41-1.07,1.41-2.28v-1.46c0-2.03-0.8-3.14-3.21-3.14h-3.48V48.57z"/>
          <path className="st2" d="M88.14,36.23c0.82,0,1.18,0.38,1.18,1.16v2.11c0,0.76-0.36,1.18-1.18,1.18h-0.8v-4.45H88.14z M89.59,48.57
		h2.53l-2.3-6.5c1.37-0.32,1.92-1.22,1.92-2.76v-1.83c0-2-0.89-3.21-3.31-3.21h-3.5v14.3h2.4v-6.56L89.59,48.57z"/>
          <path className="st2" d="M97.82,34.27v11.05c0,0.84-0.34,1.41-1.18,1.41c-0.84,0-1.18-0.57-1.18-1.41V34.27h-2.4v10.82
		c0,2.21,1.05,3.65,3.59,3.65c2.45,0,3.4-1.43,3.4-3.65V34.27H97.82z"/>
          <path className="st2" d="M105.45,42.83h1.14v2.53c0,0.93-0.4,1.37-1.18,1.37c-0.82,0-1.18-0.61-1.18-1.48v-7.68
		c0-0.87,0.38-1.48,1.24-1.48c0.87,0,1.2,0.51,1.2,1.39v1.46h2.19v-1.27c0-2.11-0.78-3.59-3.44-3.59c-2.53,0-3.61,1.62-3.61,3.86
		v6.92c0,2.21,0.95,3.86,3.02,3.86c1.31,0,1.98-0.65,2.3-1.73v1.56h1.73v-7.63h-3.42V42.83z"/>
          <path className="st2" d="M57.92,69.15c2.55,0,3.65-1.65,3.65-3.86v-6.92c0-2.23-1.1-3.86-3.65-3.86c-2.53,0-3.63,1.62-3.63,3.86v6.92
		C54.29,67.51,55.39,69.15,57.92,69.15 M57.92,67.15c-0.84,0-1.22-0.61-1.22-1.48V58c0-0.87,0.38-1.48,1.22-1.48
		c0.87,0,1.24,0.61,1.24,1.48v7.68C59.16,66.54,58.78,67.15,57.92,67.15"/>
          <path className="st2" d="M66.14,63.25h1.14v2.53c0,0.93-0.4,1.37-1.18,1.37c-0.82,0-1.18-0.61-1.18-1.48V58c0-0.87,0.38-1.48,1.24-1.48
		c0.86,0,1.2,0.51,1.2,1.39v1.46h2.19V58.1c0-2.11-0.78-3.59-3.44-3.59c-2.53,0-3.61,1.62-3.61,3.86v6.92
		c0,2.21,0.95,3.86,3.02,3.86c1.31,0,1.98-0.65,2.3-1.73v1.56h1.73v-7.63h-3.42V63.25z"/>
          <path className="st2" d="M76.85,56.65c0.82,0,1.18,0.38,1.18,1.16v3.29c0,0.78-0.36,1.18-1.18,1.18h-0.89v-5.63H76.85z M73.56,68.98
		h2.4v-4.85h1.16c2.42,0,3.31-1.22,3.31-3.23v-3.02c0-2-0.89-3.21-3.31-3.21h-3.56V68.98z"/>
          <path className="st2" d="M84.47,56.65c0.82,0,1.18,0.38,1.18,1.16v2.11c0,0.76-0.36,1.18-1.18,1.18h-0.8v-4.45H84.47z M85.92,68.98
		h2.53l-2.3-6.5c1.37-0.32,1.92-1.22,1.92-2.76v-1.83c0-2-0.89-3.21-3.31-3.21h-3.5v14.3h2.4v-6.56L85.92,68.98z"/>
          <path className="st2" d="M93.01,69.15c2.55,0,3.65-1.65,3.65-3.86v-6.92c0-2.23-1.1-3.86-3.65-3.86c-2.53,0-3.63,1.62-3.63,3.86v6.92
		C89.38,67.51,90.48,69.15,93.01,69.15 M93.01,67.15c-0.84,0-1.22-0.61-1.22-1.48V58c0-0.87,0.38-1.48,1.22-1.48
		c0.86,0,1.24,0.61,1.24,1.48v7.68C94.25,66.54,93.87,67.15,93.01,67.15"/>
          <path className="st2" d="M101.74,54.68h-3.71v14.3h3.71c2.57,0,3.48-1.64,3.48-3.84v-6.62C105.22,56.31,104.31,54.68,101.74,54.68
		 M100.43,67.02V56.65h1.01c0.99,0,1.37,0.59,1.37,1.43v7.49c0,0.87-0.38,1.46-1.37,1.46H100.43z"/>
          <path className="st2" d="M111.36,54.68v11.05c0,0.84-0.34,1.41-1.18,1.41c-0.84,0-1.18-0.57-1.18-1.41V54.68h-2.4V65.5
		c0,2.21,1.05,3.65,3.59,3.65c2.45,0,3.4-1.43,3.4-3.65V54.68H111.36z"/>
          <polygon className="st2" points="119.86,68.98 122.39,68.98 119.54,61.12 122.32,54.68 119.96,54.68 117.35,60.97 117.35,54.68
		114.94,54.68 114.94,68.98 117.35,68.98 117.35,62.02 	"/>
          <polygon className="st2" points="121.33,56.65 123.32,56.65 123.32,68.98 125.74,68.98 125.74,56.65 127.72,56.65 127.72,54.68
		121.33,54.68 	"/>
          <rect x="128.42" y="54.68" className="st2" width="2.4" height="14.3" />
          <path className="st2" d="M135.84,69.15c2.55,0,3.65-1.65,3.65-3.86v-6.92c0-2.23-1.1-3.86-3.65-3.86c-2.53,0-3.63,1.62-3.63,3.86v6.92
		C132.22,67.51,133.31,69.15,135.84,69.15 M135.84,67.15c-0.84,0-1.22-0.61-1.22-1.48V58c0-0.87,0.38-1.48,1.22-1.48
		c0.86,0,1.24,0.61,1.24,1.48v7.68C137.09,66.54,136.71,67.15,135.84,67.15"/>
          <polygon className="st2" points="145.71,68.98 147.82,68.98 147.82,54.68 145.82,54.68 145.82,62.89 143.22,54.68 140.86,54.68
		140.86,68.98 142.89,68.98 142.89,60 	"/>
        </g>
        <polygon className="st2" points="16.98,22.34 17.03,22.34 17.03,48.6 22.79,48.6 22.79,14.3 18.19,14.3 12.88,20.52 12.88,26.54
	12.93,26.54 "/>
      </svg>
    </>
  );
};
