import React, { FC } from "react";

import logo from "../../assets/images/logo.png";
import mainImage from "../../assets/images/main-image.png";
import { CustomizedFormButton } from "../../components/core/buttons/CustomizedFormButton";

const ApprovedMailPage: FC = () => {
  return (
    <div className="relative flex h-full w-full min-w-7xl max-w-screen-2xl m-auto">
      <div className="h-screen flex items-center justify-center w-full">
        <div className="w-1/2 m-auto">
          <div className="">
            <img className="ml-auto" src={logo} />
          </div>
          <h1 className="text-4xl font-semibold mb-8">Kære Carsten</h1>
          <p className="text-2xl mb-5">
            Din bruger er blevet godkendt, og du kan nu starte med at udarbejde
            din forretningsplan på platformen. Knæk og bræk med det!
          </p>
          <p className="text-2xl">God dag!</p>

          <div className="mt-16">
            <CustomizedFormButton
              text="Log ind"
              btn_type={"primary"}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovedMailPage;
