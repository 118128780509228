import React, { FC, SyntheticEvent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";

import cheveronRightIcon from "../../assets/icons/chevron-right.png";
import { NotFound404 } from "../../components/404";
import { AddBusinessPlanModal } from "../../components/BusinensPlanModals/addBpModal";
import TopLeftModal from "../../components/BusinensPlanModals/topLeftModal";
import TopRightModal from "../../components/BusinensPlanModals/topRightModal";
import { ConfirmationModal } from "../../components/core/modals/confirmation-modal";
import Layer from "../../components/Layer";
import {BP_LAYERS, IBPLAYER, LAYER} from "../../helpers/bpLayers";
import {
  BP_DELETE_MODAL_DATA,
  BP_REQUEST_CONFIRMATION_DATA,
  LAYER_DELETE_CONFIRMATION_DATA
} from "../../store/constants/bp-constants";
import { getBPUrl, HOME_ROUTE } from "../../store/constants/route-constants";
import { Dispatch, RootState } from "../../store/store";
import EmptyBusinessPlanPage from "../EmptyBusinessPlanPage";

const BusinessPlanPage: FC<any> = (
  {
    userId,
    businessPlans,
    getBPs,
    updateBp,
    deleteBp,
    permissionLayerType,
    savePermissionLayerType,
    saveDeletedLayer,
    deletedLayer,
    sendPermissionRequest,
    deleteLayer,
    deleteRedLayer,
  }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const hasId = !!id;
  const businessPlan = hasId ? businessPlans?.find((b: any) => b.id === id) : businessPlans[0];
  if (!hasId && businessPlan) navigate(getBPUrl(businessPlan.id));
  const [bpListModal, setbpListModal] = useState(false);
  const [bpDescription, setBpdescription] = useState(false);
  const [permissionTitle, setPermissionTitle] = useState("");
  const [bpDescriptionModal, setBpdescriptionModal] = useState({
    visible: false,
    name: "",
    description: "",
  });
  const [bpDeleteModal, setBpDeleteModal] = useState(false);

  const closebpListModalModal = (val: boolean = false) => {
    setbpListModal(val);
  };
  const closeBpDescriptionModal = (val: boolean = false) => {
    setBpdescription(val);
  };

  useEffect(() => {
    setbpListModal(false);
    getBPs();
  }, [id, userId]);

  const closeModal = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setBpdescriptionModal({ ...bpDescriptionModal, visible: false });
  };

  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showDeleteLayerModal, setShowDeleteLayerModal] = useState(false);

  const hideModal = () => {
    setShowRequestModal(false);
  }

  const hideDeleteLayerModal = () => {
    setShowDeleteLayerModal(false);
  }

  const onBlockClick = (layerType: string) => {
    setPermissionTitle(getPermissionTitle(layerType));
    setShowRequestModal(true);
    savePermissionLayerType(layerType);
  }

  const getPermissionTitle = (layerType: string) => {
    switch (layerType) {
      case LAYER.BLUE_LAYER:
        return BP_REQUEST_CONFIRMATION_DATA.blueTitle;
      case LAYER.YELLOW_LAEYR:
        return BP_REQUEST_CONFIRMATION_DATA.yellowTitle;
      case LAYER.GREEN_LAYER:
        return BP_REQUEST_CONFIRMATION_DATA.greenTitle;
      case LAYER.RED_LAEYR:
        return BP_REQUEST_CONFIRMATION_DATA.redTitle;
      default:
        return "";
    }
  }

  const onLayerDeleteClick = (layerId: string, layerType: string) => {
    setShowDeleteLayerModal(true);
    saveDeletedLayer({ layerId, layerType });
  }

  const requestPermission = () => {
    sendPermissionRequest({
      businessPlanId: businessPlan.id,
      layerType: permissionLayerType
    });
    setShowRequestModal(false);
  }

  const submitDeleteLayer = () => {
    if (deletedLayer.type === 'red_layer') deleteRedLayer({ id: deletedLayer.id, bpId: businessPlan.id });
    else deleteLayer(deletedLayer.id)
    setShowDeleteLayerModal(false);
  }

  const onUpdateDescription = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const name = event.target[0].value;
    const description = event.target[1].value;
    await updateBp({ name, description, id });
    setBpdescriptionModal({ ...bpDescriptionModal, visible: false });
  };

  const onUpdateBPDescription = () => {
    setBpdescription(false);
    setBpdescriptionModal({
      name: businessPlan.name,
      description: businessPlan.description,
      visible: true,
    });
  };

  const opentDeleteBpModal = () => {
    setBpdescription(false);
    setBpDeleteModal(true);
  }

  const cancelDelete = () => {
    setBpDeleteModal(false);
  }

  const deleteBusinessPlan = async () => {
    await deleteBp({ id });
    cancelDelete();
    navigate(HOME_ROUTE);
  };

  const renderLayer = (layer: IBPLAYER) => {
    const isBlocked = !businessPlan["access_" + layer.type];
    return <Layer
      onLayerDeleteClick={onLayerDeleteClick}
      onBlockClick={onBlockClick}
      bpId={id}
      key={layer.idx}
      data={businessPlan[layer.type] || {}}
      layer={layer}
      isBlocked={isBlocked}
    />;
  };

  if (businessPlan?.id)
    return (
      <div className="p-5 flex-grow">
        <div className="flex items-center justify-between">
          <div
            onClick={closeBpDescriptionModal.bind(null, !bpDescription)}
            className="text-2xl font-bold flex items-center cursor-pointer"
          >
            {businessPlan.name}
          </div>
          <div
            onClick={closebpListModalModal.bind(null, !bpListModal)}
            className="text-2xl font-bold flex items-center cursor-pointer"
          >
            Dine forretningsplaner
            <img className="ml-2 w-4 rotate-90" src={cheveronRightIcon} />
          </div>
        </div>
        <div className="w-full h-full flex space-x-5 pt-2 pb-8 relative">
          {BP_LAYERS.map(renderLayer)}
        </div>
        {bpDescription && (
          <TopLeftModal
            visible={bpDescription}
            data={businessPlan}
            close={closeBpDescriptionModal.bind(null, false)}
            updateBp={onUpdateBPDescription}
            deleteBp={opentDeleteBpModal}
          />
        )}
        {bpListModal && (
          <TopRightModal
            activeItem={id}
            items={businessPlans}
            visible={bpListModal}
            close={closebpListModalModal.bind(null, false)}
          />
        )}
        <AddBusinessPlanModal
          onSubmitForm={onUpdateDescription}
          closeModal={closeModal}
          data={bpDescriptionModal}
        />
        <ConfirmationModal
          setShowModal={cancelDelete}
          submitRed={deleteBusinessPlan}
          submitGreen={cancelDelete}
          showModal={bpDeleteModal}
          title={BP_DELETE_MODAL_DATA.title}
          body={BP_DELETE_MODAL_DATA.body}
          redBtnLabel={BP_DELETE_MODAL_DATA.redBtnLabel}
          greenBtnLabel={BP_DELETE_MODAL_DATA.greenBtnLabel}
        />
        <ConfirmationModal
          setShowModal={hideModal}
          submitRed={hideModal}
          submitGreen={requestPermission}
          showModal={showRequestModal}
          title={permissionTitle}
          body={BP_REQUEST_CONFIRMATION_DATA.body}
          redBtnLabel={BP_REQUEST_CONFIRMATION_DATA.redBtnLabel}
          greenBtnLabel={BP_REQUEST_CONFIRMATION_DATA.greenBtnLabel}
        />
        <ConfirmationModal
          setShowModal={hideDeleteLayerModal}
          submitRed={submitDeleteLayer}
          submitGreen={hideDeleteLayerModal}
          showModal={showDeleteLayerModal}
          title={LAYER_DELETE_CONFIRMATION_DATA.title}
          body={LAYER_DELETE_CONFIRMATION_DATA.body}
          redBtnLabel={LAYER_DELETE_CONFIRMATION_DATA.redBtnLabel}
          greenBtnLabel={LAYER_DELETE_CONFIRMATION_DATA.greenBtnLabel}
        />
      </div>
    );
  if (hasId && !businessPlan?.id) return <NotFound404 />;
  return <EmptyBusinessPlanPage />;
};

const mapState = (state: RootState) => ({
  businessPlans: state.businessPlan,
  userId: state.user.data?.id,
  permissionLayerType: state.layer?.permissionLayerType,
  deletedLayer: state.layer?.deletedLayer
});

const mapDispatch = (dispatch: Dispatch) => ({
  createBP: dispatch.businessPlan.createBP,
  getBPs: dispatch.businessPlan.getBPs,
  updateBp: dispatch.businessPlan.updateBP,
  deleteBp: dispatch.businessPlan.deleteBP,
  setLayer: dispatch.layer.setLayer,
  deleteLayer: dispatch.layer.deleteLayer,
  deleteRedLayer: dispatch.redLayer.deleteRedLayer,
  saveDeletedLayer: dispatch.layer.saveDeletedLayer,
  savePermissionLayerType: dispatch.layer.savePermissionLayerType,
  sendPermissionRequest: dispatch.layer.sendPermissionRequest
});

export default connect(mapState, mapDispatch)(BusinessPlanPage);
