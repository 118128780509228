import React from "react";

export interface Props {
  title: string;
  introduction: string;
}

export const LeftColorPickerComponent: React.FC<Props> = ({
  title,
  introduction,
  ...props
}: Props) => {
  return (
    <>
      <div className="font-quickStand font-mediumPlusPlus text-46 leading-58 mb-6">
        {title}
      </div>
      <div className="font-quickStand overflow-auto max-h-[40vh]" dangerouslySetInnerHTML={{ __html: introduction }} />
    </>
  );
};
