import React, { FC } from "react";

import { RegisterPageWrapper } from "../RegisterPageWrapper";

const UserRegisteredPage: FC = () => {
  return (
    <RegisterPageWrapper title="Din oprettelse er registreret">
      <h1 className="text-4xl font-semibold mb-8"></h1>
      <p className="text-2xl">
        Din bruger afventer godkendelse fra en administrator. Når din bruger er
        godkendt sender vi dig en mail. Processen tager typisk op til 48 timer.
      </p>
    </RegisterPageWrapper>
  );
};

export default UserRegisteredPage;
