import React, { useMemo } from "react";

import { INPUTS } from "../../../constants/ui";
import { Props as TextareaProps, Textarea } from "../Textarea";

export interface Props extends TextareaProps {
  placeHolder: string;
  type: typeof INPUTS[keyof typeof INPUTS];
  disabled: boolean;
}

export const CustomizedTextarea: React.FC<Props> = ({
  placeHolder,
  type = INPUTS.DEFAULT_FULL_WIDTH,
  disabled = false,
  ...props
}: Props) => {
  const getArgs = () => {
    const commons = {
      placeHolder,
      disabled,
      fontSize: "text-26",
      fontWeight: "font-medium",
      paddingX: "p-21",
      width: "w-full",
      fontFamily: "font-quickStand",
      ...props,
    };
    switch (type) {
      case INPUTS.DEFAULT_HALF_WIDTH:
        return {
          backgroundColor: "bg-gray-150",
          borderColor: "outline-gray-500",
          textColor: "text-black-900",
          height: "h-268",
          ...commons,
        } as Props;
      case INPUTS.MEDIUM_LIGHT_BLUE:
        return {
          backgroundColor: "bg-[#ffffff21]",
          borderColor: "outline-[#0000001f]",
          textColor: "text-white",
          placeHolderColor: "placeholder-white",
          height: "h-268",
          ...commons,
        } as Props;
      case INPUTS.MEDIUM_BLACK:
        return {
          backgroundColor: "bg-[#0000001f]",
          borderColor: "outline-[#0000001f]",
          textColor: "text-black",
          placeHolderColor: "placeholder-black",
          height: "h-198",
          ...commons,
        } as Props;
      case INPUTS.MEDIUM_LIGHT_GREEN:
        return {
          backgroundColor: "bg-green-200",
          borderColor: "outline-green-200",
          textColor: "text-white",
          placeHolderColor: "placeholder-white",
          height: "h-268",
          ...commons,
        } as Props;
      case INPUTS.MEDIUM_LIGHT_YELLOW:
        return {
          backgroundColor: "bg-yellow-200",
          borderColor: "outline-yellow-200",
          textColor: "text-white",
          placeHolderColor: "placeholder-white",
          height: "h-268",
          ...commons,
        } as Props;
      default:
        return {
          backgroundColor: "bg-gray-500",
          borderColor: "outline-gray-500",
          textColor: "text-black-900",
          height: "h-[54vh]",
          maxHeight: "max-h-[550px]",
          ...commons,
        } as Props;
    }
  };
  const args = getArgs();
  return (
    <>
      <Textarea {...args} />
    </>
  );
};
