import React, { FC } from "react";

import { CustomizedInput, Props as InputProps } from "./CustomizedInput";

interface ICustomizedLabelInputProps extends InputProps {
  label: string;
  error?: string;
  fontsizestyle?: string;
}

export const CustomizedLabelInput: FC<ICustomizedLabelInputProps> = ({
  label,
  placeHolder,
  disabled,
  styleType,
  error,
  fontsizestyle,
  ...props
}) => {
  return (
    <div className="w-full">
      <div className={`text-2xl mb-2 flex ${fontsizestyle || ''}`}>
        <label htmlFor={props.id}>{label}</label>
      </div>
      <CustomizedInput
        {...props}
        placeHolder={placeHolder}
        styleType={styleType}
        disabled={disabled || false}
      />
      <p className="text-base h-4 text-red-500">{error || ""}</p>
    </div>
  );
};
