import React, { FC } from "react";

import { RegisterPageWrapper } from "../RegisterPageWrapper";

const NotFoundPage: FC = () => (
  <RegisterPageWrapper title="404 fejl">
    <p className="text-2xl">Siden du søger efter finder desværre ikke.</p>
  </RegisterPageWrapper>
);

export default NotFoundPage;
