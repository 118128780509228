import React, { FC } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "../../store/store";

import cheveronRightIcon from "../../assets/icons/chevron-right.png";
import smallPlusIcon from "../../assets/icons/small-plus.png";
import { BUSINESS_PLAN_ROUTE, EMPTY_BUSINESS_PLAN_ROUTE, getBPUrl } from "../../store/constants/route-constants";

interface IBPModalPRops {
  isEditor: boolean;
  visible: boolean;
  items: {
    id: string;
    name: string;
  }[];
  close?: () => void;
  activeItem: any;
}

const TopRightModal: FC<IBPModalPRops> = ({
  isEditor,
  items,
  visible,
  close,
  activeItem,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`${
          !visible && "hidden"
        } w-96 h-[auto!important] overflow-y-auto overflow-x-hidden absolute top-32 right-4 z-50 w-full h-modal md:h-full drop-shadow-md`}
      >
        <div className="bg-secondBg rounded-lg shadow">
          <div className="max-h-[60vh] p-4 space-y-1 text-1xl font-medium overflow-y-auto">
            {items.length &&
              items.map((bp) => (
                <Link
                  key={bp.id}
                  to={getBPUrl(bp.id)}
                  className={`cursor-pointer  flex items-center ${
                    activeItem === bp.id ? "font-[700]" : "font-[600]"
                  }`}
                >
                  {bp.name}
                  <img className="ml-3 w-4" src={cheveronRightIcon} />
                </Link>
              ))}
          </div>
          {isEditor && (
            <div className="p-4 text-1xl flex justify-end items-center gap-4">
              <Link
                to={EMPTY_BUSINESS_PLAN_ROUTE}
                className="flex items-center justify-center"
              >
                <span>Tilføj</span>
                <span className="ml-2 cursor-pointer w-9 h-9 flex items-center justify-center bg-greenLayer rounded-full">
                  <img src={smallPlusIcon} />
                </span>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div onClick={close} className="absolute inset-0 z-1" />
    </>
  );
};

const mapState = (state: RootState) => ({
  isEditor: state.user.isEditor,
});

export default connect(mapState)(TopRightModal);
