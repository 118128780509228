import React from "react";

export interface Props {
  isActive: boolean;
}

export const IconTen: React.FC<Props> = ({ isActive = false }: Props) => {
  return (
    <>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 170.08 170.08" style={{ ['enableBackground' as any]: 'new 0 0 170.08 170.08', 'filter': 'none' }} xmlSpace="preserve">
        <style type="text/css">{`
            .st10{fill:${isActive ? '#e01483' : '#B2B2B2'};}
          svg:hover #SVGID_1_10{fill:#e01483;}
          .st1{clip-path:url(#SVGID_00000014633921794799521640000011042909493708115334_);fill:#FFFFFF;}
          .st2{fill:#FFFFFF;}
        `}</style>
        <rect className="st10" width="170.08" height="170.08" />
        <g>
          <defs>
            <rect id="SVGID_1_10" x="0" y="0" width="170.08" height="170.08" />
          </defs>
          <use xlinkHref="#SVGID_1_10" style={{ 'overflow': 'visible', 'fill': isActive ? '#e01483' : '#B2B2B2' }} />
          <clipPath id="SVGID_00000014633921794799521640000011042909493708115334_">
            <use xlinkHref="#SVGID_1_10" style={{ 'overflow': 'visible' }} />
          </clipPath>
          <path className="st1" d="M43.24,39.7V23.09
		c0-5.37-2.68-9.26-8.56-9.26c-5.82,0-8.51,3.9-8.51,9.26V39.7c0,5.31,2.69,9.26,8.51,9.26C40.56,48.97,43.24,45.02,43.24,39.7
		 M31.95,40.61V22.18c0-2.08,0.76-3.54,2.73-3.54c2.02,0,2.78,1.47,2.78,3.54v18.43c0,2.08-0.76,3.54-2.78,3.54
		C32.71,44.16,31.95,42.69,31.95,40.61"/>
        </g>
        <polygon className="st2" points="60.65,28.15 62.84,28.15 62.84,13.86 59.8,13.86 58.41,21.91 58.39,21.91 56.83,13.86 54.03,13.86
	54.03,28.15 56.01,28.15 56.01,18.88 56.05,18.88 57.84,28.15 58.96,28.15 60.58,18.88 60.65,18.88 "/>
        <rect x="64.63" y="13.86" className="st2" width="2.4" height="14.3" />
        <polygon className="st2" points="73.7,28.15 75.81,28.15 75.81,13.86 73.81,13.86 73.81,22.06 71.21,13.86 68.85,13.86 68.85,28.15
	70.88,28.15 70.88,19.17 "/>
        <g>
          <path className="st2" d="M81.32,13.85H77.6v14.3h3.71c2.57,0,3.48-1.65,3.48-3.84v-6.62C84.8,15.48,83.89,13.85,81.32,13.85
		 M80.01,26.19V15.82h1.01c0.99,0,1.37,0.59,1.37,1.43v7.49c0,0.87-0.38,1.46-1.37,1.46H80.01z"/>
          <path className="st2" d="M89.79,15.82c0.82,0,1.18,0.38,1.18,1.16v2.11c0,0.76-0.36,1.18-1.18,1.18h-0.8v-4.45H89.79z M91.25,28.15
		h2.53l-2.3-6.5c1.37-0.32,1.92-1.22,1.92-2.76v-1.83c0-2-0.89-3.21-3.31-3.21h-3.5v14.3h2.4V21.6L91.25,28.15z"/>
          <polygon className="st2" points="95.13,28.15 100.87,28.15 100.87,26.19 97.53,26.19 97.53,21.85 99.92,21.85 99.92,19.91 97.53,19.91
		97.53,15.82 100.87,15.82 100.87,13.86 95.13,13.86 	"/>
          <path className="st2" d="M58.79,34.27v11.05c0,0.84-0.34,1.41-1.18,1.41c-0.84,0-1.18-0.57-1.18-1.41V34.27h-2.4v10.82
		c0,2.21,1.05,3.65,3.59,3.65c2.45,0,3.4-1.43,3.4-3.65V34.27H58.79z"/>
          <polygon className="st2" points="62.8,48.57 68.07,48.57 68.07,46.61 65.2,46.61 65.2,34.27 62.8,34.27 	" />
          <rect x="69.17" y="34.27" className="st2" width="2.4" height="14.3" />
          <path className="st2" d="M77.01,42.83h1.14v2.53c0,0.93-0.4,1.37-1.18,1.37c-0.82,0-1.18-0.61-1.18-1.48v-7.68
		c0-0.86,0.38-1.48,1.24-1.48s1.2,0.51,1.2,1.39v1.45h2.19v-1.27c0-2.11-0.78-3.59-3.44-3.59c-2.53,0-3.61,1.62-3.61,3.86v6.92
		c0,2.21,0.95,3.86,3.02,3.86c1.31,0,1.98-0.65,2.3-1.73v1.56h1.73v-7.63h-3.42V42.83z"/>
          <polygon className="st2" points="86.95,48.57 89.37,48.57 89.37,34.27 86.95,34.27 86.95,40.32 84.63,40.32 84.63,34.27 82.22,34.27
		82.22,48.57 84.63,48.57 84.63,42.26 86.95,42.26 	"/>
          <polygon className="st2" points="91.16,48.57 96.9,48.57 96.9,46.61 93.57,46.61 93.57,42.26 95.95,42.26 95.95,40.32 93.57,40.32
		93.57,36.23 96.9,36.23 96.9,34.27 91.16,34.27 	"/>
          <path className="st2" d="M101.9,34.27h-3.71v14.3h3.71c2.57,0,3.48-1.65,3.48-3.84v-6.62C105.38,35.89,104.47,34.27,101.9,34.27
		 M100.59,46.61V36.23h1.01c0.99,0,1.37,0.59,1.37,1.43v7.49c0,0.87-0.38,1.46-1.37,1.46H100.59z"/>
          <polygon className="st2" points="56.1,92.9 40.11,108.9 56.1,124.89 	" />
          <polygon className="st2" points="113.98,124.89 129.97,108.9 113.98,92.9 	" />
          <polygon className="st2" points="101.06,80.03 85.06,64.04 69.07,80.03 	" />
          <polygon className="st2" points="69.07,137.82 85.06,153.82 101.06,137.82 	" />
          <rect x="67.68" y="93.97" className="st2" width="34.73" height="9.64" />
          <rect x="67.68" y="113.8" className="st2" width="34.73" height="10.08" />
        </g>
        <polygon className="st2" points="16.41,22.28 16.41,48.56 22.18,48.56 22.18,14.23 17.57,14.23 12.26,20.46 12.26,26.49 12.31,26.49
	16.36,22.28 "/>
      </svg>
    </>
  );
};
