import React, { FC, FormEvent, ReactNode } from "react";

import logo from "../../assets/images/logo.png";
import mainImage from "../../assets/images/main-image.png";
import { CustomizedFormButton } from "../../components/core/buttons/CustomizedFormButton";

interface IRegisterPageWrapperProps {
  children: ReactNode;
  title: string;
  action?: {
    title: string;
    handler: (event: FormEvent<HTMLFormElement>) => void;
    disabled: boolean;
  };
}

export const RegisterPageWrapper: FC<IRegisterPageWrapperProps> = ({
  children,
  title,
  action,
}) => {
  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    action?.handler(event);
  };
  return (
    <div className="relative flex h-full w-full min-w-7xl">
      <div className="h-screen w-1/2 px-16 flex items-center justify-center overflow-y-scroll no-scrollbar">
        <div className="w-full" style={{ maxWidth: "612px" }}>
          <h1 className="text-4xl font-semibold mb-6">{title}</h1>
          <form onSubmit={onSubmit}>
            {children}
            {action && (
              <div className="mt-10">
                <CustomizedFormButton
                  type="submit"
                  text={action.title}
                  btn_type={"primary"}
                  disabled={action.disabled}
                />
              </div>
            )}
          </form>
          <div className="mt-10 text-center">
            <img className="mx-auto" src={logo} />
          </div>
        </div>
      </div>
      <div className="h-screen w-1/2">
        <img src={mainImage} className="h-full w-full object-cover" />
      </div>
    </div>
  );
};
