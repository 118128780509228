import React from "react";

import { FORM_BUTTONS, INPUTS  } from "../../../../constants/ui";
import { CustomizedFormButton } from "../../buttons/CustomizedFormButton";
import { CustomizedInput } from "../../inputs/CustomizedInput";
import { RadioGroup } from "../../RadioGroup";
import { CustomizedTextarea } from "../../textarea/CustomizedTextarea";

export interface Props {
  inputLabel: string;
  colorPickerLabel: string;
  textareaLabel: string;
  SubmitButtonText: string;
  FormSubmitBtnDisabled: boolean;
  CancelButtonText: string;
  FormCancelBtnDisabled: boolean;
  onSubmit?: (e: any) => void;
  data: any;
  rightcomponentbackgroundcolor: string;
}

export const RightComponent: React.FC<Props> = ({
  inputLabel,
  colorPickerLabel,
  textareaLabel,
  SubmitButtonText,
  FormSubmitBtnDisabled,
  CancelButtonText,
  FormCancelBtnDisabled,
  onSubmit,
  data,
  ...props
}: Props) => {
  const borderColor = props.rightcomponentbackgroundcolor;
  const isCreate = !!data?.id;
  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="mb-6">
          <div className="text-2xl mb-2 flex">
            <label className="font-quickStand font-medium text-26 leading-32 text-white">
              {inputLabel}
            </label>
          </div>
          <CustomizedInput
            autoFocus
            defaultValue={data?.title || ''}
            placeHolder={""}
            disabled={false}
            borderColor="none"
            borderSize="0"
            styleType={INPUTS.MEDIUM_LIGHT_BLUE}
            {...props}
          />
        </div>
        <div className="mb-6">
          <div className="text-2xl mb-2 flex">
            <label className="font-quickStand font-medium text-26 leading-32 text-white">
              {colorPickerLabel}
            </label>
          </div>
          <RadioGroup color={data?.color || ''} border={borderColor} />
        </div>
        {textareaLabel.length > 0 && (
          <>
            <div className="mb-6">
              <div className="text-2xl mb-2 flex">
                <label className="font-quickStand font-medium text-26 leading-32 text-white">
                  {textareaLabel}
                </label>
              </div>
              <CustomizedTextarea
                defaultValue={data?.textareaTitle}
                placeHolder={""}
                disabled={false}
                type={INPUTS.MEDIUM_LIGHT_BLUE}
                {...props}
              />
            </div>
          </>
        )}
        <div className="flex justify-between">
        {isCreate ? <div className="w-[35%]">
            <CustomizedFormButton
              text={CancelButtonText}
              btn_type={FORM_BUTTONS.DANGER}
              disabled={FormCancelBtnDisabled}
              type="submit"
              id="delete"
              {...props}
            />
          </div> : null}
          <div className={isCreate ? "w-[55%]" : "w-[100%]"}>
            <CustomizedFormButton
              text={SubmitButtonText}
              btn_type={FORM_BUTTONS.PRIMARY}
              disabled={FormSubmitBtnDisabled}
              type="submit"
              id="save"
              {...props}
            />
          </div>
        </div>
      </form>
    </>
  );
};
