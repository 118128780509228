/* eslint-disable no-console */
import { createModel } from "@rematch/core";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";

import history from "../../helpers/history";
import { APPLICATION_BUSINESS_PLAN_URL } from "../constants/api-contstants";
import { BUSINESS_PLAN_ROUTE } from "../constants/route-constants";
import http from "../http/http-common";
import type { RootModel } from "./index";
import { BusinessPlanState } from "./interfaces/businessPlan";

export const businessPlan = createModel<RootModel>()({
  state: [] as BusinessPlanState,
  reducers: {
    setBusinessPlanes(state, data) {
      return [...data] as BusinessPlanState;
    },
    setBusinessPlane(state, data) {
      return [...state, data] as BusinessPlanState;
    },
    updateBusinessPlan(state, data) {
      state.forEach((bp) => {
        if (bp.id === data.id) {
          bp.name = data.name;
          bp.description = data.description;
        }
      });
      return [...state];
    },
    deleteBusinessPlan(state, data) {
      return [...state.filter((i) => i.id !== data.id)];
    },
    clearBusinessPlanes() {
      return [];
    },
    setBusinessPlanLayer(state, data) {
      state.forEach((bp: any) => {
        if (bp.id === data.bpId) {
          bp[data.type] = data.layer;
        }
      })
      return state;
    }
  },
  effects: (dispatch) => ({
    async getBPs() {
      try {
        const result = await http.get(APPLICATION_BUSINESS_PLAN_URL);
        if (result?.status === 200) {
          dispatch.businessPlan.setBusinessPlanes(result.data);
          dispatch.layer.setLayer({});
        }
      } catch (err) {
        throw err;
      }
    },

    async createBP(data) {
      try {
        const result: AxiosResponse = await http.post(APPLICATION_BUSINESS_PLAN_URL, {
          data,
        });
        if (result.status === 201) {
          dispatch.businessPlan.setBusinessPlane(result.data);
          history.replace(`${BUSINESS_PLAN_ROUTE}/${result.data.id}`);
          toast.success("Business Plan created!");
        }
        if (result.request.status === 400) {
          const json = JSON.parse(result.request.responseText);
          toast.error(json?.message || 'Error creating BP!');
        }
      } catch (err) {
        toast.error('err');
        throw err;
      }
    },

    async updateBP(data) {
      try {
        const result = await http.put(APPLICATION_BUSINESS_PLAN_URL, {
          data,
        });
        if (result.status === 200) {
          dispatch.businessPlan.updateBusinessPlan(data);
          toast.success("Business Plan updated!");
        }
      } catch (err) {
        throw err;
      }
    },

    async deleteBP(data) {
      try {
        const result = await http.delete(APPLICATION_BUSINESS_PLAN_URL, {
          data,
        });
        if (result.status === 200) {
          dispatch.businessPlan.deleteBusinessPlan(data);
          toast.success("Business Plan deleted!");
        }
      } catch (err) {
        throw err;
      }
    },

    async getDataForDocx(id) {
      try {
        const result = await http.get(`${APPLICATION_BUSINESS_PLAN_URL}/doc/${id}`);
        return result.status === 200 ? result.data : false;
      } catch (error) {
        throw error;
      }
    }

  }),
});
