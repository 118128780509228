import React from "react";

export interface Props {
  isActive: boolean;
}

export const IconThree: React.FC<Props> = ({ isActive = false }: Props) => {
  return (
    <>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 170.08 170.08" style={{ ['enableBackground' as any]: 'new 0 0 170.08 170.08', 'filter': 'none' }} xmlSpace="preserve">
        <style type="text/css">
        {`  .st03{fill:${isActive ? '#4ca146' : '#B2B2B2'};}
            svg:hover .st03{fill:#4ca146;}
        .st13{fill:#FFFFFF;}`}
      </style>
        <rect className="st03" width="170.08" height="170.08" />
        <g>
          <path className="st13" d="M24.27,49.01c5.82,0,8.35-3.09,8.35-7.84v-5.06c0-3.13-1.37-5.16-4.25-5.82c2.38-0.76,3.94-2.68,3.94-5.56
		v-2.99c0-4.86-2.33-7.84-7.64-7.84c-5.97,0-8.04,3.54-8.04,8.6v3.03h5.26v-3.49c0-2.12,0.66-3.34,2.48-3.34
		c1.82,0,2.48,1.21,2.48,3.08v3.69c0,1.82-1.21,2.78-3.08,2.78h-2.18v4.4h2.43c2.02,0,2.83,0.96,2.83,2.78v5.67
		c0,1.82-0.81,3.09-2.58,3.09c-1.97,0-2.68-1.32-2.68-3.34v-4.91h-5.26v4.5C16.33,45.67,18.4,49.01,24.27,49.01"/>
          <path className="st13" d="M142.17,106.29c0-4.79-3.07-8.67-7.72-8.67c-2.33,0-5.13,2.55-6.65,4.11c-1.52-1.57-4.14-4.12-6.47-4.12
		c-4.65,0-7.91,3.88-7.91,8.67c0,2.39,0.94,4.56,2.47,6.13l11.92,11.94l11.91-11.93l-0.01-0.01
		C141.22,110.86,142.17,108.69,142.17,106.29"/>
          <path className="st13" d="M91.41,143.87c-0.04,0-0.08,0-0.12,0c-0.91-0.06-1.65-0.73-1.81-1.62l-6.06-35.27l-9.17,17.01
		c-0.4,0.75-1.24,1.15-2.07,1c-0.83-0.15-1.48-0.81-1.6-1.65L66.69,96.1l-7.12,16c-0.31,0.71-1.02,1.16-1.79,1.16H30.08
		c-1.08,0-1.96-0.88-1.96-1.96c0-1.08,0.88-1.96,1.96-1.96H56.5l9.37-21.05c0.36-0.8,1.19-1.26,2.06-1.14
		c0.86,0.12,1.54,0.8,1.67,1.66l4.02,28.07l9.05-16.77c0.4-0.74,1.21-1.14,2.04-1c0.82,0.14,1.47,0.78,1.61,1.6l5.61,32.67
		l20.44-67.9c0.23-0.77,0.92-1.32,1.72-1.39c0.81-0.06,1.57,0.37,1.92,1.1l10.76,22.3c0.47,0.97,0.06,2.14-0.91,2.61
		c-0.97,0.47-2.15,0.06-2.61-0.91l-8.57-17.76l-21.39,71.04C93.03,143.3,92.26,143.87,91.41,143.87"/>
          <path className="st13" d="M52.15,28.32c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.68-1.14-1.14-1.14-1.94v-0.3c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.49c0,1.64,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.02
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96h-2.24v1.92C48.76,26.95,49.71,28.32,52.15,28.32"/>
          <path className="st13" d="M62.11,13.85v11.05c0,0.84-0.34,1.41-1.18,1.41c-0.84,0-1.18-0.57-1.18-1.41V13.85h-2.4v10.82
		c0,2.21,1.05,3.65,3.59,3.65c2.45,0,3.4-1.43,3.4-3.65V13.85H62.11z"/>
          <polygon className="st13" points="70.97,28.15 73.08,28.15 73.08,13.85 71.07,13.85 71.07,22.06 68.48,13.85 66.12,13.85 66.12,28.15
		68.14,28.15 68.14,19.17 	"/>
          <path className="st13" d="M79,13.86h-3.71v14.3H79c2.57,0,3.48-1.64,3.48-3.84v-6.62C82.48,15.48,81.58,13.86,79,13.86 M77.69,26.19
		V15.82h1.01c0.99,0,1.37,0.59,1.37,1.43v7.49c0,0.87-0.38,1.46-1.37,1.46H77.69z"/>
          <polygon className="st13" points="89,28.15 91.42,28.15 91.42,13.85 89,13.85 89,19.91 86.68,19.91 86.68,13.85 84.27,13.85
		84.27,28.15 86.68,28.15 86.68,21.85 89,21.85 	"/>
          <polygon className="st13" points="93.64,28.15 99.37,28.15 99.37,26.19 96.04,26.19 96.04,21.85 98.43,21.85 98.43,19.91 96.04,19.91
		96.04,15.82 99.37,15.82 99.37,13.85 93.64,13.85 	"/>
          <path className="st13" d="M104.8,13.86h-3.71v14.3h3.71c2.57,0,3.48-1.64,3.48-3.84v-6.62C108.28,15.48,107.37,13.86,104.8,13.86
		 M103.49,26.19V15.82h1.01c0.99,0,1.37,0.59,1.37,1.43v7.49c0,0.87-0.38,1.46-1.37,1.46H103.49z"/>
          <path className="st13" d="M52.51,48.74c2.55,0,3.65-1.64,3.65-3.86v-6.92c0-2.23-1.1-3.86-3.65-3.86c-2.53,0-3.63,1.62-3.63,3.86v6.92
		C48.89,47.09,49.98,48.74,52.51,48.74 M52.51,46.73c-0.84,0-1.22-0.61-1.22-1.48v-7.68c0-0.87,0.38-1.48,1.22-1.48
		c0.87,0,1.24,0.61,1.24,1.48v7.68C53.76,46.12,53.38,46.73,52.51,46.73"/>
          <path className="st13" d="M61.58,42.83h1.14v2.53c0,0.93-0.4,1.37-1.18,1.37c-0.82,0-1.18-0.61-1.18-1.48v-7.68
		c0-0.87,0.38-1.48,1.24-1.48c0.87,0,1.2,0.51,1.2,1.39v1.46H65v-1.27c0-2.11-0.78-3.59-3.44-3.59c-2.53,0-3.61,1.62-3.61,3.86v6.92
		c0,2.21,0.95,3.86,3.02,3.86c1.31,0,1.98-0.65,2.3-1.73v1.56H65v-7.63h-3.42V42.83z"/>
          <polygon className="st13" points="68.31,36.23 70.29,36.23 70.29,48.57 72.72,48.57 72.72,36.23 74.7,36.23 74.7,34.27 68.31,34.27 	" />
          <path className="st13" d="M79.02,36.23c0.82,0,1.18,0.38,1.18,1.16v2.11c0,0.76-0.36,1.18-1.18,1.18h-0.8v-4.45H79.02z M80.48,48.57
		h2.53l-2.3-6.5c1.37-0.32,1.92-1.22,1.92-2.76v-1.83c0-2-0.89-3.21-3.31-3.21h-3.5v14.3h2.4v-6.56L80.48,48.57z"/>
          <rect x="84.36" y="34.27" className="st13" width="2.4" height="14.3" />
          <polygon className="st13" points="94.02,34.27 92.33,44.24 92.31,44.24 90.67,34.27 88.22,34.27 90.94,48.57 93.55,48.57 96.28,34.27
		"/>
          <path className="st13" d="M100.32,48.74c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.68-1.14-1.14-1.14-1.94v-0.3c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.49c0,1.64,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.02
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96h-2.24v1.92C96.93,47.37,97.88,48.74,100.32,48.74"/>
          <polygon className="st13" points="105.51,48.57 111.25,48.57 111.25,46.61 107.92,46.61 107.92,42.26 110.3,42.26 110.3,40.32
		107.92,40.32 107.92,36.23 111.25,36.23 111.25,34.27 105.51,34.27 	"/>
          <polygon className="st13" points="112.96,48.57 118.23,48.57 118.23,46.61 115.36,46.61 115.36,34.27 112.96,34.27 	" />
        </g>
      </svg>

    </>
  );
};
