import React from "react";

export interface Props {
  isActive: boolean;
}

export const IconFourTen: React.FC<Props> = ({ isActive = false }: Props) => {
  return (
    <>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 170.08 170.08" style={{ ['enableBackground' as any]: 'new 0 0 170.08 170.08', 'filter': 'none' }} xmlSpace="preserve">
        <style type="text/css">
          {`  .st014{fill:${isActive ? '#3397d4' : '#B2B2B2'};}
            svg:hover .st014{fill:#3397d4;}
        .st1{fill:#FFFFFF;}`}
        </style>
        <rect className="st014" width="170.08" height="170.08" />
        <polygon className="st1" points="22.16,14.31 22.16,48.59 16.39,48.59 16.39,22.35 16.34,22.35 12.3,26.54 12.25,26.54 12.25,20.53
	17.56,14.31 "/>
        <path className="st1" d="M39.71,48.59v-5.56h2.33v-4.5h-2.33V14.3h-5.67l-9.96,25.19v3.54h10.17v5.56H39.71z M29.14,38.53l5.16-14.11
	h0.05v14.11H29.14z"/>
        <g>
          <path className="st1" d="M130.59,124.09c-2.14-2.89-15.97-20.52-35.35-20.52c-16.83,0-34.84,15.27-34.84,15.27s-6.76-13.84-20.6-15.17
		c-0.08,0-0.17-0.02-0.26-0.02h-0.09c-0.29,0.02-0.56,0.3-0.53,0.55c0.04,0.24,0.22,0.51,0.22,0.51c1.78,2.96,6.34,11.38,6.34,19.8
		c0,8.44-4.58,16.89-6.35,19.84L39,144.53c-0.05,0.09-0.09,0.18-0.09,0.29c0,0.29,0.23,0.52,0.51,0.54l0.15-0.01
		c0.05,0,0.1,0,0.15-0.01c13.88-1.29,20.67-15.18,20.67-15.18s18.01,15.27,34.84,15.27c19.39,0,33.21-17.63,35.35-20.51
		c0.06-0.08,0.11-0.15,0.15-0.2c0.02-0.07,0.05-0.13,0.05-0.21c0-0.08-0.02-0.15-0.05-0.21
		C130.69,124.23,130.64,124.17,130.59,124.09 M106.01,126.73c-3.13,0-5.66-2.54-5.66-5.66c0-3.12,2.53-5.65,5.66-5.65
		c3.13,0,5.66,2.53,5.66,5.65C111.68,124.19,109.14,126.73,106.01,126.73"/>
          <path className="st1" d="M39.36,70.44c-0.04-0.01-0.07-0.02-0.11-0.02c-0.31,0-0.56,0.25-0.56,0.56v3.86c0,0.16,0.06,0.3,0.16,0.4
		c9.36,8.13,18.22,0.51,20.27-1.5c0.04-0.06,0.09-0.12,0.15-0.16c0.09-0.06,0.19-0.1,0.31-0.1c0.14,0,0.28,0.06,0.38,0.15
		c0.01,0.01,0.02,0.02,0.03,0.02c10.57,10.09,21.65,1.92,23.96,0l0.23-0.21c0.07-0.03,0.13-0.04,0.21-0.04
		c0.08,0,0.16,0.01,0.22,0.04l0.23,0.21c2.31,1.92,13.39,10.09,23.96,0c0-0.01,0.02-0.01,0.02-0.02c0.1-0.09,0.23-0.15,0.38-0.15
		c0.11,0,0.22,0.04,0.31,0.1c0.06,0.04,0.11,0.1,0.16,0.16c2.05,2.01,10.9,9.63,20.26,1.5c0.1-0.1,0.16-0.24,0.16-0.4v-3.86
		c0-0.31-0.24-0.56-0.55-0.56c-0.04,0-0.08,0.01-0.12,0.02c-0.09,0.05-0.18,0.1-0.28,0.15c-2.88,1.44-11.8,4.87-19.91-2.8
		c-0.08-0.07-0.16-0.14-0.24-0.22c-0.07-0.03-0.15-0.05-0.24-0.05c-0.06,0-0.12,0.02-0.17,0.03c-0.12,0.05-0.22,0.13-0.28,0.22
		c-2.41,1.84-13.5,9.34-23.37,0.14l-0.14-0.14c-0.1-0.09-0.24-0.17-0.4-0.17c-0.15,0-0.29,0.07-0.39,0.17l-0.15,0.14
		c-9.87,9.21-20.96,1.7-23.37-0.14c-0.06-0.09-0.16-0.17-0.27-0.22c-0.06-0.01-0.11-0.03-0.18-0.03c-0.08,0-0.16,0.02-0.23,0.05
		c-0.07,0.08-0.16,0.15-0.24,0.22c-8.1,7.67-17.02,4.24-19.9,2.8C39.54,70.54,39.44,70.49,39.36,70.44"/>
          <path className="st1" d="M59.12,87.77c0.04-0.06,0.09-0.11,0.15-0.15c0.09-0.06,0.19-0.1,0.31-0.1c0.14,0,0.28,0.06,0.38,0.15
		c0.01,0.01,0.02,0.02,0.03,0.02c10.57,10.07,21.65,1.91,23.96,0l0.23-0.21c0.06-0.03,0.13-0.04,0.21-0.04
		c0.08,0,0.16,0.01,0.22,0.04l0.23,0.21c2.31,1.91,13.39,10.07,23.96,0c0-0.01,0.01-0.01,0.02-0.02c0.1-0.1,0.23-0.15,0.38-0.15
		c0.12,0,0.22,0.05,0.31,0.1c0.07,0.04,0.11,0.09,0.16,0.15c2.05,2.02,10.9,9.64,20.26,1.5c0.1-0.1,0.16-0.23,0.16-0.39v-3.87
		c0-0.31-0.25-0.55-0.55-0.55c-0.04,0-0.08,0.01-0.12,0.02c-0.09,0.04-0.18,0.09-0.28,0.14c-2.88,1.44-11.81,4.86-19.91-2.79
		c-0.08-0.07-0.15-0.14-0.23-0.22c-0.08-0.04-0.15-0.05-0.24-0.05c-0.06,0-0.12,0.01-0.17,0.04c-0.12,0.03-0.22,0.11-0.28,0.21
		c-2.41,1.84-13.5,9.34-23.37,0.14l-0.14-0.14c-0.1-0.1-0.24-0.15-0.4-0.15c-0.14,0-0.29,0.06-0.39,0.15l-0.15,0.14
		c-9.87,9.21-20.96,1.7-23.37-0.14c-0.06-0.1-0.16-0.18-0.27-0.21c-0.06-0.03-0.12-0.04-0.18-0.04c-0.08,0-0.16,0.01-0.23,0.05
		c-0.08,0.08-0.16,0.15-0.24,0.22c-8.1,7.66-17.02,4.23-19.9,2.79c-0.1-0.05-0.2-0.1-0.28-0.14c-0.04-0.01-0.07-0.02-0.11-0.02
		c-0.31,0-0.56,0.25-0.56,0.55v3.87c0,0.16,0.06,0.29,0.16,0.39C48.21,97.41,57.07,89.79,59.12,87.77"/>
        </g>
        <polygon className="st1" points="54.29,28.15 59.56,28.15 59.56,26.19 56.69,26.19 56.69,13.86 54.29,13.86 " />
        <rect x="60.66" y="13.86" className="st1" width="2.4" height="14.3" />
        <polygon className="st1" points="69.89,13.86 68.21,23.83 68.19,23.83 66.54,13.86 64.1,13.86 66.82,28.15 69.43,28.15 72.15,13.86 " />
        <polygon className="st1" points="73.14,28.15 78.88,28.15 78.88,26.19 75.55,26.19 75.55,21.85 77.93,21.85 77.93,19.91 75.55,19.91
	75.55,15.82 78.88,15.82 78.88,13.86 73.14,13.86 "/>
        <polygon className="st1" points="79.05,15.82 81.03,15.82 81.03,28.15 83.46,28.15 83.46,15.82 85.44,15.82 85.44,13.86 79.05,13.86 " />
        <rect x="54.29" y="34.27" className="st1" width="2.4" height="14.3" />
        <polygon className="st1" points="66.29,48.57 68.71,48.57 68.71,34.27 66.29,34.27 66.29,40.32 63.97,40.32 63.97,34.27 61.56,34.27
	61.56,48.57 63.97,48.57 63.97,42.26 66.29,42.26 "/>
        <path className="st1" d="M73.67,38.3h0.02l0.95,5.74h-1.92L73.67,38.3z M71.98,48.57l0.42-2.62h2.55l0.44,2.62h2.47l-2.72-14.3H72.4
	l-2.7,14.3H71.98z"/>
        <polygon className="st1" points="83.46,34.27 81.77,44.25 81.75,44.25 80.1,34.27 77.66,34.27 80.38,48.57 82.99,48.57 85.71,34.27 " />
        <polygon className="st1" points="86.7,48.57 92.44,48.57 92.44,46.61 89.11,46.61 89.11,42.26 91.49,42.26 91.49,40.32 89.11,40.32
	89.11,36.23 92.44,36.23 92.44,34.27 86.7,34.27 "/>
        <polygon className="st1" points="92.61,36.23 94.59,36.23 94.59,48.57 97.02,48.57 97.02,36.23 99,36.23 99,34.27 92.61,34.27 " />
      </svg>
    </>
  );
};
