import React, { FC } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";

import {
  SIGN_IN_ROUTE,
} from "../../store/constants/route-constants";
import { Dispatch, RootState } from "../../store/store";
import BusinessPlanPage from "../BusinessPlanPage";

const HomePage: FC<any> = ({ isAuth }) => {

  if (!isAuth) return <Navigate to={SIGN_IN_ROUTE} />;
  return <BusinessPlanPage />;
};

const mapState = (state: RootState) => ({
  isAuth: state.user.isAuthenticated
});

const mapDispatch = (dispatch: Dispatch) => ({
  getBPs: dispatch.businessPlan.getBPs,
});

export default connect(mapState, mapDispatch)(HomePage);
