import React, { FC, useState } from "react";
import { useNavigate } from "react-router";

import plusIcon from "../../assets/icons/white-plus.png";
import arrowIcon from "../../assets/icons/arrow.png";
import blockIcon from "../../assets/icons/block.png";
import { IBPLAYER } from "../../helpers/bpLayers";
import { CardTag, RedLayerTag } from "./tag";
import { Dispatch, RootState } from "../../store/store";
import { connect } from "react-redux";
import { getLayerUrl } from "../../store/constants/route-constants";

interface ILayerProps {
  bpId: string;
  data: any;
  createRedLayer: (data: any) => void;
  deleteRedLayer: (data: any) => void;
  getRedLayer: (data: any) => void;
  layer: IBPLAYER;
  createLayer: (data: any) => void;
  onLayerDeleteClick: (layerId: string, layerType: string) => void;
  getLayer: (id: string) => void;
  deleteLayer: (data: string) => void;
  onBlockClick: (layerType: string) => void;
  isEditor: boolean;
  isBlocked: boolean;
}

const Layer: FC<ILayerProps> = ({
  isEditor,
  layer,
  data,
  bpId,
  onLayerDeleteClick,
  deleteLayer,
  createLayer,
  createRedLayer,
  deleteRedLayer,
  onBlockClick,
  isBlocked
}) => {

  const navigate = useNavigate();

  const onAddLayer = async () => {
    const layerData = { title: layer.title, type: layer.type, bpId };
    if (layer.type === 'red_layer') createRedLayer(layerData);
    else createLayer(layerData);
  };

  const goToLayer = async () => {
    const path = layer.type === 'red_layer' ? 'red-layer' : 'layer';
    navigate(getLayerUrl(bpId, data.id, path));
  }

  const renderTags = ({ id, card_id, card_title, tags }: any) => (
    <div className="w-fit" key={card_id || id} onClick={goToLayer}>
      <p className="text-1xl font-bold">{card_title}</p>
      <div className="flex flex-wrap my-2" onClick={goToLayer}>
        {tags?.length ? tags.map((tag: any) => <CardTag open={null} key={tag.id} data={tag} />) : null}
      </div>
    </div>
  );

  const renderRedLayerTags = (tag: any) => {
    return <RedLayerTag key={tag.id} title={tag.title} />;
  }

  const onDeleteLayerClick = () => {
    onLayerDeleteClick(data.id, layer.type);
  }

  const onBlockImageClick = () => {
    onBlockClick(layer.type);
  }

  const plusIconContent = (handler: any) => <div className="m-auto text-white">
    <img
      onClick={handler}
      className="m-auto cursor-pointer w-24"
      src={plusIcon}
    />
    <h1 className="text-3xl font-bold leading-loose">Tilføj</h1>
    <p className="text-2xl font-bold">{layer.title}</p>
  </div>;

  if (isBlocked) {
    return (
      <div className={`w-1/4 h-full rounded-xl flex items-center text-center ${layer.color}`}>
        <div className="m-auto text-white">
          <img
            onClick={onBlockImageClick}
            className="m-auto w-24 cursor-pointer"
            src={blockIcon}
          />
          <h1 className="text-3xl font-bold leading-loose">Anmod om adgang</h1>
          <p className="text-2xl font-bold">{layer.title}</p>
        </div>
      </div>
    );
  }

  if (data?.id) {
    const hasTags = data.tags?.length > 0;
    const isRedLayer = layer.type === "red_layer";
    const linkText: any = {
      blue_layer: "Økonomisk lag",
      yellow_layer: "Socialt lag",
      green_layer: "Miljømæssigt lag",
      red_layer: "Verdensmål",
    }
    return (
      <div className={` w-1/4 p-4 h-full relative text-white rounded-xl drop-shadow-md ${layer.color}`}>
        {layer.title && <p className="text-2xl font-bold mb-3">{data?.title}</p>}
        <div className="tags-wrap absolute top-14 left-4 right-3 bottom-10 overflow-auto">
          {(hasTags && !isRedLayer) && data.tags.map(renderTags)}
          {(hasTags && isRedLayer ) ? (<div key={'red-layer'} className="flex flex-wrap mb-2" onClick={goToLayer}>
            {data.tags?.length ? data.tags.map(renderRedLayerTags) : null}
          </div>) : null}

          {!hasTags && ( <div className="h-full flex items-center text-center">
            {plusIconContent(goToLayer)}
          </div> )}
        </div>

        <div className="text-1xl w-full font-bold flex justify-between items-center absolute bottom-2">
          {isEditor && <p className="cursor-pointer" onClick={onDeleteLayerClick}>Fjern lag</p>}
          <div onClick={goToLayer} className="mr-6 flex items-center cursor-pointer">
            <p>{linkText[data.type]}</p>
            <img className="ml-1 mt-1 w-6 h-6" src={arrowIcon} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`w-1/4 h-full rounded-xl flex items-center text-center ${layer.color}`}>
      {plusIconContent(onAddLayer)}
    </div>
  );
};

const mapState = (state: RootState) => ({
  businessPlans: state.businessPlan,
  isEditor: state.user.isEditor,
});

const mapDispatch = (dispatch: Dispatch) => ({
  getTags: dispatch.layer.getTags,
  createLayer: dispatch.layer.createLayer,
  deleteLayer: dispatch.layer.deleteLayer,
  setLayer: dispatch.layer.setLayer,
  getLayer: dispatch.layer.getLayer,
  createRedLayer: dispatch.redLayer.createRedLayer,
  deleteRedLayer: dispatch.redLayer.deleteRedLayer,
  getRedLayer: dispatch.redLayer.getRedLayer
});

export default connect(mapState, mapDispatch)(Layer);
