import React, { FC, useEffect, useState } from "react";
import classnames from "classnames";

import { ActivityModal } from "../../components/core/modals/color-picker-variant-one/ActivityModal";
import { ACTIVITY_MODAL_DATA, TAG_MODAL_DATA } from "../../store/constants/layer-constants";
import { LayerActivity } from "../../components/Layer/activity";
import smallPlusIcon from "../../assets/icons/small-plus.png";
import { connect } from "react-redux";
import { Dispatch, RootState } from "../../store/store";
import LayerCard from "../../components/Layer/card";
import { LayerTagModal } from "../../components/core/modals/select-variant-one/LayerTagModal";
import { getModalColorByType, LAYER_GRID_VALUES } from "../../helpers/bpLayers";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import { LAYER_DESCRIPTION } from "../../store/constants/layer-constants";
import { NotFound404 } from "../../components/404";
import {
  ACTIVITY_DELETE_CONFIRMATION_DATA,
  LAYER_TAG_DELETE_CONFIRMATION_DATA
} from "../../store/constants/bp-constants";
import {ConfirmationModal} from "../../components/core/modals/confirmation-modal";

const LayerPage: FC<any> = ({
  userId,
  isEditor,
  saveActivity,
  deleteActivity,
  deletedActivityId,
  layer,
  getLayer,
  createTag,
  deleteTag,
  selectedActivities,
  setActiveActivity,
  removeActiveActivity,
  saveDeletedLayerTag,
  deleteLayerTag,
  saveDeletedActivityId
}) => {
  const { layer_id } = useParams();
  const [showFullDescription, setShowFullDescription] = useState<any>(false);
  const [activityModal, setActivityModal] = useState<any>({ visible: false });
  const [tagModal, setTagModal] = useState<any>({ visible: false });
  const [show404, setShow404] = useState(false);
  const layerColor = getModalColorByType(layer.type);
  const [deleteModal, setDeleteModal] = useState({ visible: false });
  const [showDeleteActivityModal, setShowDeleteActivityModal] = useState(false);

  useEffect(() => {
    getLayer(layer_id).then((res: boolean) => {
      setShow404(!res);
    });
  }, [userId]);

  const openActivityModal = () => { setActivityModal({ visible: true }); }
  const closeActivityModal = () => { setActivityModal({ visible: false }); }

  const openTagModal = (data: any) => {
    setTagModal({ ...data, visible: true });
  }
  const closeTagModal = () => { setTagModal({ visible: false }); }
  const closeDeleteModal = () => { setDeleteModal({ visible: false }); }

  const onSubmitActivity = (e: any) => {
    e.preventDefault();
    const title = e.target[0]?.value;
    const checkboxColor = [...e.target.radio].find((i: any) => i.checked)?.value;
    const type = e.nativeEvent.submitter.id
    if (!title?.length) {
      toast.warning("Indtast overskriften!");
      return;
    }
    if (!checkboxColor?.length) {
      toast.warning("Vælg farve!");
      return;
    }
    if (type === "save") saveActivity({ id: activityModal?.id, title, color: checkboxColor, layer_id: layer.id });
    else if (type === "delete") {
      closeActivityModal();
      setShowDeleteActivityModal(true);
      saveDeletedActivityId(activityModal.id);
    }
    closeActivityModal();
  }

  const hideDeleteActivityModal = () => {
    setShowDeleteActivityModal(false);
  }

  const submitDeleteActivity = () => {
    deleteActivity(deletedActivityId);
    hideDeleteActivityModal();
  }

  const onSubmitTag = async (e: any) => {
    e.preventDefault();
    const type = e.nativeEvent.submitter.id;
    const data = {
      title: e.target[0]?.value,
      description: e.target[2].value,
      activities: tagModal.activities,
      card_id: tagModal.card_id,
    };
    if (!data.title?.length) {
      toast.warning("Indtast overskriften!");
      return;
    }
    if (data.card_id && data.title) {
      if (type === "save") await createTag({ ...data, id: tagModal?.id });
      else if (type === "delete") {
        saveDeletedLayerTag(tagModal);
        setDeleteModal({visible: true});
      }
    }
    closeTagModal();
  }

  const submitDeleteTag = () => {
    deleteTag(deleteLayerTag)
    setDeleteModal({visible: false})
  }

  const renderActivity = ({ title, color, id }: any) => {
    const openModal = () => {
      if (isEditor) {
        setActivityModal({ ...activityModal, title, color, id, visible: true });
      }
    }
    const onMouseEnter = () => setActiveActivity([id]);
    const onMouseLeave = () => removeActiveActivity([id]);
    const isSelected = selectedActivities.includes(id);
    return <LayerActivity onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} isSelected={isSelected} key={id} onClick={openModal} title={title} color={color} />
  }

  const renderCard = (card: any, idx: number) => {
    const { style } = LAYER_GRID_VALUES[idx];
    return <LayerCard onAdd={openTagModal} data={card} key={card.id} style={style} />
  }
  const renderCards = () => {
    return <>
      <div className="h-full pb-4 grid overflow-hidden grid-cols-10 grid-rows-3 gap-5 text-white">
        {layer?.cards?.map(renderCard)}
      </div>
    </>
  }

  const prepareOptionItems = () => {
    return layer?.activities?.map((a: any) => ({
      value: a.id,
      label: a.title,
      color: a.color
    })) || [];
  }
  const getSelectedOption = () => {
    return tagModal?.activities?.map((a: any) => ({
      value: a.id,
      label: a.title,
      color: a.color
    })) || [];
  }
  const onselectChange = (e: any) => {
    const ids = e?.map((i: any) => i.value) || [];
    setTagModal({ ...tagModal, activities: ids });
  }

  const onHideDescription = (event: any) => {
    if (showFullDescription) event.target.previousElementSibling.scrollTo({top: 0})
    setShowFullDescription(!showFullDescription);
  }

  if (layer?.id) return (
    <>
      <div className="max-h-1/4">
        <div className="w-4/5 pt-8 m-auto text-center">
          <div
            className={classnames({
              "overflow-auto h-[auto] max-h-[80vh]": showFullDescription,
              "overflow-hidden h-[46px]": !showFullDescription,
            })}
            dangerouslySetInnerHTML={{ __html: LAYER_DESCRIPTION[layer.type] }}
          />
          <button onClick={onHideDescription}>{showFullDescription ? "Vis mindre" : "Læs mere"}</button>
        </div>
        <div className="mt-3 mb-4 text-3xl font-bold text-center">Dine kort</div>
        <div className="mb-8 flex justify-center">
          {layer?.activities?.map(renderActivity)}
          {isEditor && <LayerActivity onClick={openActivityModal} title="Tilføj kort" color="#d4d4d4" icon={<img className="ml-2" src={smallPlusIcon} />} />}
        </div>
      </div>
      <div className="px-5 h-full ">
        {renderCards()}
        {activityModal?.visible ? <ActivityModal
          setShowModal={closeActivityModal}
          showModal={activityModal.visible}
          title={ACTIVITY_MODAL_DATA[layer.type].title}
          introduction={ACTIVITY_MODAL_DATA[layer.type].introduction}
          inputLabel={ACTIVITY_MODAL_DATA[layer.type].inputLabel}
          colorPickerLabel={ACTIVITY_MODAL_DATA[layer.type].colorPickerLabel}
          SubmitButtonText={ACTIVITY_MODAL_DATA[layer.type].SubmitButtonText}
          CancelButtonText={ACTIVITY_MODAL_DATA[layer.type].CancelButtonText}
          FormCancelBtnDisabled={false}
          FormSubmitBtnDisabled={false}
          textareaLabel={""}
          onSubmit={onSubmitActivity}
          data={activityModal}
          bgColor={layerColor}
        /> : null}

        {tagModal?.visible ? <LayerTagModal
          setShowModal={closeTagModal}
          data={tagModal}
          onSubmit={onSubmitTag}
          onSelectChange={onselectChange}
          selectedoption={getSelectedOption()}
          showModal={tagModal?.visible}
          title={tagModal?.card_title}
          introduction={tagModal?.card_description}
          body={TAG_MODAL_DATA[layer.type].body}
          listtitle={TAG_MODAL_DATA[layer.type].listtitle}
          liitems={TAG_MODAL_DATA[layer.type].listitems}
          inputLabel={TAG_MODAL_DATA[layer.type].inputLabel}
          selectLabel={TAG_MODAL_DATA[layer.type].selectLabel}
          optionList={prepareOptionItems()}
          textareaLabel={TAG_MODAL_DATA[layer.type].textareaLabel}
          SubmitButtonText={TAG_MODAL_DATA[layer.type].SubmitButtonText}
          FormSubmitBtnDisabled={false}
          CancelButtonText={TAG_MODAL_DATA[layer.type].CancelButtonText}
          FormCancelBtnDisabled={false}
          bgColor={layerColor}
        /> : null}

        <ConfirmationModal
          setShowModal={closeDeleteModal}
          submitRed={submitDeleteTag}
          submitGreen={closeDeleteModal}
          showModal={deleteModal.visible}
          title={LAYER_TAG_DELETE_CONFIRMATION_DATA.title}
          body={LAYER_TAG_DELETE_CONFIRMATION_DATA.body}
          redBtnLabel={LAYER_TAG_DELETE_CONFIRMATION_DATA.redBtnLabel}
          greenBtnLabel={LAYER_TAG_DELETE_CONFIRMATION_DATA.greenBtnLabel}
        />

        <ConfirmationModal
          setShowModal={hideDeleteActivityModal}
          submitRed={submitDeleteActivity}
          submitGreen={hideDeleteActivityModal}
          showModal={showDeleteActivityModal}
          title={ACTIVITY_DELETE_CONFIRMATION_DATA.title}
          body={ACTIVITY_DELETE_CONFIRMATION_DATA.body}
          redBtnLabel={ACTIVITY_DELETE_CONFIRMATION_DATA.redBtnLabel}
          greenBtnLabel={ACTIVITY_DELETE_CONFIRMATION_DATA.greenBtnLabel}
        />
      </div>
    </>
  );
  if (show404) return <NotFound404 />;
  return <div />;
}

const mapState = (state: RootState) => ({
  layer: state.layer,
  deletedActivityId: state.layer.deletedActivityId,
  deleteLayerTag: state.layer?.deletedLayerTag,
  isEditor: state.user.isEditor,
  userId: state.user.data?.id,
  selectedActivities: state.layer?.selectedActivities || []
});

const mapDispatch = (dispatch: Dispatch) => ({
  saveActivity: dispatch.layer.saveActivity,
  saveDeletedActivityId: dispatch.layer.saveDeletedActivityId,
  deleteActivity: dispatch.layer.deleteActivity,
  getLayer: dispatch.layer.getLayer,
  createTag: dispatch.layer.createTag,
  deleteTag: dispatch.layer.deleteTag,
  saveDeletedLayerTag: dispatch.layer.saveDeletedLayerTag,
  setActiveActivity: dispatch.layer.setActiveActivity,
  removeActiveActivity: dispatch.layer.removeActiveActivity
});

export default connect(mapState, mapDispatch)(LayerPage);
