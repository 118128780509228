import React from "react";

export interface Props {
  isActive: boolean;
}

export const IconFiveTen: React.FC<Props> = ({ isActive = false }: Props) => {
  return (
    <>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 170.08 170.08" style={{ ['enableBackground' as any]: 'new 0 0 170.08 170.08', 'filter': 'none' }} xmlSpace="preserve">
        <style type="text/css">{`
          svg:hover #SVGID_1_15{fill:#5aba47;}
          .st1{clip-path:url(#SVGID_00000069365680215032327190000012398756419810540479_);fill:#FFFFFF;}
          .st2{fill:#FFFFFF;}
        `}</style>
        <rect className="st0" width="170.08" height="170.08" />
        <g>
          <defs>
            <rect id="SVGID_1_15" y="0" width="170.07" height="170.07" />
          </defs>
          <use xlinkHref="#SVGID_1_15" style={{ 'overflow': 'visible', 'fill': isActive ? '#5aba47' : '#B2B2B2' }} />
          <clipPath id="SVGID_00000069365680215032327190000012398756419810540479_">
            <use xlinkHref="#SVGID_1_15" style={{ 'overflow': 'visible' }} />
          </clipPath>
          <path className="st1" d="M36.07,25.11
		c-2.27,0-3.94,0.81-4.86,2.38h-0.05v-8.55h9.92v-4.7H25.91v19.32h5.26V32.8c0-2.02,0.91-3.13,2.53-3.13c1.77,0,2.48,1.21,2.48,3.09
		v8.29c0,1.82-0.66,3.08-2.43,3.08c-1.97,0-2.68-1.31-2.68-3.34v-4.3h-5.26v3.9c0,5.21,2.07,8.55,7.94,8.55
		c5.82,0,8.19-3.09,8.19-7.84v-8.4C41.94,27.29,39.16,25.11,36.07,25.11"/>
          <path className="st1" d="M70.43,114.99
		c0.21,0,0.37,0.17,0.37,0.38v12.38c0,0.21-0.17,0.38-0.37,0.38h-5.01c-0.21,0-0.38-0.17-0.38-0.38v-12.38
		c0-0.21,0.17-0.38,0.38-0.38H70.43z"/>
          <path className="st1" d="M125.47,133.45H42.88
		c-0.21,0-0.38,0.18-0.38,0.39v5.05c0,0.21,0.17,0.37,0.38,0.37h82.59c0.2,0,0.38-0.17,0.38-0.37v-5.05
		C125.85,133.63,125.67,133.45,125.47,133.45"/>
          <path className="st1" d="M125.47,143.48H42.88
		c-0.21,0-0.38,0.17-0.38,0.37v5.06c0,0.2,0.17,0.37,0.38,0.37h82.59c0.2,0,0.38-0.17,0.38-0.37v-5.06
		C125.85,143.65,125.67,143.48,125.47,143.48"/>
          <path className="st1" d="M109.75,101.29
		c-1.03-0.81-3.79-2.69-7.14-2.69c-2.4,0-4.63,0.95-6.63,2.82c-0.14,0.13-0.22,0.32-0.22,0.52v0.45c0,0.22,0.07,0.43,0.23,0.56
		c0.18,0.14,0.37,0.19,0.58,0.14c0.05-0.01,0.11-0.02,0.15-0.04c0.15-0.05,0.3-0.14,0.41-0.26c0.43-0.29,1.62-0.69,3.31-0.69
		c1.75,0,5.19,0.47,8.51,3.57l0.08,0.07c0.19,0.19,0.44,0.3,0.72,0.3c0.26,0,0.53-0.11,0.7-0.29l0.07-0.07
		c3.34-3.1,6.77-3.57,8.52-3.57c1.7,0,2.88,0.4,3.32,0.69c0.12,0.12,0.26,0.21,0.46,0.28c0.07,0.02,0.28,0.07,0.48,0.02
		c0.31-0.09,0.52-0.36,0.52-0.68v-0.4c0-0.19-0.08-0.38-0.22-0.51c-2.02-1.92-4.27-2.89-6.71-2.89
		C113.55,98.59,110.81,100.45,109.75,101.29"/>
          <path className="st1" d="M104.63,68.37
		c-0.18,0.08-0.3,0.24-0.36,0.43l-0.08,0.28c-0.06,0.22-0.02,0.45,0.11,0.62c0.13,0.17,0.31,0.27,0.51,0.28c0.04,0,0.07,0.01,0.1,0
		c0.14,0,0.27-0.04,0.4-0.1c0.07-0.01,0.23-0.06,0.5-0.06c0.37,0,3.7,0.11,5.9,3.85l0.03,0.05c0.12,0.22,0.31,0.37,0.53,0.42
		c0.06,0.02,0.19,0.04,0.26,0.04c0.15,0,0.31-0.04,0.42-0.1l0.05-0.03c1.21-0.6,2.45-0.91,3.69-0.91c2.09,0,3.45,0.85,3.77,1.21
		c0.07,0.12,0.16,0.23,0.28,0.31c0.07,0.04,0.19,0.1,0.27,0.13c0.04,0.01,0.08,0.01,0.12,0.02c0.02,0,0.05,0,0.07,0
		c0.11,0,0.22-0.03,0.33-0.08c0.17-0.09,0.3-0.24,0.35-0.42l0.07-0.24c0.02-0.06,0.03-0.13,0.03-0.2c0-0.13-0.03-0.25-0.1-0.35
		c-1.6-2.79-3.93-3.37-5.6-3.37c-1.24,0-2.33,0.32-2.97,0.56c-0.76-1.02-2.67-3.12-5.57-3.12C106.7,67.59,105.66,67.85,104.63,68.37
		"/>
          <path className="st1" d="M87.18,81.08l0.02,0.37
		c0.01,0.23,0.13,0.43,0.33,0.56c0.16,0.1,0.37,0.14,0.56,0.08c0.04-0.01,0.09-0.02,0.12-0.04c0.14-0.06,0.26-0.15,0.36-0.26
		c0.35-0.25,1.48-0.71,3.15-0.71c1.6,0,4.04,0.43,6.53,2.47l0.06,0.06c0.18,0.15,0.41,0.24,0.64,0.24H99
		c0.26-0.02,0.5-0.14,0.65-0.31l0.06-0.06c2.75-2.91,5.72-3.35,7.24-3.35c1.08,0,1.85,0.22,2.15,0.39c0.11,0.1,0.25,0.18,0.43,0.22
		c0.05,0.01,0.17,0.03,0.3,0.02c0.05-0.01,0.12-0.03,0.17-0.04c0.29-0.1,0.49-0.39,0.47-0.71l-0.02-0.32
		c-0.01-0.19-0.1-0.37-0.25-0.49c-1.64-1.38-3.42-2.08-5.28-2.08c-2.91,0-5.22,1.72-6.18,2.56c-0.88-0.6-3.01-1.83-5.51-1.83
		c-2.2,0-4.17,0.91-5.86,2.71C87.24,80.69,87.17,80.88,87.18,81.08"/>
          <path className="st1" d="M45.38,87.71
		c0-4.95,4.01-8.95,8.95-8.95c0.19,0,0.38,0.02,0.57,0.03c0.47-6.26,5.69-11.2,12.07-11.2c6.69,0,12.12,5.42,12.12,12.11
		c0,1.9-0.45,3.68-1.23,5.28c6.06,1.34,10.6,6.74,10.6,13.21c0,7.48-6.06,13.54-13.54,13.54c-0.55,0-18.07-0.11-18.07-0.11
		c-5.3,0-9.59-4.29-9.59-9.59c0-2.54,1-4.85,2.61-6.56C47.2,93.93,45.38,91.03,45.38,87.71"/>
        </g>
        <polygon className="st2" points="54.33,28.15 59.6,28.15 59.6,26.19 56.73,26.19 56.73,13.86 54.33,13.86 " />
        <rect x="60.7" y="13.86" className="st2" width="2.4" height="14.3" />
        <polygon className="st2" points="69.93,13.86 68.25,23.83 68.23,23.83 66.58,13.86 64.13,13.86 66.86,28.15 69.47,28.15 72.19,13.86 " />
        <polygon className="st2" points="73.18,28.15 78.92,28.15 78.92,26.19 75.59,26.19 75.59,21.85 77.97,21.85 77.97,19.91 75.59,19.91
	75.59,15.82 78.92,15.82 78.92,13.86 73.18,13.86 "/>
        <polygon className="st2" points="79.93,15.82 81.91,15.82 81.91,28.15 84.34,28.15 84.34,15.82 86.32,15.82 86.32,13.86 79.93,13.86 " />
        <g>
          <path className="st2" d="M57.62,36.23c0.82,0,1.18,0.38,1.18,1.16v3.29c0,0.78-0.36,1.18-1.18,1.18h-0.89v-5.63H57.62z M54.33,48.57
		h2.4v-4.85h1.16c2.43,0,3.31-1.22,3.31-3.23v-3.02c0-2-0.89-3.21-3.31-3.21h-3.56V48.57z"/>
          <path className="st2" d="M64.62,32.41c0-0.4,0.25-0.7,0.65-0.7s0.65,0.3,0.65,0.7c0,0.4-0.25,0.7-0.65,0.7S64.62,32.82,64.62,32.41
		 M65.19,38.3h0.02l0.95,5.74h-1.92L65.19,38.3z M63.65,32.41c0,0.87,0.67,1.58,1.62,1.58c0.95,0,1.6-0.72,1.6-1.58
		c0-0.86-0.65-1.58-1.6-1.58C64.32,30.83,63.65,31.55,63.65,32.41 M63.5,48.57l0.42-2.61h2.55l0.44,2.61h2.47l-2.72-14.3h-2.74
		l-2.7,14.3H63.5z"/>
          <polygon className="st2" points="73.86,48.57 79.13,48.57 79.13,46.61 76.26,46.61 76.26,34.27 73.86,34.27 	" />
          <path className="st2" d="M83.81,38.3h0.02l0.95,5.74h-1.92L83.81,38.3z M82.12,48.57l0.42-2.61h2.55l0.44,2.61h2.47l-2.72-14.3h-2.74
		l-2.7,14.3H82.12z"/>
          <polygon className="st2" points="93.85,48.57 95.96,48.57 95.96,34.27 93.96,34.27 93.96,42.47 91.36,34.27 89,34.27 89,48.57
		91.02,48.57 91.02,39.59 	"/>
          <path className="st2" d="M101.46,34.27h-3.71v14.3h3.71c2.57,0,3.48-1.64,3.48-3.84v-6.62C104.94,35.89,104.04,34.27,101.46,34.27
		 M100.16,46.61V36.23h1.01c0.99,0,1.37,0.59,1.37,1.43v7.49c0,0.86-0.38,1.45-1.37,1.45H100.16z"/>
        </g>
        <polygon className="st2" points="12.45,20.46 12.45,26.48 12.5,26.48 16.55,22.28 16.6,22.28 16.6,48.53 22.37,48.53 22.37,14.24
	17.76,14.24 "/>
      </svg>
    </>
  );
};
