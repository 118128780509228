export const BLUE_LAYER_ACTIVITY_MODAL = {
  title: "Tilføj kort",
  introduction: `
    <p class='mb-6'>Her kan du opdele dit værditilbud i flere lag altså lave eks. varegrupper eller aktiviteter ved at opdele dem i forskellige ”kort”. Disse kort kan du så give forskellige farver. Disse kan så allokere til de byggesten som er relevante for netop disse varegrupper eller aktiviteter. Farverne vil så fremgå i hver enkelt byggesten, hvor de er indplaceret.</p>
    <p class='mb-6'>Dette vil give et bedre overblik over, hvor ressourcerne bliver brugt og hvor kan der optimeres.</p>
  `,
  inputLabel: "Overskrift",
  colorPickerLabel: "Vælg farve til kort",
  SubmitButtonText: "Gem",
  CancelButtonText: "Slet"
}

export const YELLOW_LAYER_ACTIVITY_MODAL = {
  title: "Tilføj kort",
  introduction: `
    <p class='mb-6'>Hvis det giver mening, kan du opdele den sociale værdi i flere lag altså lave eks. forskellige afdelinger i virksomheden, ved at opdele dem i forskellige ”kort”. Disse kort kan du så give forskellige farver. Disse kan så allokeres til de byggesten som er relevante for netop disse afdelinger. Farverne vil så fremgå i hver enkelt byggesten, hvor de er indplaceret.</p>
  `,
  inputLabel: "Overskrift",
  colorPickerLabel: "Vælg farve til kort",
  SubmitButtonText: "Gem",
  CancelButtonText: "Slet"
}

export const GREEN_LAYER_ACTIVITY_MODAL = {
  title: "Tilføj kort",
  introduction: `
    <p class='mb-6'>Her kan du opdele den funktionelle enhed i undergrupper. Således kan man behandle flere enheder i samme model. Giver det mening at gruppere dem og tale om homogene varegrupper? Ved at opdele dem i forskellige ”kort” med forskellige farver. Disse kan så allokeres til de byggesten som er relevante for netop disse enheder. Farverne vil så fremgå i hver enkelt byggesten, hvor de er indplaceret.</p>
  `,
  inputLabel: "Overskrift",
  colorPickerLabel: "Vælg farve til kort",
  SubmitButtonText: "Gem",
  CancelButtonText: "Slet"
}

export const LAYER_TAG_MODAL = {
  title: "Kanaler",
  introduction: `
    <p class='mb-6'>Kanaler bekriver hvordan du når dit kundesegment, kommunikerer med dem og leverer dit værdiudsagn. Når vi kender vores kundesegment og værdiudsagn, kan vi undersøge hvordan vi når kunden gennem marketing, salg, kundeservice – altså hele kundeoplevelsen.</p>
    <p class='mb-6'>Samtidig bør du tænke grundigt over, hvilke kanaler kunden foretrækker at benytte, hvilke du bruger i øjeblikket, hvilke der virker bedst og hvilke der giver økonomisk mening. Kanaler kan være både fysiske og digitale, hvor du kan få et godt overblik gennem PESO modellen.</p>
    <p class='mb-6'><strong>Følgende spørgsmål kan hjælpe dit arbejde:</strong></p>
    <p class='mb-6'>
      <ol class='list-decimal'>
        <li>Jeg vil nå mine kunder gennem (kanaler, medier, SoMe)…</li>
        <li>jeg vil optimere mine kunders oplevelse ved at…</li>
        <li>Mine hoved salgstale er…</li>
        <li>Jeg vil anvende følgende marketing mix…</li>
      </ol>
    </p>
  `,
  inputLabel: "Overskrift",
  colorPickerLabel: "Vælg farve til kort",
  SubmitButtonText: "Gem",
  CancelButtonText: "Slet",
  selectLabel: "Aktivitet",
  textareaLabel: "Beskrivelse"
}

// need add other modal datas;

export const ACTIVITY_MODAL_DATA: any = {
  blue_layer: BLUE_LAYER_ACTIVITY_MODAL,
  yellow_layer: YELLOW_LAYER_ACTIVITY_MODAL,
  green_layer: GREEN_LAYER_ACTIVITY_MODAL
}

export const TAG_MODAL_DATA: any = {
  blue_layer: LAYER_TAG_MODAL,
  yellow_layer: LAYER_TAG_MODAL,
  green_layer: LAYER_TAG_MODAL
}


export const RED_LAYER_DESCRIPTION: any = `
  <p class="mb-6">Verdensmålene udgør 17 konkrete mål og 169 delmål, som forpligter alle FN’s 193 medlemslande til helt at afskaffe fattigdom og sult i verden, reducere uligheder, sikre god uddannelse og bedre sundhed til alle, anstændige jobs og mere bæredygtig økonomisk vækst.</p>
  <p class="mb-6">Verdensmålene gælder for alle lande, byer, virksomheder og individer  – både rige og fattige – med højde for landenes forskellige udgangspunkt. De store udfordringer, vi står overfor i dag, som bl.a. social, økonomisk og politisk marginalisering, stigende ungdomsarbejdsløshed, ulighed, fattigdom, fødevareusikkerhed, mangel på, eller ulige adgang til grundlæggende naturressourcer, miljøforurening, klimaforandringer og ikke-lydhøre regeringer. De er alle nationale udfordringer med store regionale og globale konsekvenser, og er derfor nødvendige at løse i fællesskab.</p>
  <p class="mb-6">Den nye dagsorden anerkender således, at social, økonomisk og miljømæssig udvikling, fred, sikkerhed og internationalt samarbejde er tæt forbundne, og at det kræver en integreret indsats at opnå holdbare udviklingsresultater.</p>
  <p class="mb-6">Derfor er det vigtigt at vi alle hjælper til og gør en indsats.</p>
  <p class="mb-6">Modellen her giver en introduktion til FN17 Verdensmålene hver for sig, der kan så udvælges hvor man kan gøre en forskel som virksomhed. Under hvert af de udvalgte Verdensmål er der en række delmål, som hver især kan understøtte Verdensmålene. Til hvert af delmålene kan man indikere hvilke tiltage man gør som virksomhed og dermed støtte i kampen mod de store verdensudfordringer.</p>
`;

export const LAYER_DESCRIPTION: any = {
  blue_layer: `
    <p class="mb-6">Med en forretningsplan skabes en beskrivelse af grundlaget for virksomheden og dermed sikre, at der er tænkt over alle forhold omkring en virksomhedsstart eller eksistens. En forretningsplan kan give en idé om, hvordan virksomheden skal drives samt hvilke muligheder der er ift. udviklingen af virksomheden.</p>
    <p class="mb-6">Denne model indeholder en ekstern og en intern side. Den eksterne del, omhandler kunderne og markedet, den indeholder Værditilbud, Kundegrupper, Kunderelation, Kanaler og Indtægter – Grundlæggende hvilken værdi skaber du og for hvem, hvordan levere du og hvordan skaber du værdi for din virksomhed? Den interne del, med alt der kræves af organisationen, og indeholder Aktiviteter, Ressourcer, Partnere og Omkostninger – Her ser vi på, hvordan vi skaber værdi og hvad vi har brug for, for at skabe værdien. </p>
    <p class="mb-6">Modellen bygger på de 9 elementer beskrevet ovenfor, og inspirationsspørgsmål til hvert af de 9 elementer, som kan hjælpe med at beskrive elementet, tilsammen udgør de forretningsmodellen.</p>

    <p class="mb-6">KUNDEGRUPPER</p>
    <p class="mb-6">Kundegrupper er personer eller organisationer, som du skaber værdi for gennem dit værditilbud. Kundegruppen er en afgrænsning af, hvem køber dit produkt eller service, ved at se på deres fællestræk. Hvilke behov har din målgruppe, og hvad kendetegner de personer/organisationer der får mest ud af at købe dit produkt/service.</p>
    <p class="mb-6">Når du skal definere hvem dine kunder er, kan det hjælpe at se på hvilke opgaver de skal udføre, frem for demografi som køn og alder. Det er nødvendigt at se på hvilke problem de forsøger at få løst, og hvilket behov der bliver opfyldt.</p>
    <p class="mb-6">Du kan sagtens have flere kundegrupper. I denne situation bør du lave et kort for hver, med dertilhørende værditilbud – de får hver sin farve på din forretningsmodel.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på KUNDEGRUPPER:</p>
    <ul class="mb-6">
      <li>1. Hvilke kundegrupper vil jeg sælge til? </li>
      <li>2. Hvilke fælletræk har de enkelte grupper?</li>
      <li>3. Hvilket potentiale har hver gruppe?</li>
      <li>4. Hvorfor køber de?</li>
      <li>5. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">VÆRDITILBUD</p>
    <p class="mb-6">Værditilbuddet fortæller hvordan dit produkt eller service giver værdi for dine kundegrupper. Værditilbuddet er dog ikke selve produktet/servicen, men det behov du løser for køberen ved at hjælpe dem med at udføre deres opgaver.</p>
    <p class="mb-6">Dit værditilbud svarer på hvilken værdi du leverer til kundegruppen, hvilke opgaver dit produkt/service løser og hvilke behov det tilfredsstiller. Samtidig er dit værditilbud grunden til at kundegruppen skal vælge dig, frem for dine konkurrenter. Værditilbuddet kan f.eks. fokusere på innovation, funktion, tilpasning, design, status, pris, besparelse, tilgængelighed.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på VÆRDITILBUD:</p>
    <ul class="mb-6">
      <li>1. Hvilke fordele har produktet? Eks. Pris, design, kvalitet etc. </li>
      <li>2. Hvad er værdien og udbyttet for kunden?</li>
      <li>3. Hvilke konkurrenter findes der? Og hvad er deres værditilbud?</li>
      <li>4. Hvad adskiller produktet fra konkurrenterne?</li>
      <li>5. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">KANALER</p>
    <p class="mb-6">Kanaler bekriver hvordan du når din kundegruppe, kommunikerer med dem og leverer dit værditilbud. Når vi kender vores kundegrupper og værditilbud, kan vi undersøge hvordan vi når kunden gennem marketing, salg, kundeservice – altså hele kundeoplevelsen.</p>
    <p class="mb-6">Samtidig bør du tænke grundigt over, hvilke kanaler kunden foretrækker at benytte, hvilke du bruger i øjeblikket, hvilke der virker bedst og hvilke der giver økonomisk mening. Kanaler kan være både fysiske og digitale.</p>

    <p class="mb-6">Inspirationsspørgsmål til rapporten på KANALER:</p>
    <ul class="mb-6">
      <li>1. Hvordan nås kunderne (kanaler, medier, SoMe)</li>
      <li>2. Kunders oplevelse optimeres ved at?</li>
      <li>3. Hoved salgstalen er</li>
      <li>4. Følgende marketing mix anvendes</li>
      <li>5. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">Rapporten kan se sådan ud:</p>
    <p class="mb-6">overskrift: salgs- og marketingskanaler</p>
    <p class="mb-6">De primære kanaler for mig er, direkte reklame via direct mail. Jeg vil desuden gennem mit netværk på LinkedIn og Facebook lave kampagner hvor jeg tilbyder gratis prøver tilsendt, således at man kan se at mine produkter rent faktisk lever op til de ting jeg siger de kan. </p>
    <p class="mb-6">Jeg vil fortælle den gode historie om produktets grønne profil, det er godt for miljøet og samtidig er det uhyre effektivt. Modsat de lignende produkter på markedet er mine produkter bæredygtige. </p>
    <p class="mb-6">Jeg vil kommunikere gennem SoMe LinkedIn og Facebook, jeg vil være aktiv med Direct mails til potentielle kunder, og vil ringe dem op efter en uge. 10 potentielle kundeemner findes hver 14. dag.</p>

    <p class="mb-6">KUNDERELATION</p>
    <p class="mb-6">Kunderelationen beskriver hvordan du opbygger, beholder og udvikler forholdet til kundegrupperne – og hvilket forhold de forventer. Det er ikke altid nødvendigt, eller økonomisk attraktivt, at have tætte personlige kunderelationer, og det kommer ofte meget an på din branche, og hvilken standard kundegrupperne er vant til.</p>
    <p class="mb-6">Der er stor forskel på om relationen er et enkelt køb, abonnement, personlig assistance, selvbetjening eller automatiseret. Dette er også et område, hvor du kan differentiere dig over for konkurrenterne i begge retninger – f.eks. loyalitet gennem kundekort i supermarkedet eller automatiseret check-in på hotellet.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på KUNDERELATION:</p>
    <ul class="mb-6">
      <li>1. hvilken dialog/forhold ønskes med kunderne?</li>
      <li>2. hvilken information udveksles med kunderne?</li>
      <li>3. tiltænkes ekstrasalg, serviceinformation eller nyheder kommunikeret til kunderne?</li>
      <li>4. andre tiltag for at binde kunderne til virksomheden? </li>
      <li>5. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">INDTÆGTER</p>
    <p class="mb-6">Indtægterne viser hvordan du tjener penge fra kundegrupperne. Dit produkt eller service skaber værdi ved at dække kundens behov, men hvordan tjener du penge og på hvilken måde. En ting er prisen på dit produkt eller service, en anden er betalingsmodellen. Der findes mange betalingsmodeller, f.eks. kontantkøb, abonnement, leasing, licenser, betal-per-brug eller annoncering.</p>
    <p class="mb-6">Betalingsmodeller er ofte en måde at adskille dig fra konkurrenterne, f.eks. Spotify’s månedlige abonnement kontra køb af individuelle sange/albums. Spotify har desuden forskellige betalingsmodeller, da de både tjener penge på abonnementet og annoncering til brugere af den gratis version.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på INDTÆGTER:</p>
    <ul class="mb-6">
      <li>1. hvilken betalingsmodel er tænkt anvendt? </li>
      <li>2. hvor tjenes pengene i virksomheden? Er der gratisydelser/services? </li>
      <li>3. tiltænkes ekstrasalg? abonnent på service? eller andet?</li>
      <li>4. hvilken prissætning er der foretaget i forhold til kundegruppe og konkurrenter? </li>
      <li>5. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">AKTIVITETER</p>
    <p class="mb-6">Aktiviteterne er de ting du skal udføre for at skabe og levere værditilbuddet. Aktiviteterne er alle de ting din virksomhed skal gøre inden for udvikling og produktion af produktet, markedsføring af værditilbuddet, distribution og levering, kundeservice og modtage betaling for produktet/servicen.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på AKTIVITETER:</p>
    <ul class="mb-6">
      <li>1. Hvad er jeres nøgleaktivitet? </li>
      <li>2. Hvad skal i være gode til for at støtte op om jeres værditilbud?</li>
      <li>3. hvordan vil du levere din vare eller service til kunden?</li>
      <li>4. hvilke muligheder skal kunden have for at afregne for varen?</li>
      <li>5. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">RESSOURCER</p>
    <p class="mb-6">Ressourcerne er hvad du skal have eller erhverve for at udføre aktiviteterne. Ressourcerne kan være både materielle, menneskelige, finansielle eller intellektuelle. Ligesom aktiviteter er ressourcer alt hvad der kræves for at skabe og levere værditilbuddet.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på RESSOURCER:</p>
    <ul class="mb-6">
      <li>1. hvilke investeringer skal gøres for at lave dit værditilbud</li>
      <li>2. hvem skal hjælpe dig med at producere værditilbuddet? </li>
      <li>3. hvad skal der til af penge?</li>
      <li>4. skal du beskytte dine rettigheder eller produkter? </li>
      <li>5. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">PARTNERE</p>
    <p class="mb-6">Partnere er de leverandører og samarbejdspartnere der kræves for at forretningsmodellen virker. Du kan aldrig føre dit forretningsmodel ud i livet alene, og har ofte brug for både netværk, platforme og outsourcing. Det er ikke altid fornuftigt at eje alle ressourcerne selv, og i mange brancher kan det være en god idé at benytte partnere til at hjælpe med både aktiviteter og ressourcer.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på PARTNERE:</p>
    <ul class="mb-6">
      <li>1. hvilke samarbejdspartnere skal du have for at kunne levere dit værditilbud?</li>
      <li>2. hvor finder du samarbejdspartnerne? </li>
      <li>3. hvad skal købes af hjælp?</li>
      <li>4. hvilke netværk skal aktiveres? </li>
      <li>5. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">OMKOSTNINGER</p>
    <p class="mb-6">Omkostningerne beskriver alle udgifterne ved at skabe og levere værditilbuddet i forretningsmodellen. Generelt kan omkostningerne i forretningsmodellen være enten prisfokuseret eller værdifokuseret. Prisfokusererede forretningsmodeller gør alt for at minimere omkostningerne så meget som muligt, f.eks. lavpris flyselskaber. Værdifokuserede forretningsmodeller fokuser derimod på at maksimere værdien, f.eks. luksusbrands som Rolex.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på OMKOSTNINGER:</p>
    <ul class="mb-6">
      <li>1. hvilke omkostninger er der forbundet med at kunne levere værditilbuddet?</li>
      <li>2. hvad skal der til for at produktet eller servicen bliver helt optimal?</li>
      <li>3. Har du den rigtige fordeling mellem faste og variable omkostninger?</li>
      <li>4. Hvordan er din omkostningsstruktur i forhold til dine konkurrente?</li>
      <li>5. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>
  `,
  yellow_layer: `
    <p class="mb-6">Det sociale lag tager udgangspunkt i mennesker, gennem f.eks. socialt entreprenørskab, lokalmiljøet og virksomhedens CSR. De ni elementer i det sociale lag tager udgangspunkt i de forskellige “interessenter”; personer, grupper og organisationer, der har en interesse i virksomheden.</p>
    <p class="mb-6">Formålet med det sociale lag er kort sagt at skabe balance mellem de forskellige “interessenter” i og uden for virksomheden.</p>

    <p class="mb-6">SOCIAL VÆRDI</p>
    <p class="mb-6">Social værdi kan sammenlignes med virksomhedens vision, mission og formål, udover at tjene penge – altså hvad virksomheden gør for samfundet generelt.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på SOCIAL VÆRDI</p>
    <ul class="mb-6">
      <li>1. Hvilke ting gøres for det omliggende samfund? </li>
      <li>2. Hvad bidrager i med til Verden? </li>
      <li>3. Hvad er jeres eksistens berettigelse?</li>
      <li>4. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">MEDARBEJDERE</p>
    <p class="mb-6">Denne byggesten omhandler medarbejdernes rolle og vilkår i virksomheden, indenfor f.eks. løn, demografi (alder/køn), pension og uddannelse – og andre programmer internt i virksomheden, der sikrer medarbejderne et godt liv.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på MEDARBEJDERE</p>
    <ul class="mb-6">
      <li>1. beskriv personalepolitikken? (Sundhed og sikkerhed)</li>
      <li>2. hvor kommer de fra?</li>
      <li>3. hvorledes motiveres ansatte til at blive og dygtiggøre sig</li>
      <li>4. hvad gør vi ud over løn for at knytte ansatte til virksomheden?</li>
      <li>5. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">ORGANISATION (Governance)</p>
    <p class="mb-6">Organisation omhandler strukturen og hierarkiet i virksomheden. Det kunne f.eks. være, hvordan den træffer beslutninger, og i hvor høj grad der er gennemsigtighed i virksomheden.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på ORGANISATION</p>
    <ul class="mb-6">
      <li>1. Hvorvidt foretages beslutningerne i grupper eller i samråd? </li>
      <li>2. Hvorledes informeres om de beslutninger der træffes?</li>
      <li>3. Hvorledes informerer man om de nye ting der sker i virksomheden? (Gennemsigtighed i virksomheden)</li>
      <li>4. Hvad er organisationsstrukturen?</li>
      <li>5. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">LOKALMILJØ</p>
    <p class="mb-6">Byggestenen handler om virksomheden forhold til det/de lokalmiljø den er en del af, eller virksomhedens leverandører er en del af. Hvis virksomheder har flere placeringer, i samme eller flere lande, gennemgås de separat.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på LOKALMILJØ</p>
    <ul class="mb-6">
      <li>1. Beskriv hvad der gøres for lokalmiljøet? </li>
      <li>2. Støtter i andre ting end lige i lokalmiljøet?</li>
      <li>3. sponsorere virksomheden private, organisationer eller foreninger?</li>
      <li>4. Hvordan supporter i jeres værdikæde og leverandør? </li>
      <li>5. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">RÆKKEVIDDE</p>
    <p class="mb-6">Rækkevidde er en vurdering af, hvor lang og omfattende virksomhedens påvirkning rækker. F.eks. i form af antallet af lande eller kulturer, og i hvilken grad virksomheden tilpasser til lokale forhold.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på RÆKKEVIDDE</p>
    <ul class="mb-6">
      <li>1. Beskriv de områder virksomheden opererer i?</li>
      <li>2. hvorledes har virksomheden tilpasset sig i lokalmiljøet?</li>
      <li>3. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">SLUTBRUGER</p>
    <p class="mb-6">Slutbrugeren er personen, der bruger produktet, og denne byggesten handler derfor om, hvordan virksomheden bidrager positivt til personens liv. Ligesom i den økonomiske del, handler det om at kende målgruppens behov.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på SLUTBRUGER</p>
    <ul class="mb-6">
      <li>1. beskriv hvordan slutbrugeren bruger produktet eller servicen</li>
      <li>2. hvilke fordele har slutbruger af at bruge produktet eller servicen?</li>
      <li>3. hvilken gevinst opnår slutbruger ved at bruge produktet eller servicen?</li>
      <li>4. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">KULTUR</p>
    <p class="mb-6">Denne byggesten erkender at virksomheden ikke har succes, hvis samfundet ikke er med, og handler derfor om hvordan virksomheden kan have en positiv påvirkning, f.eks. gennem non-profit organisationer og andre sociale agendaer.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på KULTUR</p>
    <ul class="mb-6">
      <li>1. støttes organisationer som er en del af samfundet som ikke handler af økonomisk grund?</li>
      <li>2. Hvilken kultur støtter eller taler jeres værditilbud ind i?</li>
      <li>3. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">SOCIALE INDVIRKNINGER (-)</p>
    <p class="mb-6">Sociale indvirkninger kan indeholde elementer, såsom medarbejdernes arbejdstid, helbred og sikkerhed – men det afhænger meget af virksomheden, hvilke elementer man bør fokusere på.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på SOCIALE INDVIRKNINGER</p>
    <ul class="mb-6">
      <li>1. Har i ansatte som i har taget ind pga. deres sårbarhed?</li>
      <li>2. Hvilken social indvirkning har i på jeres forbruger, værdikæde? </li>
      <li>3. Er der specielle forhold som gælder sikkerhed, sundhed eller velbefindende?</li>
      <li>4. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">SOCIALE FORDELE (+)</p>
    <p class="mb-6">Sociale fordele er de aktive tiltag virksomheden gør for et positivt socialt aftryk. Ligesom ovenstående er denne byggesten i høj grad påvirket af den enkelte virksomhed.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på SOCIALE FORDELE</p>
    <ul class="mb-6">
      <li>1. Beskriv aktiviteter i har ud over de andre sociale tiltag</li>
      <li>2. Hvilken  positiv indvirkning har i på bruger, forbruger og værdikæde?</li>
      <li>3. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>
  `,
  green_layer: `
    <p class="mb-6">Det miljømæssige lag handler om at udvikle en grøn forretningsmodel, der både tager hensyn til en bæredygtig produktion og brug af produktet/servicen, især sammenlignet med traditionelle forretningsmodeller. I dette lag er det ikke kun, hvad der foregår i selve virksomheden, der tages højde for men i ligeså høj grad hele kredsløbet før og efter virksomheden er inde i billedet.</p>
    <p class="mb-6">Formålet med det miljømæssige lag er kort sagt at skabe et “overskud”, hvor de miljømæssige fordele klart overstiger de miljømæssige indvirkninger, gennem et fokus på livscyklusvurderingen tilknyttet produkter eller servicen.</p>

    <p class="mb-6">FUNKTIONEL VÆRDIENHED</p>
    <p class="mb-6">For at beregne den miljømæssige påvirkning, og at sammenligne forretningsmodellen med andre, er vi først nødt til at vælge en enhed at tage udgangspunkt i. Det kunne f.eks. være et par bukser, en kop kaffe eller en online-shop, som danner udgangspunktet for beregning af de andre elementer i modellen.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på FUNKTIONEL VÆRDIENHED:</p>
    <ul class="mb-6">
      <li>1. Hvilken enhed er det vi taler om?</li>
      <li>2. Giver det mening at gruppere dem og tale om homogene varegrupper?</li>
      <li>- Hvilket?</li>
      <li>- Hvorfor?</li>
      <li>3. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">MATERIALER</p>
    <p class="mb-6">Materialer kan enten være de fysiske råvarer, der bruges i virksomhedens produkter, eller infrastrukturen og informationen, der er nødvendige for at levere virksomhedens services. Denne byggesten skal ikke indeholde samtlige materialer, men i stedet nøglematerialerne, som f.eks. bomuld eller kaffebønner.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på MATERIALER:</p>
    <ul class="mb-6">
      <li>1. Hvilke materialer bruges til fremstillingen? </li>
      <li>2. Hvilke er de mest essentielle og hvilke er de mest miljøbelastende?</li>
      <li>3. Kunne det overvejes at erstatte med mindre belastende materialer?</li>
      <li>4. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">PRODUKTION</p>
    <p class="mb-6">Produktion er alle de aktiviteter, der skal til for at omdanne råvaren til et fysiske produkt, eller infrastruktur og logistik i forbindelse med en service. Ligesom med materialerne, er det vigtigt at fokusere på de dele af virksomheden, der har den største miljøbelastning.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på PRODUKTION:</p>
    <ul class="mb-6">
      <li>1. Hvilke processer skal til for at producere vores værditilbud? </li>
      <li>2. Hvilke optimeringsmuligheder er der?</li>
      <li>3. Kunne det gøres anderledes?</li>
      <li>4. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">FORSYNINGER & OUTSOURCING</p>
    <p class="mb-6">Denne byggesten repræsenterer alle de andre materialer og produktionsaktiviteter, der ikke er en del af virksomhedens kerne. Det kan f.eks. være vand og elektricitet, som leveres af tredjeparter, og derfor kræver at virksomheden også undersøger partnernes miljøpåvirkning.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på FORSYNINGER & OUTSOURCING:</p>
    <ul class="mb-6">
      <li>1. Hvilke ting tilkøbes som ikke direkte indgår i vores produktionsproces?</li>
      <li>2. Hvilken miljøpåvirkning har disse produkter?</li>
      <li>3. Kan de erstattes af andet? og hvilke?</li>
      <li>4. Kan der optimeres på disse produkter?</li>
      <li>5. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">DISTRIBUTION</p>
    <p class="mb-6">Byggestenen Distribution handler om levering af produktet, og er en beregning af transportmetoden, distancen og enhedens vægt. Ligeledes er der lignende omkostninger ved transporten af personen i en servicevirksomhed.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på DISTRIBUTION:</p>
    <ul class="mb-6">
      <li>1. Hvilke metoder bruges til levering af vores værditilbud?</li>
      <li>2. Kan vi bruge alternativer? Kan vi planlægge anderledes?</li>
      <li>3. Kan der optimeres på nogen måde?</li>
      <li>4. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">BRUG</p>
    <p class="mb-6">I denne fase af produktets liv, bruges det af selve kunden, og skal f.eks. løbende oplades, vedligeholdes eller repareres, hvilket fører til miljømæssig påvirkning.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på BRUG:</p>
    <ul class="mb-6">
      <li>1. På hvilken måde påvirker værditilbuddet miljøet? </li>
      <li>2. Hvilke ressourcer bliver brugt under produktet/servicens brugsfase?</li>
      <li>3. Hvilke ændringer skal til for at minimere dette?</li>
      <li>4. kan vi optimere på servicen omkring produktet som belaster miljøet mindre?</li>
      <li>5. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">END-OF-LIFE</p>
    <p class="mb-6">End-of-Life handler om, hvad der sker med produktet, når kunden er færdig med at bruge det. I mange tilfælde er det et spørgsmål om genanvendelse eller genbrug, enten gennem virksomheden selv eller samfundstiltag.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på END-OF-LIFE:</p>
    <ul class="mb-6">
      <li>1. Hvorledes bortskaffes/opbevares værditilbuddet? </li>
      <li>2. Hvilke tiltag kan gøres for at minimere miljøbelastningen?</li>
      <li>3. Hvilke muligheder er der for genbrug/genanvendelse</li>
      <li>4. Hvad sker der med værditilbuddet når kunden er færdig med brugen?</li>
      <li>5. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">MILJØ INDVIRKNINGER (-)</p>
    <p class="mb-6">Denne byggesten forsøger at beregne den samlede miljø indvirkning, gennem f.eks. CO2, vandforbrug eller lignende. Byggestenen kan ligeledes omdeles i miljø indvirkning per fase; f.eks. produktion, brug og bortskaffelse.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på MILJØ INDVIRKNING:</p>
    <ul class="mb-6">
      <li>1. Hvilke muligheder er der for at anslå miljøindvirkningen for alle vores aktiviteter? </li>
      <li>2. Hvilke dele vil vi arbejde med?</li>
      <li>3. Hvilken proces skal der være omkring beregningen/estimeringen af miljøbelastningen?</li>
      <li>4. Kan der findes data til at understøtte forbruget – og hvad er målet fremadrettet og hvornår?</li>
      <li>5. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>

    <p class="mb-6">MILJØ FORDELE (+)</p>
    <p class="mb-6">Formålet med denne byggesten er at undersøge innovationsmuligheder for at nedbringe de negative miljø indvirkninger. Det er altså aktive tiltag i virksomheden, for at gøre noget positivt for miljøet.</p>
    <p class="mb-6">Inspirationsspørgsmål til rapporten på MILJØ FORDELE:</p>
    <ul class="mb-6">
      <li>1. Hvilke muligheder er der for at minimere miljøpåvirkningen? </li>
      <li>2. Er der andre teknologier/innovationer som kan være alternativer?</li>
      <li>3. Kan der gøres andet for at nedbringe miljøpåvirkningen?</li>
      <li>4. Hvad er status i dag på målepunktet og hvad er målet?</li>
    </ul>
  `,
};







