import React from "react";

export interface Props {
  isActive: boolean;
}

export const IconNine: React.FC<Props> = ({ isActive = false }: Props) => {
  return (
    <>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 170.08 170.08" style={{ ['enableBackground' as any]: 'new 0 0 170.08 170.08', 'filter': 'none' }} xmlSpace="preserve">
        <style type="text/css">{`
            .st9{fill:${isActive ? '#f36a2f' : '#B2B2B2'};}
          svg:hover #SVGID_1_9{fill:#f36a2f;}
          .st1{clip-path:url(#SVGID_00000131338286194204599270000000034193139086138534_);fill:#FFFFFF;}
          .st2{fill:#FFFFFF;}
        `}</style>
        <rect className="st9" width="170.08" height="170.08" />
        <g>
          <defs>
            <rect id="SVGID_1_9" x="0" y="0" width="170.07" height="170.08" />
          </defs>
          <use xlinkHref="#SVGID_1_9" style={{ 'overflow': 'visible', 'fill': isActive ? '#f36a2f' : '#B2B2B2' }} />
          <clipPath id="SVGID_00000131338286194204599270000000034193139086138534_">
            <use xlinkHref="#SVGID_1_9" style={{ 'overflow': 'visible' }} />
          </clipPath>
          <path className="st1" d="M31.15,22.88
		c0-5.31-2.33-9.15-8.39-9.15c-6.12,0-8.49,3.33-8.49,8.64v5.06c0,5.61,2.22,8.44,6.37,8.44c2.43,0,3.89-1.11,4.75-2.78h0.05v7.38
		c0,2.07-0.81,3.54-2.88,3.54c-2.07,0-2.83-1.21-2.83-3.34V38.4h-5.26v1.82c0,5.05,1.82,8.59,8.19,8.59c6.06,0,8.49-3.89,8.49-9.25
		V22.88z M25.44,28.14c-0.1,1.92-0.91,3.13-2.73,3.13c-1.97,0-2.73-1.21-2.73-3.44v-5.96c0-2.17,0.81-3.44,2.78-3.44
		c1.92,0,2.68,1.31,2.68,3.44V28.14z"/>
          <path className="st1" d="M128.49,111.79
		l-20.22-11.67V76.78c0-0.49-0.26-0.95-0.69-1.19l-20.9-12.07c-0.43-0.25-0.95-0.25-1.38,0l-20.9,12.07
		c-0.43,0.25-0.69,0.7-0.69,1.19v23.34l-20.22,11.67c-0.43,0.25-0.69,0.7-0.69,1.19v24.14c0,0.49,0.26,0.95,0.69,1.19l20.9,12.07
		c0.21,0.12,0.45,0.18,0.69,0.18c0.24,0,0.48-0.06,0.69-0.18l20.22-11.67l20.21,11.67c0.21,0.12,0.45,0.18,0.69,0.18
		c0.24,0,0.48-0.06,0.69-0.18l20.91-12.07c0.43-0.25,0.69-0.7,0.69-1.19v-24.14C129.18,112.49,128.91,112.04,128.49,111.79
		 M84.61,134.74l-16.77-9.68l16.77-9.68V134.74z M104.13,100.92l-16.76,9.68V91.24L104.13,100.92z M84.61,110.6l-16.77-9.68
		l16.77-9.68V110.6z M88.75,112.99l18.14-10.47l18.15,10.47l-18.15,10.48L88.75,112.99z M105.51,98.53l-16.76-9.68l16.76-9.68V98.53
		z M83.23,88.85l-16.77,9.68V79.17L83.23,88.85z M63.7,146.81l-18.15-10.47v-20.96l18.15,10.48V146.81z M66.46,146.81v-19.36
		l16.77,9.68L66.46,146.81z M126.42,136.33l-18.15,10.47v-20.95l18.15-10.48V136.33z"/>
        </g>
        <rect x="36.91" y="13.86" className="st2" width="2.4" height="14.3" />
        <polygon className="st2" points="45.98,28.16 43.16,19.17 43.16,28.16 41.13,28.16 41.13,13.86 43.49,13.86 46.09,22.06 46.09,13.86
	48.09,13.86 48.09,28.16 "/>
        <g>
          <path className="st2" d="M53.18,13.86h-3.71v14.3h3.71c2.57,0,3.48-1.64,3.48-3.84V17.7C56.66,15.48,55.75,13.86,53.18,13.86
		 M51.87,26.2V15.82h1.01c0.99,0,1.37,0.59,1.37,1.43v7.49c0,0.86-0.38,1.45-1.37,1.45H51.87z"/>
          <path className="st2" d="M62.79,13.86v11.05c0,0.84-0.34,1.41-1.18,1.41c-0.84,0-1.18-0.57-1.18-1.41V13.86h-2.4v10.82
		c0,2.21,1.05,3.65,3.58,3.65c2.45,0,3.4-1.43,3.4-3.65V13.86H62.79z"/>
          <path className="st2" d="M69.65,28.33c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.67-1.14-1.14-1.14-1.94v-0.3c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.49c0,1.64,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.02
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96h-2.24v1.92C66.25,26.96,67.2,28.33,69.65,28.33"/>
          <polygon className="st2" points="72.98,15.82 74.96,15.82 74.96,28.16 77.39,28.16 77.39,15.82 79.37,15.82 79.37,13.86 72.98,13.86
		"/>
          <path className="st2" d="M83.69,15.82c0.82,0,1.18,0.38,1.18,1.16v2.11c0,0.76-0.36,1.18-1.18,1.18h-0.8v-4.45H83.69z M85.15,28.16
		h2.53l-2.3-6.5c1.37-0.32,1.92-1.22,1.92-2.76v-1.83c0-2-0.89-3.21-3.31-3.21h-3.5v14.3h2.4V21.6L85.15,28.16z"/>
          <rect x="88.61" y="13.86" className="st2" width="2.4" height="14.3" />
          <polygon className="st2" points="94.52,25.86 92.25,25.86 91.95,29.28 93.3,29.28 94.52,26.05 	" />
          <rect x="96.05" y="13.86" className="st2" width="2.4" height="14.3" />
          <polygon className="st2" points="104.69,28.16 106.8,28.16 106.8,13.86 104.8,13.86 104.8,22.06 102.2,13.86 99.84,13.86 99.84,28.16
		101.87,28.16 101.87,19.17 	"/>
          <polygon className="st2" points="113.02,28.16 115.13,28.16 115.13,13.86 113.13,13.86 113.13,22.06 110.53,13.86 108.17,13.86
		108.17,28.16 110.2,28.16 110.2,19.17 	"/>
          <path className="st2" d="M120.13,28.33c2.55,0,3.65-1.64,3.65-3.86v-6.92c0-2.24-1.1-3.86-3.65-3.86c-2.53,0-3.63,1.62-3.63,3.86v6.92
		C116.5,26.68,117.6,28.33,120.13,28.33 M120.13,26.32c-0.84,0-1.22-0.61-1.22-1.48v-7.68c0-0.86,0.38-1.48,1.22-1.48
		c0.86,0,1.24,0.61,1.24,1.48v7.68C121.38,25.71,121,26.32,120.13,26.32"/>
          <polygon className="st2" points="130.17,13.86 128.48,23.83 128.46,23.83 126.82,13.86 124.37,13.86 127.09,28.16 129.71,28.16
		132.43,13.86 	"/>
          <path className="st2" d="M135.74,17.89h0.02l0.95,5.74h-1.92L135.74,17.89z M134.05,28.16l0.42-2.62h2.55l0.44,2.62h2.47l-2.72-14.3
		h-2.74l-2.7,14.3H134.05z"/>
          <polygon className="st2" points="139.59,15.82 141.58,15.82 141.58,28.16 144,28.16 144,15.82 145.98,15.82 145.98,13.86 139.59,13.86
			"/>
          <rect x="147.1" y="13.86" className="st2" width="2.4" height="14.3" />
          <path className="st2" d="M154.53,28.33c2.55,0,3.65-1.64,3.65-3.86v-6.92c0-2.24-1.1-3.86-3.65-3.86c-2.53,0-3.63,1.62-3.63,3.86v6.92
		C150.9,26.68,152,28.33,154.53,28.33 M154.53,26.32c-0.84,0-1.22-0.61-1.22-1.48v-7.68c0-0.86,0.38-1.48,1.22-1.48
		c0.86,0,1.24,0.61,1.24,1.48v7.68C155.77,25.71,155.39,26.32,154.53,26.32"/>
          <polygon className="st2" points="164.4,28.16 166.5,28.16 166.5,13.86 164.5,13.86 164.5,22.06 161.91,13.86 159.55,13.86
		159.55,28.16 161.57,28.16 161.57,19.17 	"/>
          <path className="st2" d="M40.54,48.74c2.55,0,3.65-1.64,3.65-3.86v-6.92c0-2.24-1.1-3.86-3.65-3.86c-2.53,0-3.63,1.62-3.63,3.86v6.92
		C36.92,47.09,38.01,48.74,40.54,48.74 M40.54,46.73c-0.84,0-1.22-0.61-1.22-1.48v-7.68c0-0.86,0.38-1.48,1.22-1.48
		c0.86,0,1.24,0.61,1.24,1.48v7.68C41.79,46.12,41.41,46.73,40.54,46.73"/>
          <path className="st2" d="M49.19,42.83h1.14v2.53c0,0.93-0.4,1.37-1.18,1.37c-0.82,0-1.18-0.61-1.18-1.48v-7.68
		c0-0.86,0.38-1.48,1.24-1.48c0.86,0,1.2,0.51,1.2,1.39v1.45h2.19v-1.27c0-2.11-0.78-3.59-3.44-3.59c-2.53,0-3.61,1.62-3.61,3.86
		v6.92c0,2.21,0.95,3.86,3.02,3.86c1.31,0,1.98-0.65,2.3-1.73v1.56h1.73v-7.63h-3.42V42.83z"/>
          <rect x="57.04" y="34.27" className="st2" width="2.4" height="14.3" />
          <polygon className="st2" points="66.11,48.57 68.21,48.57 68.21,34.27 66.21,34.27 66.21,42.47 63.62,34.27 61.25,34.27 61.25,48.57
		63.28,48.57 63.28,39.58 	"/>
          <polygon className="st2" points="69.59,48.57 71.99,48.57 71.99,42.26 74.39,42.26 74.39,40.32 71.99,40.32 71.99,36.23 75.22,36.23
		75.22,34.27 69.59,34.27 	"/>
          <path className="st2" d="M79.1,36.23c0.82,0,1.18,0.38,1.18,1.16v2.11c0,0.76-0.36,1.18-1.18,1.18h-0.8v-4.45H79.1z M80.55,48.57h2.53
		l-2.3-6.5c1.37-0.32,1.92-1.22,1.92-2.76v-1.83c0-2-0.89-3.21-3.31-3.21h-3.5v14.3h2.4v-6.56L80.55,48.57z"/>
          <path className="st2" d="M87.22,38.3h0.02l0.95,5.74h-1.92L87.22,38.3z M85.53,48.57l0.42-2.62h2.55l0.44,2.62h2.47l-2.72-14.3h-2.74
		l-2.7,14.3H85.53z"/>
          <path className="st2" d="M95.29,48.74c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.67-1.14-1.14-1.14-1.94v-0.3c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.49c0,1.64,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.02
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96H91.9v1.92C91.9,47.36,92.85,48.74,95.29,48.74"/>
          <polygon className="st2" points="99.41,36.23 101.39,36.23 101.39,48.57 103.81,48.57 103.81,36.23 105.8,36.23 105.8,34.27
		99.41,34.27 	"/>
          <path className="st2" d="M109.85,36.23c0.82,0,1.18,0.38,1.18,1.16v2.11c0,0.76-0.36,1.18-1.18,1.18h-0.8v-4.45H109.85z M111.3,48.57
		h2.53l-2.3-6.5c1.37-0.32,1.92-1.22,1.92-2.76v-1.83c0-2-0.89-3.21-3.31-3.21h-3.5v14.3h2.4v-6.56L111.3,48.57z"/>
          <path className="st2" d="M119.53,34.27v11.05c0,0.84-0.34,1.41-1.18,1.41c-0.84,0-1.18-0.57-1.18-1.41V34.27h-2.4v10.82
		c0,2.21,1.05,3.65,3.59,3.65c2.45,0,3.4-1.43,3.4-3.65V34.27H119.53z"/>
          <polygon className="st2" points="128.45,48.57 130.98,48.57 128.13,40.7 130.91,34.27 128.55,34.27 125.94,40.55 125.94,34.27
		123.53,34.27 123.53,48.57 125.94,48.57 125.94,41.61 	"/>
          <polygon className="st2" points="131.19,36.23 133.17,36.23 133.17,48.57 135.6,48.57 135.6,36.23 137.58,36.23 137.58,34.27
		131.19,34.27 	"/>
          <path className="st2" d="M143.04,34.27v11.05c0,0.84-0.34,1.41-1.18,1.41c-0.84,0-1.18-0.57-1.18-1.41V34.27h-2.4v10.82
		c0,2.21,1.05,3.65,3.59,3.65c2.45,0,3.4-1.43,3.4-3.65V34.27H143.04z"/>
          <path className="st2" d="M150.25,36.23c0.82,0,1.18,0.38,1.18,1.16v2.11c0,0.76-0.36,1.18-1.18,1.18h-0.8v-4.45H150.25z M151.71,48.57
		h2.53l-2.3-6.5c1.37-0.32,1.92-1.22,1.92-2.76v-1.83c0-2-0.89-3.21-3.31-3.21h-3.5v14.3h2.4v-6.56L151.71,48.57z"/>
        </g>
      </svg>
    </>
  );
};
