import React, { useEffect } from "react";
import { Dispatch, RootState } from "../../../../store/store";
import { connect } from "react-redux";
import { ICompanyUser, IUserData } from "../../../../store/models/interfaces";

export interface Props {
  leftTitle: string;
  body: string;
  leftInputLabelOne: string;
  leftInputLabelTwo: string;
  greenBtnLabel: string;
  submitDelete: () => void;
  cancel: () => void;
  user: IUserData,
  companyUsers: ICompanyUser[];
  getCompanyUsers: (companyId: string) => void;
  deleteAccess: ({ }: { companyId: string, userId: string }) => void;
}

const LeftComponent: React.FC<Props> = ({
  leftTitle,
  body,
}: Props) => {

  return (
    <div className="flex items-center">
      <div className="w-screen">
        <h3 className="font-quickStand font-mediumPlusPlus text-3xl">{leftTitle}</h3>
        <div className="font-quickStand my-6 whitespace-pre-wrap">{body}</div>
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.data,
  companyUsers: state.user?.companyUsers || []
})

const mapDispatch = (dispatch: Dispatch) => ({
  getCompanyUsers: dispatch.user.getCompanyUsers,
  deleteAccess: dispatch.user.deleteAccess
});

export default connect(mapState, mapDispatch)(LeftComponent);
