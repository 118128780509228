import React from "react";

export interface Props {
  title: string;
  body: string;
  redBtnLabel: string;
  greenBtnLabel: string;
  submitRed: () => void;
  submitGreen: () => void;
}

export const LeftComponent: React.FC<Props> = ({
  title,
  body,
  redBtnLabel,
  greenBtnLabel,
  submitRed,
  submitGreen,
}: Props) => {
  return (
    <div className="flex h-96 items-center">
      <div>
        <h3 className="font-quickStand font-mediumPlusPlus text-3xl">{title}</h3>
        <div className="font-quickStand mt-4 mb-8">{body}</div>

        <div className="w-full flex space-x-4 text-2xl text-white font-medium h-12">
          <button
            onClick={submitRed}
            className="w-2/5 bg-redLayer rounded-l-lg rounded-r-lg"
          >
            {redBtnLabel}
          </button>
          <button
            onClick={submitGreen}
            className="w-3/5 bg-greenLayer rounded-l-lg rounded-r-lg"
          >
            {greenBtnLabel}
          </button>
        </div>
      </div>
    </div>
  );
};
