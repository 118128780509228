import React, {useEffect} from "react";
import {CustomizedLabelSelect} from "../../select/CustomizedLabelSelect";
import {OPTION_COLOR, SELECT_TYPES} from "../../../../constants/ui";
import {Dispatch, RootState} from "../../../../store/store";
import {connect} from "react-redux";
import {ICompanyUser, IUserData} from "../../../../store/models/interfaces";
import {SelectOptionProps} from "../../Select";
import {EDITOR_ROLE, READER_ROLE} from "../../../../constants/roles";
import {difference} from "lodash";

export interface Props {
  leftTitle: string;
  body: string;
  leftInputLabelOne: string;
  leftInputLabelTwo: string;
  greenBtnLabel: string;
  submitDelete: () => void;
  cancel: () => void;
  user: IUserData,
  companyUsers: ICompanyUser[];
  getCompanyUsers: (companyId: string) => void;
  deleteAccess: ({}: {companyId: string, userId: string}) => void;
}

const LeftComponent: React.FC<Props> = ({
  leftTitle,
  body,
  leftInputLabelOne,
  leftInputLabelTwo,
  user,
  companyUsers,
  getCompanyUsers,
  deleteAccess
}: Props) => {

  useEffect(() => {
    getCompanyUsers(user.company.id);
  },[] );

  const getOptionsByRole = (role: string) => {
    const filteredUsers = companyUsers.filter( u => u.role.value === role);
    return filteredUsers.map( (companyUser: ICompanyUser) => (
      {
        value: companyUser.id,
        label: companyUser.first_name + " " + companyUser.last_name,
        color: OPTION_COLOR
      }))
  }

  const editorOptions: SelectOptionProps[] = getOptionsByRole(EDITOR_ROLE) || []

  const readerOptions: SelectOptionProps[] = getOptionsByRole(READER_ROLE) || []

  const handleOnRemoveClicked = (changedOptions: any, initialOptions: any) => {
    const optionsValue: SelectOptionProps[] = changedOptions.map((value: any) => value)
    const removedElement = difference(initialOptions, optionsValue)[0]
    deleteAccess({
      companyId: user.company.id,
      userId: removedElement.value
    });
    const index = initialOptions.indexOf(removedElement)
    if (index !== -1) {
      initialOptions.splice(index, 1)
    }
  }

  return (
    <div className="flex items-center">
      <div className="w-screen">
        <h3 className="font-quickStand font-semibold text-[2.3rem]">{leftTitle}</h3>
        <div className="font-quickStand my-6">{body}</div>

        <div className="flex mb-6">
          <div className="w-full">
            { editorOptions.length > 0 && <CustomizedLabelSelect
              onChange={e => handleOnRemoveClicked(e, editorOptions)}
              label={leftInputLabelOne}
              type={SELECT_TYPES.GRAY}
              optionList={editorOptions}
              selectedOption={editorOptions}
              backgroundColor="#d8d8d8"
              fontsizestyle="text-[1.5rem]"
            />}
          </div>
        </div>

        <div className="flex mb-6">
          <div className="w-full">
            { readerOptions.length > 0 && <CustomizedLabelSelect
              onChange={e => handleOnRemoveClicked(e, readerOptions)}
              label={leftInputLabelTwo}
              type={SELECT_TYPES.GRAY}
              optionList={readerOptions}
              selectedOption={readerOptions}
              backgroundColor="#d8d8d8"
              fontsizestyle="text-[1.5rem]"
            />}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.data,
  companyUsers: state.user?.companyUsers || []
})

const mapDispatch = (dispatch: Dispatch) => ({
  getCompanyUsers: dispatch.user.getCompanyUsers,
  deleteAccess: dispatch.user.deleteAccess
});

export default connect(mapState, mapDispatch)(LeftComponent);
