import React from 'react';

import { CustomizedSelect, Props as SelectProps } from './CustomizedSelect';

export interface Props extends SelectProps {
    label: string;
    error?: string;
    fontsizestyle?: string;
}

export const CustomizedLabelSelect: React.FC<Props> = ({
    label,
    error,
    fontsizestyle,
    ...props
}) => {
    return <div className="w-full">
        <div className={`text-2xl mb-2 flex ${fontsizestyle || ''}`}>
        <label>{label}</label>
        </div>
        <CustomizedSelect {...props} />
        <p className="text-base text-red-600">{error || ""}</p>
    </div>
}