import classNames from "classnames";
import { useFormik } from "formik";
import React, { ChangeEvent, FC } from "react";
import { connect } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Button } from "../../components/core/Button";
import { CustomizedLabelInput } from "../../components/core/inputs/CustomizedLabelInput";
import { INPUTS } from "../../constants/ui";
import {
  FORGOT_PASSWORD_ROUTE,
  SIGN_UP_COMPANY_ROUTE,
} from "../../store/constants/route-constants";
import { Dispatch, RootState } from "../../store/store";
import { RegisterPageWrapper } from "../RegisterPageWrapper";

type SignInProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const SignInPage: FC<SignInProps> = ({ isAuthenticated, signIn }) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email").required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      await signIn({
        email: values.email,
        password: values.password,
      });
    },
    enableReinitialize: false,
  });

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <RegisterPageWrapper
      title="Log ind"
      action={{
        title: "Log ind",
        handler: formik.handleSubmit,
        disabled: !formik.isValid,
      }}
    >
      <div className="mb-6">
        <CustomizedLabelInput
          autoFocus
          id="email"
          autoComplete="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.values.email && formik.errors.email}
          type="email"
          label="Email"
          placeHolder="Din mailadresse"
          styleType={formik.values.email && formik.errors.email ? INPUTS.ERROR : INPUTS.DEFAULT_FULL_WIDTH}
          disabled={false}
        />
      </div>
      <div className="mb-6">
        <CustomizedLabelInput
          id="password"
          autoComplete="new-password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.values.password && formik.errors.password}
          type="password"
          label="Kodeord"
          placeHolder="Kodeord"
          styleType={formik.values.password && formik.errors.password ? INPUTS.ERROR : INPUTS.DEFAULT_FULL_WIDTH}
          disabled={false}
        />
        <div className="mt-2 text-right">
          <a className="cursor-pointer" onClick={() => navigate(FORGOT_PASSWORD_ROUTE)} >Glemt kodeord?</a>
        </div>
      </div>

      <div className="text-center">
        <a className="cursor-pointer" onClick={() => navigate(SIGN_UP_COMPANY_ROUTE)} >Register company</a>
      </div>
    </RegisterPageWrapper>
  );
};

const mapState = (state: RootState) => ({
  isAuthenticated: state.user?.isAuthenticated,
});

const mapDispatch = (dispatch: Dispatch) => ({
  signIn: dispatch.user.signIn,
});

export default connect(mapState, mapDispatch)(SignInPage);
