import classnames from "classnames";
import React, { useMemo } from "react";

export interface Props
  extends React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    React.AriaAttributes {
  label: React.ReactNode;
  backgroundColor: string;
  textColor: string;
  hoverColor?: string;
  borderRadius?: string;
  borderSize?: string;
  borderColor?: string;
  paddingX?: string;
  paddingL?: string;
  paddingR?: string;
  paddingY?: string;
  paddingT?: string;
  paddingB?: string;
  width?: string;
  height?: string;
  fontSize?: string;
  fontWeight?: string;
  fontFamily?: string;
}

export const Button: React.FC<Props> = ({
  label,
  backgroundColor,
  textColor = "text-white",
  disabled = false,
  hoverColor,
  borderRadius = "rounded-10",
  borderSize,
  borderColor,
  paddingX,
  paddingL,
  paddingR,
  paddingY,
  paddingT,
  paddingB,
  width,
  height,
  fontSize,
  fontWeight,
  fontFamily,
  ...props
}: Props) => {
  const classList = useMemo(() => {
    const list = [
      backgroundColor,
      textColor,
      hoverColor,
      borderRadius,
      borderSize,
      borderColor,
      paddingX,
      paddingL,
      paddingR,
      paddingY,
      paddingT,
      paddingB,
      width,
      height,
      fontSize,
      fontWeight,
      fontFamily,
    ];
    return list
      .filter((i) => !["undefined", "null", "true", "false"].includes(`${i}`))
      .join(" ");
  }, []);

  return (
    <>
      <button
        disabled={disabled}
        {...props}
        className={classnames(`${classList}`, {
          "cursor-not-allowed": disabled,
        })}
      >
        {label}
      </button>
    </>
  );
};
