import classNames from "classnames";
import React, { SyntheticEvent } from "react";
import "./style.css";

export interface Props {
  onSelect?: (e: SyntheticEvent) => void;
  color?: string;
  border?: string;
}

export const RadioGroup: React.FC<Props> = ({
  border = "border-white",
  onSelect,
  ...props
}: Props) => {
  return (
    <>
      <div className="grid grid-cols-6 gap-y-8 gap-x-1 justify-items-center">
        <input
          {...props}
          onClick={onSelect}
          id="list-radio-id"
          type="radio"
          name="radio"
          defaultChecked={props?.color === '#DCB473'}
          value={"#DCB473"}
          style={{ borderColor: border }}
          className={classNames(
            `appearance-none cursor-pointer checked:p-0 h-[4rem] w-[4rem] border-[0.32rem] checked:border-5 border-solid rounded-[50%] bg-yellow-1200 checked:border-white`
          )}
        />
        <input
          {...props}
          onClick={onSelect}
          id="list-radio-id"
          type="radio"
          name="radio"
          style={{ borderColor: border }}
          defaultChecked={props?.color === '#5AB478'}
          value={"#5AB478"}
          className={classNames(
            `appearance-none cursor-pointer checked:p-0 h-[4rem] w-[4rem] border-[0.32rem] checked:border-5 border-solid rounded-[50%] bg-green-200 checked:border-white`
          )}
        />
        <input
          {...props}
          onClick={onSelect}
          id="list-radio-id"
          type="radio"
          name="radio"
          style={{ borderColor: border }}
          defaultChecked={props?.color === '#3D3938'}
          value={"#3D3938"}
          className={classNames(
            `appearance-none cursor-pointer checked:p-0 h-[4rem] w-[4rem] border-[0.32rem] checked:border-5 border-solid rounded-[50%] bg-black-1200 checked:border-white`
          )}
        />
        <input
          {...props}
          onClick={onSelect}
          id="list-radio-id"
          type="radio"
          name="radio"
          style={{ borderColor: border }}
          defaultChecked={props?.color === '#8C1473'}
          value={"#8C1473"}
          className={classNames(
            `appearance-none cursor-pointer checked:p-0 h-[4rem] w-[4rem] border-[0.32rem] checked:border-5 border-solid rounded-[50%] bg-red-1200 checked:border-white`
          )}
        />
        <input
          {...props}
          onClick={onSelect}
          id="list-radio-id"
          type="radio"
          name="radio"
          style={{ borderColor: border }}
          defaultChecked={props?.color === '#FAB900'}
          value={"#FAB900"}
          className={classNames(
            `appearance-none cursor-pointer checked:p-0 h-[4rem] w-[4rem] border-[0.32rem] checked:border-5 border-solid rounded-[50%] bg-yellow-1000 checked:border-white`
          )}
        />
        <input
          {...props}
          onClick={onSelect}
          id="list-radio-id"
          type="radio"
          name="radio"
          style={{ borderColor: border }}
          defaultChecked={props?.color === '#F5AFA5'}
          value={"#F5AFA5"}
          className={classNames(
            `appearance-none cursor-pointer checked:p-0 h-[4rem] w-[4rem] border-[0.32rem] checked:border-5 border-solid rounded-[50%] bg-pink-1200 checked:border-white`
          )}
        />
        <input
          {...props}
          onClick={onSelect}
          id="list-radio-id"
          type="radio"
          name="radio"
          style={{ borderColor: border }}
          defaultChecked={props?.color === '#E6007D'}
          value={"#E6007D"}
          className={classNames(
            `appearance-none cursor-pointer checked:p-0 h-[4rem] w-[4rem] border-[0.32rem] checked:border-5 border-solid rounded-[50%] bg-red-200 checked:border-white`
          )}
        />
        <input
          {...props}
          onClick={onSelect}
          id="list-radio-id"
          type="radio"
          name="radio"
          style={{ borderColor: border }}
          defaultChecked={props?.color === '#37B4E6'}
          value={"#37B4E6"}
          className={classNames(
            `appearance-none cursor-pointer checked:p-0 h-[4rem] w-[4rem] border-[0.32rem] checked:border-5 border-solid rounded-[50%] bg-blue-200 checked:border-white`
          )}
        />
        <input
          {...props}
          onClick={onSelect}
          id="list-radio-id"
          type="radio"
          name="radio"
          style={{ borderColor: border }}
          defaultChecked={props?.color === '#322864'}
          value={"#322864"}
          className={classNames(
            `appearance-none cursor-pointer checked:p-0 h-[4rem] w-[4rem] border-[0.32rem] checked:border-5 border-solid rounded-[50%] bg-deepKoamaru-700 checked:border-white`
          )}
        />
        <input
          {...props}
          onClick={onSelect}
          id="list-radio-id"
          type="radio"
          name="radio"
          style={{ borderColor: border }}
          defaultChecked={props?.color === '#EB6969'}
          value={"#EB6969"}
          className={classNames(
            `appearance-none cursor-pointer checked:p-0 h-[4rem] w-[4rem] border-[0.32rem] checked:border-5 border-solid rounded-[50%] bg-red-500 checked:border-white`
          )}
        />
        <input
          {...props}
          onClick={onSelect}
          id="list-radio-id"
          type="radio"
          name="radio"
          style={{ borderColor: border }}
          defaultChecked={props?.color === '#32B4B4'}
          value={"#32B4B4"}
          className={classNames(
            `appearance-none cursor-pointer checked:p-0 h-[4rem] w-[4rem] border-[0.32rem] checked:border-5 border-solid rounded-[50%] bg-blue-500 checked:border-white`
          )}
        />
        <input
          {...props}
          onClick={onSelect}
          id="list-radio-id"
          type="radio"
          name="radio"
          style={{ borderColor: border }}
          defaultChecked={props?.color === '#FAE61E'}
          value={"#FAE61E"}
          className={classNames(
            `appearance-none cursor-pointer checked:p-0 h-[4rem] w-[4rem] border-[0.32rem] checked:border-5 border-solid rounded-[50%] bg-yellow-500 checked:border-white`
          )}
        />
      </div>
    </>
  );
};
