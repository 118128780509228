import React from "react";

export interface Props {
  isActive: boolean;
}

export const IconTenFour: React.FC<Props> = ({ isActive = false }: Props) => {
  return (
    <>
      <svg
        style={{filter: isActive ? "grayscale(0)" : "grayscale(1)"}}
        width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g filter="url(#filter_ten_four)">
          <rect x="0" y="0" width="100" height="100"  fill="url(#goal_ten_four)" shapeRendering="crispEdges" />
        </g>
        <defs>
          <filter id="filter_ten_four" x="0" y="0" width="170" height="170" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="15" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_44_193" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_44_193" result="shape" />
          </filter>
          <pattern id="goal_ten_four" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image_ten_four" transform="scale(0.01)" />
          </pattern>
          <image id="image_ten_four" width="100" height="100" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAFLElEQVR4nO2dz28bRRTHv5P1pk6c3xAnQUgISJEACYmC1PqAoD1VgRJTDkkuVKRVJCqhlsIfwIFD4eAWUom2CCFRcaUgEFIPqNwKQkjAgQMklZLQrglpEpeksbNrLwf3x9pex+t4d+ft+n0usdfrmXE+nvfeeNaJmOmbMsGQoUX2AJhSWAgxWAgxWAgxWAgxWAgxWAgxWAgxWAgxWAgxWAgxWAgxWAgxWAgxWAgxWAgxIjI77317BKIjCqDaHpmw3DbLjlnvmzbH7doqf8x6zNqO5aeex/LJb4CCP/t4UoV0HXkBSn+XzCHUxNzQsfL+tzCrinYXeSFL1D6FBD6Pk3NILXy+4kCeEL60whYOWcTgkEUMz6usyGA3uiafBwAU1nNY/fCS1126i88z2XMhSrwbPSdGAAD5f/8LnpCmSepBgcve5obL3lqEOmSJKrcpE+qQFZRZYSXUM8SKBy80+9Ms9NlF9xv2kdCErOyPM0iPfQQtmYI++4+7jftIKKqs7JUZpMemUVjLwdBWoSVPBVZK4HNI9spfSI9Po7Ceu3vsrpQZF6SEOqm7TFHGmRIZdzC0VWivuCDF56QudcewEQqrt5A5fxnR3cNbnrc6fQn3n5yAaFN9Gllj+CvExenf0tOOgc+m3GuQCHJzSBDXJR4jr+y1u88Ev8oKG3KTukNBxsIyRDRScsmQ/mca69/96uj50d3DiCbuJX9zLYv8zSwiD/TUNVw/kCvEQcgyFpahJVMY+OJoiZDNP65h+b2vHHXT+86LJUIKazloyRSGvjyOyIN9dQ/bS+TmkBrckaHPLVU+tcH8o19dhDaagjG31FhDLuPvDKkjhxgLN4qr7du/sIqLQHeojq96FLEdtgPR55ZwfTSFoYtvQX243/ngPIRkDjEWbkAbPQV9vvq7t33/U3ho/wcND8H4exnaaApDF49DfXTA+SA9Qu5HJzZhx8zq0A6e3lKG2x0b11egHTwNc2Oz5rleQy6HiKiKnhMjEIp/QxMRBfe9+ypEW6tvfVZDbg6pEg06JxKAAJaOXYCZL9iek/v5KjJnvy/5IkK1JjsO7EIs+YztWSKiIH72dcSSzzp4Ad4j97OsLWZM53gCQHUpxrUVrH39i6NuW3cOIgarkGLH1GQAsmdIDTrHExAQWDz2eeWDDYb2oozJspkjH5JVlpWO8T2AKOYWK0q8G+37nnTUjfpIWUmrtCB+bhKxUVoyANlCHL7LO8b2VByLJoYxmHhzW90q/Z0kZQAEq6xmhz/tJYbchWEgBPk7yEDkEFv0PDKf/gAzq2/dhaqg69Bzt79+vR38jbOB3VOHqqD1sSGkX/u4qhShKoifO9yADP8J9J56274nMHjhjYqSGLgnI/byrsY68ZnA76m37a2UIlQF8fPBkwHIniEuYZVSlHEEsQPBkwHITuouCipKOYrCehaxl552r2GfCW6VZUPb3sfdbVACvFInRihySJjglXpNmnxPnR5Nvqfe7AR6pR5GAr9SDxtcZRGDqyxicJVVk6YpewNhw3ckCjEDErLCvGNYRu63eSi9MZlDqImZ23qL2G3kCTGB9MQZad1TJdB/ySGMsBBi+BqyWjqj6J8+tI1nWrN/vUm2/D8n2D9/8/d5ZD65XP/QXMZXISKqFr/7QZBbPe0khHDIIobnM8Q08sgv3vS6m4YpZDZkDwEAIGb6pgKxPGsWOGQRg4UQg4UQg4UQg4UQg4UQg4UQg4UQg4UQg4UQg4UQg4UQg4UQg4UQg4UQg4UQ43/1qVdbR6DCbQAAAABJRU5ErkJggg==" />
        </defs>
      </svg>
    </>
  );
};
