export const BP_ADD_MODAL_DATA = {
  title: "Forretningsplan",
  introduction: `
    <p class='mb-6'>Virksomhedens forretningsplan er en plan, som skal indeholde en generel beskrivelse af virksomheden og produktet eller servicen. Udover at beskrive virksomheden, skal forretningsplanen også angive, hvordan du planlægger at drive og udvikle virksomheden.</p>
    <p class='mb-6'>Først og fremmest gør udarbejdelsen af en forretningsplan hele stiftelsen af virksomheden mere overskuelig. Ved udarbejdelsen af planen får du mulighed for at reflektere over de mange forhold, som du skal tage stilling til, inden du for alvor går i gang din kommende virksomhed.</p>
    <p class='mb-6'>Afslutningsvis kan udarbejdelsen af en forretningsplan være med til at overbevise omgivelserne om, at der drives en professionel og seriøs virksomhed. </p>
    <p class='mb-6'>Start her med en overordnet beskrivelse af ide og vision med virksomheden. Giv et kort resumé, eller executive summary, Hold det kort og simpelt, men inkludér de vigtigste emner af forretningsplanen. </p>
    <p class='mb-6'>Inspirationsspørgsmål:</p>
    <p class='mb-6'>Hvad er ideen med virksomheden?</p>
    <p class='mb-6'>Hvad er visionen? </p>
    <p class='mb-6'>Hvorfor er denne branche valgt?</p>
    <p class='mb-6'>Hvad gør at der er plads i markedet til netop denne ide?</p>
  `,
  inputLabel: "Overskrift",
  textareaLabel: "Beskrivelse",
  SubmitButtonText: "Gem",
};

export const BP_DELETE_MODAL_DATA = {
  title: "Er du sikker på at du vil slette dette lag?",
  body: "Du er ved at slette et lag i din forretningsmodel. Hvis du fortsætter sletningen vil alle dine udfyldninger i dette lag blive slettet.",
  redBtnLabel: "Slet",
  greenBtnLabel: "Behold",
}

export const BP_REQUEST_CONFIRMATION_DATA = {
  blueTitle: "Anmod om adgang til det økonomiske lag",
  yellowTitle: "Anmod om adgang til det sociale lag",
  greenTitle: "Anmod om adgang til det miljømæssige lag",
  redTitle: "Anmod om adgang til FNs 17 verdensmål",
  body: "Du er ved at anmode om adgang til FNs verdensmål. Hvis du gennemfører anmodningen skal den godkendes af en administrator.",
  redBtnLabel: "Luk",
  greenBtnLabel: "Anmod",
}

export const LAYER_DELETE_CONFIRMATION_DATA = {
  title: "Er du sikker på at du vil slette dette lag?",
  body: "Du er ved at slette et lag. Hvis du fortsætter sletningen vil alle tag udfyldninger i dette lag blive slettet.",
  redBtnLabel: "Slet",
  greenBtnLabel: "Behold",
}

export const ACTIVITY_DELETE_CONFIRMATION_DATA = {
  title: "Er du sikker på at du vil slette dette aktivitet?",
  body: "Du er ved at slette et aktivitet.",
  redBtnLabel: "Slet",
  greenBtnLabel: "Behold",
}

export const LAYER_TAG_DELETE_CONFIRMATION_DATA = {
  title: "Er du sikker på at du vil slette dette tag?",
  body: "Du er ved at slette et tag.",
  redBtnLabel: "Slet",
  greenBtnLabel: "Behold",
}

export const RED_SUB_GOAL_DELETE_CONFIRMATION_DATA = {
  title: "Er du sikker på, at du vil slette dette delmål?",
  body: "Du er ved at slette et delmål.",
  redBtnLabel: "Slet",
  greenBtnLabel: "Behold",
}