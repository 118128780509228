import React, { FormEvent, useEffect, useState } from "react";
import { FORM_BUTTONS, INPUTS } from "../../constants/ui";
import { CustomizedFormButton } from "../core/buttons/CustomizedFormButton";
import { CustomizedInput } from "../core/inputs/CustomizedInput";
import { CustomizedTextarea } from "../core/textarea/CustomizedTextarea";
import plusIcon from "../../assets/icons/small-plus-2.png";
import { Dispatch, RootState } from "../../store/store";
import { connect } from "react-redux";

export interface Props {
  inputLabel: string;
  textareaLabel: string;
  SubmitButtonText: string;
  FormSubmitBtnDisabled: boolean;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  data: any;
  getCompanyUsers: (companyId: string) => void;
  userId: string;
  users: any[];
}

const RightComponent: React.FC<Props> = ({
  inputLabel,
  textareaLabel,
  SubmitButtonText,
  FormSubmitBtnDisabled,
  onSubmit,
  getCompanyUsers,
  userId,
  data,
  users,
  ...props
}: Props) => {
  const [activeInput, setActiveInput] = useState<string>("description");
  useEffect(() => {
    getCompanyUsers(userId);
  }, []);

  const getDateValue = (dateValue: string) => {
    const date = dateValue ? new Date(dateValue) : new Date();
    return date?.toISOString()?.substring(0, 10) || '';
  }

  return (
    <>
      <form onSubmit={onSubmit} className="h-[570px] relative">
        <div className="mb-6">
          <div className="text-2xl mb-2 flex">
            <label className="font-quickStand font-medium text-26 leading-32">
              {inputLabel}
            </label>
          </div>
          <CustomizedInput
            autoFocus
            defaultValue={data?.layerGoalTitle}
            placeHolder={""}
            disabled={false}
            styleType={INPUTS.DEFAULT_HALF_WIDTH}
            {...props}
          />
        </div>
        <div className="mb-6">
          <div className="flex items-center my-2">
            <img height={15} width={15} src={plusIcon} alt="" />
            <p className="ml-2 cursor-pointer text-2xl leading-none" onClick={() => setActiveInput("milestoneDefinition")}>Defination af delmål</p>
          </div>
          <div
            className="mb-4"
            hidden={activeInput !== "milestoneDefinition"}
          >
            <CustomizedInput
              autoFocus
              defaultValue={data?.layerGoalMilestoneDefinition}
              placeHolder={""}
              disabled={false}
              styleType={INPUTS.DEFAULT_HALF_WIDTH}
              {...props}
            />
          </div>
        </div>
        <div className="mb-6">
          <div className="flex items-center my-2">
            <img height={15} width={15} src={plusIcon} alt="" />
            <p className="ml-2 cursor-pointer text-2xl leading-none" onClick={() => setActiveInput("description")}>Hvilken indsats er planlagt for delmålet</p>
          </div>
          <CustomizedTextarea
            defaultValue={data?.layerGoalDescription}
            hidden={activeInput !== "description"}
            placeHolder={""}
            disabled={false}
            type={INPUTS.DEFAULT_FULL_WIDTH}
            height="h-[190px]"
            {...props}
          />
        </div>
        <div className="my-6">
          <div className="flex items-center mt-2 mb-3 ">
            <img height={15} width={15} src={plusIcon} alt="" />
            <p className="ml-2 cursor-pointer text-2xl leading-none" onClick={() => setActiveInput("deadline")}>Hvilken deadline er aftalt</p>
          </div>

          <input
            type="date"
            style={{ backgroundColor: '#0000000a', border: 'none', }}
            defaultValue={getDateValue(data?.layerGoalDeadline || '')}
            className={`bg-gray-100 h-82 font-quickStand border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 ${activeInput !== "deadline" ? "hidden" : ""}`}
            placeholder="Select date" />
        </div>

        <div className="flex items-center my-2 mb-3">
          <img height={15} width={15} src={plusIcon} alt="" />
          <p className="ml-2 cursor-pointer text-2xl leading-none" onClick={() => setActiveInput("responsibles")}>Hvem har ansvaret</p>
        </div>
        <div
          className="mb-4"
          hidden={activeInput !== "responsibles"}
        >
          <CustomizedInput
            autoFocus
            defaultValue={data?.layerResponsibles}
            placeHolder={""}
            disabled={false}
            styleType={INPUTS.DEFAULT_HALF_WIDTH}
            {...props}
          />
        </div>
        <div className="absolute left-0 right-0 bottom-0">
          <CustomizedFormButton
            text={SubmitButtonText}
            btn_type={FORM_BUTTONS.PRIMARY}
            disabled={FormSubmitBtnDisabled}
            type="submit"
            {...props}
          />
        </div>
      </form>
    </>
  );
};

const mapState = (state: RootState) => ({
  userId: state.user.data?.company.id,
  users: state.user.companyUsers
});

const mapDispatch = (dispatch: Dispatch) => ({
  getCompanyUsers: dispatch.user.getCompanyUsers
});

export default connect(mapState, mapDispatch)(RightComponent);
