import React, { FC, SyntheticEvent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import plusIcon from "../../assets/icons/plus.png";
import { AddBusinessPlanModal } from "../../components/BusinensPlanModals/addBpModal";
import { Dispatch, RootState } from "../../store/store";

let isFetched = false;

const EmptyBusinessPlanPage: FC<any> = ({
  createBP,
  getBPs,
  businessPlanesLength,
}) => {
  const [addBusinessPlanModal, setAddBusinessPlanModal] = useState({
    visible: false,
    name: "",
    description: "",
  });

  useEffect(() => {
    if (!businessPlanesLength && !isFetched) {
      getBPs();
      isFetched = true;
    }
  }, []);

  const openModel = () => {
    setAddBusinessPlanModal({ ...addBusinessPlanModal, visible: true });
  };

  const closeModal = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setAddBusinessPlanModal({ ...addBusinessPlanModal, visible: false });
  };

  const onSubmitForm = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const name = event.target[0]?.value;
    const description = event.target[1].value;
    if (!name?.length) {
      toast.warning("Indtast overskriften!");
      return;
    }
    if (name.length || description.length) {
      await createBP({ name, description });
    }
  };

  return (
    <>
      <div className="bg-secondBg flex justify-center items-center flex-grow drop-shadow-lg m-6 rounded-lg">
        <div className="place-self-center">
          <img
            className="m-auto cursor-pointer w-20"
            onClick={openModel}
            src={plusIcon}
          />
          <h1 className="text-4xl font-bold leading-loose">
            Tilføj forretningsplan
          </h1>
        </div>
      </div>
      <AddBusinessPlanModal
        onSubmitForm={onSubmitForm}
        closeModal={closeModal}
        data={addBusinessPlanModal}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  businessPlanesLength: state.businessPlan.length,
});

const mapDispatch = (dispatch: Dispatch) => ({
  createBP: dispatch.businessPlan.createBP,
  getBPs: dispatch.businessPlan.getBPs,
});

export default connect(mapState, mapDispatch)(EmptyBusinessPlanPage);
