import React, { FC } from "react";
interface ICardTag {
  data: any;
  open: any;
  cardData?: any;
  isSelected?: boolean;
  onMouseEnter?: (value: any) => void;
  onMouseLeave?: (value: any) => void;
}

export const CardTag: FC<ICardTag> = ({ data, open, cardData, isSelected, onMouseEnter, onMouseLeave }) => {
  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={open?.bind(null, { ...data, card_id: cardData.card_id, card_title: cardData.title, card_description: cardData.description })}
      style={{ background: "#0000001c" }}
      className={`text-1-xs overflow-hidden w-fit h-fit relative cursor-pointer px-3 p-1 ml-0 mr-2 mb-3 text-white text-left rounded-[15px] shadow-sm ${isSelected ? "shadow-[0px_0px_6px_rgba(0,0,0,0.6)]" : ""}`}
    >
      {data?.activities?.length ? (<div className="flex justify-between h-1 absolute top-0 left-0 right-0">
        {data?.activities?.map((ac: any) => <div key={ac.id} style={{ background: ac.color }} className="h-1 w-full" />) || <div className="h-1 w-full" />}
      </div>) : null}
      {data?.title || ''}
    </div>
  )
}

export const RedLayerTag: FC<any> = ({ title }) => {
  return (
    <div
      style={{ background: "#0000001c" }}
      className={`text-1-xs overflow-hidden w-fit relative cursor-pointer px-3 p-1 ml-0 m-1 text-white text-left rounded-[15px] shadow-sm`}
    >
      {title || ''}
    </div>
  )
}
