import React, { FormEvent, SyntheticEvent } from "react";

import { Modal } from "../../Modal";
import { LeftComponent } from "../common/LeftComponent";
import { RightComponent } from "./RightComponent";

export interface Props {
  showModal: boolean;
  title: string;
  introduction: string;
  inputLabel: string;
  textareaLabel: string;
  SubmitButtonText: string;
  FormSubmitBtnDisabled: boolean;
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
  setShowModal?: (e: SyntheticEvent) => void;
  data: any;
}

export const Default: React.FC<Props> = ({ showModal, ...props }: Props) => {
  return (
    <>
      <Modal
        LeftComponent={LeftComponent}
        RightComponent={RightComponent}
        rightcomponentbackgroundcolor={"bg-gray-100"}
        showModal={showModal}
        {...props}
      />
    </>
  );
};
