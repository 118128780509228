import React from "react";

export interface Props {
  isActive: boolean;
}

export const IconFour: React.FC<Props> = ({ isActive = false }: Props) => {
  return (
    <>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 170.08 170.08" style={{ ['enableBackground' as any]: 'new 0 0 170.08 170.08', 'filter': 'none' }} xmlSpace="preserve">
        <style type="text/css">
          {`  .st04{fill:${isActive ? '#c7202f' : '#B2B2B2'};}
            svg:hover .st04{fill:#c7202f;}
        .st1{fill:#FFFFFF;}`}
        </style>
        <rect className="st04" width="170.08" height="170.08" />
        <path className="st1" d="M26.64,48.54h5.46v-5.56h2.33v-4.5h-2.33V14.25h-5.67l-9.96,25.19v3.54h10.17V48.54z M21.53,38.48l5.16-14.11
	h0.05v14.11H21.53z"/>
        <polygon className="st1" points="75.42,91.19 44.91,73.58 44.91,130.56 75.42,139.76 " />
        <polygon className="st1" points="78.77,91.31 78.77,139.78 109.47,130.55 109.47,73.58 " />
        <g>
          <path className="st1" d="M133.42,72.14c0-1.99-1.62-3.61-3.61-3.61c-2,0-3.61,1.62-3.61,3.61v3.89h7.22V72.14z" />
          <polygon className="st1" points="127.1,129.55 126.2,129.55 129.81,142.16 133.42,129.55 132.52,129.55 133.42,129.55 133.42,78.85
		132.52,78.85 131.63,78.85 130.68,78.85 128.88,78.85 127.99,78.85 127.1,78.85 126.2,78.85 126.2,129.55 	"/>
          <polygon className="st1" points="116.03,76.03 112.76,73.44 112.76,132.1 78.86,143.06 77.29,143.06 76.98,143.06 75.42,143.06
		41.68,133.63 41.68,73.44 38.4,76.03 38.4,135.92 75.26,146.13 76.98,146.13 77.29,146.13 79.02,146.13 113.02,135.33
		116.03,134.45 	"/>
        </g>
        <polygon className="st1" points="53.8,28.15 56.33,28.15 53.49,20.29 56.27,13.85 53.91,13.85 51.29,20.14 51.29,13.85 48.89,13.85
	48.89,28.15 51.29,28.15 51.29,21.19 "/>
        <polygon className="st1" points="61.42,13.85 59.73,23.83 59.71,23.83 58.06,13.85 55.61,13.85 58.34,28.15 60.95,28.15 63.67,13.85 " />
        <path className="st1" d="M66.98,17.88H67l0.95,5.74h-1.92L66.98,17.88z M65.3,28.15l0.42-2.62h2.55l0.44,2.62h2.47l-2.72-14.3h-2.74
	l-2.7,14.3H65.3z"/>
        <polygon className="st1" points="72.11,28.15 77.38,28.15 77.38,26.19 74.51,26.19 74.51,13.85 72.11,13.85 " />
        <rect x="78.41" y="13.85" className="st1" width="2.4" height="14.3" />
        <polygon className="st1" points="81.87,15.81 83.86,15.81 83.86,28.15 86.28,28.15 86.28,15.81 88.26,15.81 88.26,13.85 81.87,13.85 " />
        <polygon className="st1" points="89.32,28.15 95.06,28.15 95.06,26.19 91.72,26.19 91.72,21.85 94.11,21.85 94.11,19.91 91.72,19.91
	91.72,15.81 95.06,15.81 95.06,13.85 89.32,13.85 "/>
        <polygon className="st1" points="96,15.81 97.99,15.81 97.99,28.15 100.41,28.15 100.41,15.81 102.39,15.81 102.39,13.85 96,13.85 " />
        <g>
          <path className="st1" d="M106.72,28.32c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.68-1.14-1.14-1.14-1.94v-0.3c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.48c0,1.65,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.02
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96h-2.24v1.92C103.32,26.95,104.27,28.32,106.72,28.32"/>
          <rect x="111.27" y="20.54" className="st1" width="3.37" height="1.94" />
          <path className="st1" d="M53.65,34.27v11.05c0,0.84-0.34,1.41-1.18,1.41s-1.18-0.57-1.18-1.41V34.27h-2.4v10.82
		c0,2.21,1.05,3.65,3.59,3.65c2.45,0,3.4-1.43,3.4-3.65V34.27H53.65z"/>
          <path className="st1" d="M60.95,34.27h-3.71v14.3h3.71c2.57,0,3.48-1.65,3.48-3.84v-6.62C64.43,35.89,63.52,34.27,60.95,34.27
		 M59.64,46.61V36.23h1.01c0.99,0,1.37,0.59,1.37,1.43v7.49c0,0.87-0.38,1.46-1.37,1.46H59.64z"/>
          <path className="st1" d="M69.51,34.27H65.8v14.3h3.71c2.57,0,3.48-1.65,3.48-3.84v-6.62C72.99,35.89,72.09,34.27,69.51,34.27
		 M68.21,46.61V36.23h1.01c0.99,0,1.37,0.59,1.37,1.43v7.49c0,0.87-0.38,1.46-1.37,1.46H68.21z"/>
          <path className="st1" d="M77.63,38.3h0.02l0.95,5.74h-1.92L77.63,38.3z M75.95,48.57l0.42-2.62h2.55l0.44,2.62h2.47l-2.72-14.3h-2.74
		l-2.7,14.3H75.95z"/>
          <polygon className="st1" points="87.67,48.57 89.78,48.57 89.78,34.27 87.78,34.27 87.78,42.47 85.18,34.27 82.82,34.27 82.82,48.57
		84.85,48.57 84.85,39.58 	"/>
          <polygon className="st1" points="96.43,48.57 98.53,48.57 98.53,34.27 96.53,34.27 96.53,42.47 93.94,34.27 91.57,34.27 91.57,48.57
		93.6,48.57 93.6,39.58 	"/>
          <polygon className="st1" points="100.33,48.57 106.06,48.57 106.06,46.61 102.73,46.61 102.73,42.26 105.11,42.26 105.11,40.32
		102.73,40.32 102.73,36.23 106.06,36.23 106.06,34.27 100.33,34.27 	"/>
          <polygon className="st1" points="107.35,48.57 112.62,48.57 112.62,46.61 109.75,46.61 109.75,34.27 107.35,34.27 	" />
          <path className="st1" d="M116.88,48.74c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.68-1.14-1.14-1.14-1.94v-0.29c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.48c0,1.65,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.02
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96h-2.24v1.92C113.49,47.37,114.44,48.74,116.88,48.74"/>
          <polygon className="st1" points="121.65,48.57 127.39,48.57 127.39,46.61 124.05,46.61 124.05,42.26 126.44,42.26 126.44,40.32
		124.05,40.32 124.05,36.23 127.39,36.23 127.39,34.27 121.65,34.27 	"/>
        </g>
      </svg>
    </>
  );
};
