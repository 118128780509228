import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { toast } from "react-toastify";

import http from "../http/http-common";
import history from "../../helpers/history";
import { APPLICATION_URL } from "../constants/api-contstants";
import { IRedLayerState } from "./interfaces/redLayer";

export const redLayer = createModel<RootModel>()({
  state: {} as IRedLayerState,
  reducers: {

    setLayer(state, data) {
      return { ...data };
    },

    setLayerGoal(state, data) {
      const layerGoals = state?.layer_goals || [];
      return {...state, layer_goals: [...layerGoals, data]};
    },

    updateLayerGoal(state, data) {
      const layerGoals = state?.layer_goals?.filter((i: any) => i.id !== data.id) || [];
      return {...state, layer_goals: [...layerGoals, data]};
    },

    deleteLayerGoalFromState(state, data) {
      const layerGoals = state?.layer_goals?.filter((i: any) => i.id !== data);
      return {...state, layer_goals: layerGoals};
    },

    setDeletedLayerGoalId(state, data) {
      return { ...state, deletedLayerGoalId: data.id}
    },

  },
  effects: (dispatch) => ({

    async getRedLayer(layerId) {
      const request = await http.get(`${APPLICATION_URL}/red-layer/${layerId}`);
      if (request.status === 200) {
        dispatch.redLayer.setLayer(request.data);
        if (request?.data?.id) return true;
      }
      return false;
    },

    async createRedLayer(data: any) {
      const request = await http.post(`${APPLICATION_URL}/red-layer`, data);
      if (request.status === 201) {
        dispatch.redLayer.setLayer(request.data);
        dispatch.businessPlan.setBusinessPlanLayer({ ...data, layer: request.data });

        toast.success("Red Layer added!");
        history.push(history.location.pathname + "/red-layer/" + request.data.id);
      }
    },

    async deleteRedLayer({ id, bpId }) {
      const request = await http.delete(`${APPLICATION_URL}/red-layer`, {
        data: { id, layer_id: id }
      });
      if (request.status === 200) {
        dispatch.businessPlan.getBPs();
        dispatch.redLayer.setLayer({})
        toast.success("Red Layer deleted!");
      }
    },

    async saveLayerGoal(data: any, state) {
      const request = await http.post(`${APPLICATION_URL}/red-layer/goal`, {
        ...data,
        layer_id: state.redLayer.id,
      });
      if (request.status === 201) {
        if (data.id) {
          toast.success("Red Layer goal updated!");
        } else {
          toast.success("Red Layer goal created!");
        }
        dispatch.redLayer.updateLayerGoal(request.data);
      }
    },

    async deleteLayerGoal(id: string, state) {
      const request = await http.delete(`${APPLICATION_URL}/red-layer/goal`, {
        data: {
          layer_id: state.redLayer.id,
          layerGoalId: id
        }
      });
      if (request.status === 200) {
        toast.success("Red Layer goal deleted!");
        dispatch.redLayer.deleteLayerGoalFromState(id);
      }
    },

    async saveDeletedLayerGoalId(goalId: string) {
      dispatch.redLayer.setDeletedLayerGoalId({ id: goalId})
    }

  })
});

