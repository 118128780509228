import React from "react";

import { HomePage, NotFoundPage, ResetPasswordPage, SignInPage, SignUpPage } from "./pages";
import ApprovedMailPage from "./pages/ApprovedMailPage";
import BusinessPlanPage from "./pages/BusinessPlanPage";
import EmptyBusinessPlanPage from "./pages/EmptyBusinessPlanPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ProfilePage from "./pages/ProfilePage";
import RegisteredMailPage from "./pages/RegisteredMailPage";
import SignUpCompanyPage from "./pages/SignUpCompanyPage";
import UserRegisteredPage from "./pages/UserRegisteredPage";
import LayerPage from './pages/LayerPage';
import {
  APPROVED_MAIL_ROUTE,
  LAEYR_ROUTE,
  BUSINESS_PLAN_ID_ROUTE,
  COMPANY_INVITE_LINK,
  EMPTY_BUSINESS_PLAN_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  PROFILE_ROUTE,
  REGISTERED_MAIL_ROUTE,
  RESET_PASSWORD_ROUTE,
  SIGN_IN_ROUTE,
  SIGN_UP_COMPANY_ROUTE,
  USER_REGISTERED_ROUTE,
  RED_LAEYR_ROUTE,
} from "./store/constants/route-constants";
import RedLayerPage from "./pages/RedLayerPage";

interface Page {
  id: number;
  path: string;
  title: string;
  element: React.ReactElement;
  pageTitle?: string;
}

const routes: Page[] = [
  {
    id: 1,
    path: "/",
    title: "HomePage",
    element: <HomePage />,
  },
  // {
  //   id: 2,
  //   path: SIGN_UP_ROUTE,
  //   title: "SignUpPage",
  //   element: <SignUpPage />,
  // },
  {
    id: 3,
    path: SIGN_IN_ROUTE,
    title: "SignInPage",
    element: <SignInPage />,
  },
  {
    id: 4,
    path: RESET_PASSWORD_ROUTE,
    title: "ResetPasswordPage",
    element: <ResetPasswordPage />,
    pageTitle: "Nulstil kodeord"
  },
  {
    id: 5,
    path: SIGN_UP_COMPANY_ROUTE,
    title: "SignUpCompanyPage",
    element: <SignUpCompanyPage />,
  },
  {
    id: 6,
    path: FORGOT_PASSWORD_ROUTE,
    title: "ForgotPasswordPage",
    element: <ForgotPasswordPage />,
    pageTitle: "Glemt kodeord"
  },
  {
    id: 7,
    path: USER_REGISTERED_ROUTE,
    title: "UserRegisteredPage",
    element: <UserRegisteredPage />,
  },
  {
    id: 8,
    path: REGISTERED_MAIL_ROUTE,
    title: "RegisteredMailPage",
    element: <RegisteredMailPage />,
  },
  {
    id: 9,
    path: APPROVED_MAIL_ROUTE,
    title: "ApprovedMailPage",
    element: <ApprovedMailPage />,
  },
  {
    id: 10,
    path: BUSINESS_PLAN_ID_ROUTE,
    title: "BusinessPlanPage",
    element: <BusinessPlanPage />,
    pageTitle: "Lag"
  },
  {
    id: 11,
    path: EMPTY_BUSINESS_PLAN_ROUTE,
    title: "EmptyBusinessPlanPage",
    element: <EmptyBusinessPlanPage />,
    pageTitle: "Lag"
  },
  {
    id: 12,
    path: LAEYR_ROUTE,
    title: "BlueLayerPage",
    element: <LayerPage />,
  },
  {
    id: 13,
    path: LAEYR_ROUTE,
    title: "YellowLayerPage",
    element: <LayerPage />,
  },
  {
    id: 13,
    path: LAEYR_ROUTE,
    title: "GreenLayerPage",
    element: <LayerPage />,
  },
  {
    id: 14,
    path: RED_LAEYR_ROUTE,
    title: "RedLayerPage",
    element: <RedLayerPage />,
    pageTitle: "FNs 17 verdensmål",
  },
  {
    id: 22,
    path: PROFILE_ROUTE,
    title: "ProfilePage",
    element: <ProfilePage />,
    pageTitle: "Profil"
  },
  {
    id: 23,
    path: COMPANY_INVITE_LINK,
    title: "SignUpByLinkPage",
    element: <SignUpPage />,
  },
  {
    id: 34,
    path: '/404',
    title: "NotFoundPage",
    element: <NotFoundPage />,
    pageTitle: "404 fejl"
  },
];

export default routes;
