// users
export const SIGN_UP_URL = "auth/sign-up-by-token";
export const SIGN_UP_COMPANY_URL = "auth/registerCompany";
export const SIGN_IN_URL = "auth/signIn";
export const SIGN_OUT_URL = "auth/signOut";
export const FULL_SIGN_OUT_URL = "auth/fullSignOut";
export const REFRESH_TOKEN_URL = "auth/refresh";
export const RESET_PASSWORD_URL = "auth/resetPassword";
export const FORGOT_PASSWORD_URL = "auth/clear-password";
export const UPDATE_PROFILE_URL = "auth/updateProfile";
export const UPLOAD_PROFILE_IMAGE_URL = "auth/uploadProfileImage";
export const GET_COMPANY_SHARED_LINKS_URL = "/sharedLinks";
export const SEND_LINK_VIA_EMAIL_URL = "/sharedLinks/sendLinkViaEmail";
export const APPLICATION_URL = "application";
export const BUSINESS_PLAN_ROUTE = "business-plans";
export const REQUEST_PERMISSION_FOR_LAYER_URL = `${APPLICATION_URL}/${BUSINESS_PLAN_ROUTE}/create-permission-request`;
export const APPLICATION_BUSINESS_PLAN_URL = `${APPLICATION_URL}/${BUSINESS_PLAN_ROUTE}`;
export const APPLICATION_LAYER_URL = APPLICATION_URL + "/layer";

export const IS_VALID_LINK_URL = "sharedLinks/is-valid-token";
export const GET_USERS_BY_COMPANY_ID_URL = APPLICATION_URL + "/companies/get-users";
export const DELETE_USER_ACCESS_URL = APPLICATION_URL + "/companies/delete-access";
export const DELETE_USER_URL = APPLICATION_URL + '/companies/delete-user'

export const USER_URL = "auth/info";