// @ts-nocheck
import React, { FormEvent, SyntheticEvent, Suspense } from "react";
import { Modal } from "../../Modal";
import LeftComponent from "./LeftComponent";
const RightComponent = React.lazy(() => import('./RightComponent'));

export interface Props {
  showModal: boolean;
  leftTitle: string;
  rightTitle: string;
  setShowModal?: (e: SyntheticEvent) => void;
  leftInputLabelOne: string,
  leftInputLabelTwo: string,
  rightInputLabel: string,
  body: string;
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
  closeModal?: () => void;
  submitDelete?: () => void;
  cancel?: () => void;
  rightBody: string;
}

export const ReportModal: React.FC<Props> = ({ showModal, ...props }: Props) => {

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Modal
        LeftComponent={LeftComponent}
        RightComponent={RightComponent}
        rightcomponentbackgroundcolor={"bg-gray-100"}
        showModal={showModal}
        innerPadding="p-0"
        {...props}
      />
    </Suspense>
  );
};

