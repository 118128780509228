import React, { FormEvent, SyntheticEvent } from "react";
import { Modal } from "../core/Modal";
import { LeftComponent } from "./LeftComponent";
import RightComponent from "./RightComponent";

export interface Props {
  showModal: boolean;
  title: string;
  introduction: string;
  body: string;
  listtitle: string;
  liitems: string[] | null;
  inputLabel: string;
  textareaLabel: string;
  SubmitButtonText: string;
  FormSubmitBtnDisabled: boolean;
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
  setShowModal?: (e: SyntheticEvent) => void;
  data: any;
}

export const GoalModal: React.FC<Props> = ({ showModal, ...props }: Props) => {
  return (
    <>
      <Modal
        LeftComponent={LeftComponent}
        RightComponent={RightComponent}
        rightcomponentbackgroundcolor={"bg-gray-100"}
        showModal={showModal}
        {...props}
      />
    </>
  );
};
