import { Models } from "@rematch/core";

import { businessPlan } from "./businessPlan";
import { layer } from "./layer";
import { redLayer } from "./redLayer";
import { user } from "./user";

export interface RootModel extends Models<RootModel> {
  user: typeof user;
  businessPlan: typeof businessPlan;
  layer: typeof layer;
  redLayer: typeof redLayer;
}

export const models: RootModel = {
  user,
  businessPlan,
  layer,
  redLayer
};
