import React, { FormEvent } from "react";

import { FORM_BUTTONS, INPUTS } from "../../../../constants/ui";
import { CustomizedFormButton } from "../../buttons/CustomizedFormButton";
import { CustomizedInput } from "../../inputs/CustomizedInput";
import { CustomizedTextarea } from "../../textarea/CustomizedTextarea";

export interface Props {
  inputLabel: string;
  textareaLabel: string;
  SubmitButtonText: string;
  FormSubmitBtnDisabled: boolean;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  data: any;
}

export const RightComponent: React.FC<Props> = ({
  inputLabel,
  textareaLabel,
  SubmitButtonText,
  FormSubmitBtnDisabled,
  onSubmit,
  data,
  ...props
}: Props) => {
  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="mb-6">
          <div className="text-2xl mb-2 flex">
            <label className="font-quickStand font-medium text-26 leading-32">
              {inputLabel}
            </label>
          </div>
          <CustomizedInput
            autoFocus
            defaultValue={data.name}
            placeHolder={""}
            disabled={false}
            styleType={INPUTS.DEFAULT_HALF_WIDTH}
            {...props}
          />
        </div>
        <div className="mb-6">
          <div className="text-2xl mb-2 flex">
            <label className="font-quickStand font-medium text-26 leading-32">
              {textareaLabel}
            </label>
          </div>
          <CustomizedTextarea
            defaultValue={data.description}
            placeHolder={""}
            disabled={false}
            type={INPUTS.DEFAULT_FULL_WIDTH}
            {...props}
          />
        </div>
        <div className="">
          <div className="text-2xl flex">
            <CustomizedFormButton
              text={SubmitButtonText}
              btn_type={FORM_BUTTONS.PRIMARY}
              disabled={FormSubmitBtnDisabled}
              type="submit"
              {...props}
            />
          </div>
        </div>
      </form>
    </>
  );
};
