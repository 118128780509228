import React, { FC } from 'react'
import { connect } from "react-redux";
import smallPlusIcon from "../../assets/icons/small-plus.png";
import { RootState } from "../../store/store";

interface IGoalCategoryDetailModal {
  isEditor: boolean;
  data: any;
  openGoalModal: (data: any) => void;
}

const GoalCategoryDetailModal: FC<IGoalCategoryDetailModal> = ({ isEditor, data, openGoalModal }) => {
  const { title, description, youtube_url, link } = data;
  return (
    <div className="absolute flex justify-between w-[48%] mt-[350px] left-4 my-1">
      <div className="w-3/5 p-2">
        <p className="text-3-xl font-bold">{title}</p>
        <div className="text-sm my-3 overflow-hidden max-h-[78px] goal-wrap">{description}</div>
        <div className="flex justify-between w-full">
          <a className="" target="blank" href={link || ""}>Læs mere</a>
          {isEditor && (
            <div className="text-1xl font-bold flex justify-end items-center">
              <p>Tilføj</p>
              <div
                onClick={openGoalModal.bind(null, data)}
                style={{ background: "#E44F4C" }}
                className="ml-2 cursor-pointer w-9 h-9 flex items-center justify-center rounded-full"
              >
                <img src={smallPlusIcon} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-2/5">
        <iframe className="h-full w-full rounded-md" src={youtube_url} title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
      </div>
    </div>
  )
}

const mapState = (state: RootState) => ({
  isEditor: state.user.isEditor,
});

export default connect(mapState)(GoalCategoryDetailModal);
