import React, { useState } from "react";
import { GOAL_ICONS } from "../core/icons/goals";

export interface Props {
  title: string;
  introduction: string;
  body: string;
  listtitle: string;
  liitems: string[] | null;
  data: any;
}

const getGoalData = (goalId: string, goals: any[]) => {
  const goal = goals.find((g: any) => g.id === goalId);
  return {
    title: goal?.title || '',
    description: goal?.description || '',
    icon: GOAL_ICONS[goal?.icon]
  }
}

export const LeftComponent: React.FC<Props> = ({
  title,
  introduction,
  listtitle,
  liitems,
  data,
  ...props
}: Props) => {
  const [goal, setGoal] = useState<any>({
    selecteds: data?.selectedGoals || [],
    ...getGoalData(data?.selectedGoals?.at(0), data.goals)
  });

  const onGoalSelect = (goalData: any) => {
    let selecteds = goal.selecteds;
    const exist = selecteds?.includes(goalData.id);
    if (exist) selecteds = selecteds.filter((i: string) => i !== goalData.id)
    else selecteds = goalData.id;
    setGoal({...goalData, selecteds});
  }

  const renderGoals = (goalObj: any) => {
    const GoalIcon = GOAL_ICONS[goalObj.icon];
    const isActive = goal.selecteds.includes(goalObj.id);
    return <div
        className={`overflow-hidden w-[90px] h-[90px] flex items-center drop-shadow-lg rounded-xl ${goal?.id === goalObj?.id ? "border-4 h-fit rounded-[14px] border-[#3aadda]" : "border-4 border-[transparent]"}`}
        data-selected={isActive ? 1 : 0}
        data-id={goalObj?.id?.toString() || ""}
        key={goalObj.id}
        onClick={onGoalSelect.bind(null, {...goalObj, icon: GoalIcon})}
      >
      {GoalIcon ? <GoalIcon isActive={isActive} /> : null}
    </div>
  }

  return (
    <div className="">
      <div className="font-mediumPlusPlus text-4xl leading-tight">
        {title}
      </div>
      <div className="my-4 font-bold">{introduction}</div>
      <div className="goal-wrap gap-3 flex flex-wrap h-[220px] overflow-auto mb-12">
        {data?.goals?.length ? data.goals.map(renderGoals) : "No goals"}
      </div>
      <div className="flex items-start justify-between mt-4">
        <div className="w-[75%]">
          <div className="mb-1 font-bold">{goal?.title || ""}</div>
          <div className="text-1xl overflow-auto">{goal?.description || ""}</div>
        </div>
        {goal?.icon ? <div className="overflow-hidden drop-shadow-lg w-[85px] h-[85px] flex items-center rounded-xl">{goal?.icon && <goal.icon isActive={true} />}</div> : ""}
      </div>
    </div>
  );
};
