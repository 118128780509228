export const SHARE_ACCESS_MODAL_DATA = {
  leftTitle: "Redigering og læsning",
  rightTitle: "Del dit arbejde",
  introduction:
    "Del dit arbejde med andre. Hvis du deler med administrator adgang vil brugeren have mulighed for at ændre, slette og tilføje i dine forretningsplaner. Deler du som læsning, vil brugeren kun have adgang til at se dit indhold, men vil ikke kunne lave nogen ændringer.",
  body: " Samtidig bør du tænke grundigt over, hvilke kanaler kunden foretrækker at benytte, hvilke du bruger i øjeblikket, hvilke der virker bedst og hvilke der giver økonomisk mening. Kanaler kan være både fysiske og digitale, hvor du kan få et godt overblik gennem PESO modellen.",
  liItems: [
    "Jeg vil nå mine kunder gennem (kanaler, medier, SoMe)…",
    "jeg vil optimere mine kunders oplevelse ved at…",
    "Mine hoved salgstale er…",
    "Jeg vil anvende følgende marketing mix…",
  ],
  leftInputLabelOne: "Administratorer",
  leftInputLabelTwo: "Læsning",
  rightInputLabel: "Delingslink",
  listtitle: "Følgende spørgsmål kan hjælpe dit arbejde:",
  inputLabel: "Overskrift",
  textareaLabel: "Beskrivelse",
  SubmitButtonText: "Gem",
};

export const SHARE_CONFIRM_MODAL_DATA = {
  title: "Er du sikker på at du vil slette brugeren?",
  body: "Du er ved at slette din bruger. Er du den eneste administrator af forretningplanerne slettes alt indhold.",
  redBtnLabel: "Slet",
  greenBtnLabel: "Behold",
}