import React from "react";

export interface Props {
  isActive: boolean;
}

export const IconThreeTen: React.FC<Props> = ({ isActive = false }: Props) => {
  return (
    <>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 170.08 170.08" style={{ ['enableBackground' as any]: 'new 0 0 170.08 170.08', 'filter': 'none' }} xmlSpace="preserve">
        <style type="text/css">{`
          svg:hover #SVGID_1_13{fill:#407f46;}
          .st1{clip-path:url(#SVGID_00000128444070364876091130000013904325232241701542_);fill:#FFFFFF;}
          .st2{fill:#FFFFFF;}
        `}</style>
        <rect className="st0" width="170.08" height="170.08" />
        <g>
          <defs>
            <rect id="SVGID_1_13" y="0" width="170.08" height="170.08" />
          </defs>
          <use xlinkHref="#SVGID_1_13" style={{ 'overflow': 'visible', 'fill': isActive ? '#407f46' : '#B2B2B2' }} />
          <clipPath id="SVGID_00000128444070364876091130000013904325232241701542_">
            <use xlinkHref="#SVGID_1_13" style={{ 'overflow': 'visible' }} />
          </clipPath>
          <path className="st1" d="M40.48,21.7v2.98
		c0,2.88-1.57,4.8-3.94,5.56c2.88,0.66,4.25,2.68,4.25,5.81v5.06c0,4.75-2.53,7.84-8.34,7.84c-5.87,0-7.94-3.34-7.94-8.54v-4.5h5.26
		v4.91c0,2.02,0.71,3.33,2.68,3.33c1.77,0,2.58-1.26,2.58-3.08V35.4c0-1.82-0.81-2.78-2.83-2.78h-2.43v-4.4h2.17
		c1.87,0,3.08-0.96,3.08-2.78v-3.69c0-1.87-0.65-3.08-2.47-3.08c-1.82,0-2.48,1.21-2.48,3.34v3.49H24.8v-3.03
		c0-5.06,2.08-8.6,8.04-8.6C38.15,13.86,40.48,16.85,40.48,21.7"/>
          <path className="st1" d="M148.74,106.48
		c-4.48-6.29-24.75-31.38-62.43-31.38c-37.68,0-57.95,25.09-62.44,31.38c-0.19,0.27-0.36,0.5-0.49,0.7c0.13,0.2,0.3,0.43,0.49,0.71
		c4.49,6.28,24.75,31.38,62.44,31.38c37.68,0,57.95-25.09,62.43-31.38c0.2-0.27,0.36-0.5,0.49-0.71
		C149.1,106.98,148.93,106.74,148.74,106.48 M65.29,90.97c0.34-0.5,1.47-1.92,1.6-2.1c0.29-0.37,0.87-0.45,1.27-0.52
		c0.4-0.08,0.93,0.15,1,0.45c0.07,0.3-0.15,1.47-0.35,1.67c-0.55,0.57-1.99,1.38-2.78,1.26C65.42,91.62,65.17,91.15,65.29,90.97
		 M63.95,93.64c0.06-0.07,0.11-0.13,0.14-0.16c0.47-0.54,1.42-0.66,2.07-0.76c0.65-0.11,1.52,0.22,1.63,0.65
		c0.11,0.43-1.64,1.22-1.96,1.52c-0.91,0.84-2.33,2.54-3.15,2.72c-0.27,0.06-0.49,0.02-0.68-0.07
		C62.54,96.19,63.19,94.88,63.95,93.64 M78.05,122.57c-0.51,2.01-1.11,2.73-1.61,3.36c-0.51,0.64-2.39,1.46-3.58,1.37
		c-1.19-0.09-1.36-3.27-1.36-4.91c0-0.57-0.8,1.11-1.22-2.3c-0.27-2.18-1.67,0.23-1.77-1.23c-0.1-1.45-0.8-1.16-1.54-2.07
		c-0.73-0.91-1.65,0.09-2.67,0.55c-1.02,0.45-0.6,0.69-2.05,0.24c-0.04-0.01-0.06-0.02-0.09-0.04c-1.33-3.14-2.06-6.59-2.06-10.22
		c0-0.89,0.05-1.76,0.13-2.62c0.05-0.09,0.1-0.17,0.16-0.24c1.39-1.64,1.36-2.03,1.36-2.03l1.54-0.53c0,0,2.45,0.38,4.3,1.07
		c1.52,0.57,3.92,1.86,5.31,1.29c1.01-0.42,1.8-0.97,1.7-1.81c-0.11-1.05-0.94-1.3-1.95-0.59c-0.37,0.26-1.16-1.59-1.78-1.49
		c-0.62,0.09,0.76,2.17,0.24,2.26c-0.53,0.09-1.58-2.17-1.76-2.53c-0.18-0.36-0.99-1.27-1.73-0.58c-1.55,1.47-4.06,1.38-4.57,1.76
		c-1.54,1.15-1.94-0.38-1.91-1.31c0.06-0.21,0.12-0.43,0.19-0.64c0.5-0.55,2.3-0.74,2.92-0.32c0.68,0.45,1.17-0.12,1.43-0.39
		c0.27-0.27,0.17-0.7,0.01-1.07c-0.17-0.36,0.34-0.63,0.69-0.72c0.36-0.09,1.52-0.91,1.99-1.19c0.47-0.27,2.9-0.56,3.99-0.02
		c1.09,0.55,2.09,0.1,2.62-0.53c0.67-0.79,1.53-1.4,1.9-1.59c0.38-0.18-0.94-1.41-2.02-0.07c-1.09,1.33-1.64,1.46-2.15,1.46
		c-0.51,0-3.54-0.76-3.91-1.71c-0.37-0.96,0.95-1.67,2.07-2.21c1.12-0.53,4.18-0.53,4.96-0.7c1.59-0.35,3.62-1.15,4.65-0.45
		c1.02,0.68-4.28,1.89-2.54,2.83c0.62,0.34,3.42-1.61,4.38-2.11c2.26-1.16-2.19-1.55-1.61-3.92c0.65-2.67-5.45-1.64-8.5-1.41
		c4.08-2.62,8.93-4.14,14.14-4.14c12.38,0,22.76,8.58,25.52,20.11c0.02,0.57-0.07,0.98-0.13,1.21c-0.24,0.95-2.03,1.76-3.07,0.24
		c-0.27-0.38-1.39-1.45-1.35,0.37c0.04,1.82,1.77,2.01,0.09,3.1c-1.68,1.09-1.98,0.83-2.76,1.1c-0.77,0.27-1.52,2.15-1.34,3.15
		c0.19,1,2.58,3.74,0.81,3.63c-0.42-0.03-3.55-2.17-4.07-3.55c-0.55-1.45-1.14-2.87-1.57-3.96c-0.43-1.09-1.54-1.37-2.47-0.73
		c-0.93,0.64,0.26,1.28-0.41,2.64c-0.67,1.37-1.15,2.58-2.13,1.46c-2.56-2.94-6.29-1.49-6.87-4.75c-0.32-1.77-1.84-1.6-3.11-2.6
		c-1.28-1-2-1.54-2.21-0.82c-0.19,0.63,4.01,3.96,4.31,4.82c0.45,1.27-1.26,2.39-2.51,2.69c-1.24,0.3-1.79-0.7-3.01-1.97
		c-1.22-1.27-1.76-2.18-1.84-1.18c-0.12,1.44,1.16,3.56,2.41,4.36c1.06,0.67,2.25,0.98,1.91,2.07c-0.35,1.09-0.01,0.48-1.2,1.65
		c-1.19,1.18-1.42,1.56-1.33,3.2c0.09,1.64-0.34,1.73-0.6,3.1C79.15,121.75,78.56,120.57,78.05,122.57 M106.21,112.1
		c0.88-0.07,2.21,1.15,1.69,1.62c-0.52,0.46-1.84,0.29-2.5-0.22C104.73,112.99,105.81,112.13,106.21,112.1 M105.09,111.3
		c-0.28-0.11-0.53-0.37-0.75-1.02c-0.21-0.64,0.33-1.01,0.86-0.75c0.54,0.27,1.24,1.08,1.24,1.51
		C106.44,111.46,105.38,111.41,105.09,111.3 M93.51,111.25c-0.52,0.46-1.85,0.29-2.51-0.23c-0.66-0.51,0.42-1.37,0.81-1.4
		C92.69,109.55,94.02,110.78,93.51,111.25 M82.79,116.46c-0.36,0.59-1.35,5-2.01,4.49c-0.66-0.52,0.27-5.12,0.46-5.47
		C82.27,113.58,84.51,113.68,82.79,116.46 M86.35,133.57c-2.56,0-5.04-0.37-7.38-1.06c-0.34-0.27-0.53-0.73-0.32-1.28
		c0.81-2.12,3.23-2.95,5.14-3.13c4.26-0.41,9.85,2.76,10.74,2.25c0.89-0.51,2.69-2.85,5.33-2.75c1.8,0.07,1.79,0.37,2.39,0.6
		C97.85,131.56,92.34,133.57,86.35,133.57 M104.87,125.93c-0.26-0.07-0.53-0.16-0.83-0.3c-1.43-0.73-1.26,0.44-1.97,0.18
		c-0.71-0.26,0.8-1.9-1.32-0.51c-2.11,1.38-1.54-0.21-2.46-2.55c-0.93-2.34,1.06-5.01,2.72-5.61c2.28-0.83,3.7-0.99,4.77-1.26
		c3.15-0.79,3.82-2.66,4.39-1.52c0.11,0.21,0.21,0.42,0.31,0.61c0.18,0.34,0.4,1.03,0.41,1.71
		C109.55,120.18,107.48,123.33,104.87,125.93 M112.06,112.68c-0.7-0.19-3.65-4.27-1.32-6.95c0.39-0.44-0.78-1.74-0.46-1.76
		c1.27-0.1,1.95,0.69,2.29,1.83c0.03,0.5,0.05,1.01,0.05,1.52C112.61,109.16,112.41,110.95,112.06,112.68"/>
        </g>
        <polygon className="st2" points="59.03,28.15 61.56,28.15 58.72,20.29 61.5,13.86 59.14,13.86 56.52,20.14 56.52,13.86 54.12,13.86
	54.12,28.15 56.52,28.15 56.52,21.19 "/>
        <polygon className="st2" points="62.47,28.15 67.74,28.15 67.74,26.19 64.88,26.19 64.88,13.86 62.47,13.86 " />
        <rect x="68.84" y="13.86" className="st2" width="2.4" height="14.3" />
        <polygon className="st2" points="80.52,28.15 82.72,28.15 82.72,13.86 79.68,13.86 78.29,21.91 78.27,21.91 76.71,13.86 73.9,13.86
	73.9,28.15 75.88,28.15 75.88,18.87 75.93,18.87 77.72,28.15 78.84,28.15 80.46,18.87 80.52,18.87 "/>
        <path className="st2" d="M87.67,17.88h0.02l0.95,5.74h-1.92L87.67,17.88z M85.99,28.15l0.42-2.62h2.55l0.44,2.62h2.47l-2.72-14.3h-2.74
	l-2.7,14.3H85.99z"/>
        <rect x="92.08" y="20.54" className="st2" width="3.37" height="1.94" />
        <rect x="54.12" y="34.27" className="st2" width="2.4" height="14.3" />
        <polygon className="st2" points="63.61,48.57 65.72,48.57 65.72,34.27 63.72,34.27 63.72,42.47 61.12,34.27 58.76,34.27 58.76,48.57
	60.78,48.57 60.78,39.58 "/>
        <g>
          <path className="st2" d="M71.22,34.27h-3.71v14.3h3.71c2.57,0,3.48-1.64,3.48-3.84v-6.62C74.7,35.89,73.8,34.27,71.22,34.27
		 M69.92,46.61V36.23h1.01c0.99,0,1.37,0.59,1.37,1.43v7.49c0,0.87-0.38,1.46-1.37,1.46H69.92z"/>
          <path className="st2" d="M79.76,48.74c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.67-1.14-1.14-1.14-1.94v-0.3c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.49c0,1.64,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.02
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96h-2.24v1.92C76.37,47.37,77.32,48.74,79.76,48.74"/>
          <path className="st2" d="M87.9,38.3h0.02l0.95,5.74h-1.92L87.9,38.3z M86.22,48.57l0.42-2.62h2.55l0.44,2.62h2.47l-2.72-14.3h-2.74
		l-2.7,14.3H86.22z"/>
          <polygon className="st2" points="91.76,36.23 93.75,36.23 93.75,48.57 96.17,48.57 96.17,36.23 98.15,36.23 98.15,34.27 91.76,34.27
		"/>
          <path className="st2" d="M102.33,48.74c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.67-1.14-1.14-1.14-1.94v-0.3c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.49c0,1.64,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.02
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96h-2.24v1.92C98.93,47.37,99.88,48.74,102.33,48.74"/>
        </g>
        <polygon className="st2" points="21.47,14.27 21.47,48.54 15.7,48.54 15.7,22.31 15.65,22.31 11.61,26.5 11.56,26.5 11.56,20.49
	16.87,14.27 "/>
      </svg>
    </>
  );
};
