import React, { useMemo } from "react";

import { SELECT_TYPES } from "../../../constants/ui";
import { Props as SelectProps, Select, SelectOptionProps } from "../Select";

export interface Props {
  optionList: SelectOptionProps[];
  type: typeof SELECT_TYPES[keyof typeof SELECT_TYPES];
  selectedOption?: SelectOptionProps[];
  onChange?: (e: any) => void;
  hidden?: boolean;
  isMulti?: boolean;
  placeHolder?: string;
  backgroundColor?: string;
}

export const CustomizedSelect: React.FC<Props> = ({
  type = SELECT_TYPES.LIGHT_BLUE,
  optionList,
  ...props
}: Props) => {
  const args = useMemo(() => {
    const commons = {
      optionList,
      ...props,
    };
    switch (type) {
      case SELECT_TYPES.GREEN:
        return {
          backgroundColor: "#5AB478",
          ...commons,
        };
      case SELECT_TYPES.YELLOW:
        return {
          backgroundColor: "#FAB900",
          ...commons,
        };
      case SELECT_TYPES.GRAY:
        return {
          backgroundColor: "#e9e9e9",
          ...commons,
        };
      default:
        return {
          backgroundColor: "#ffffff21",
          ...commons,
        };
    }
  }, []);
  return (
    <>
      <Select {...args} />
    </>
  );
};
