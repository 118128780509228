export const REPORT_MODAL_DATA = {
  leftTitle: "Jeres bæredygtighedsrapport",
  rightTitle: "Eksportér",
  body: `Generér bæredygtighedsrapport ved at eksportere den som wordfil i enten liggende eller stående format. Indholdet på platformen vil blive eksporteret i rapporten sammen med standard indhold, men husk virksomheden selv er ansvarlige for indholdet i hele rapporten.  \n
Husk at kigge rapporten grundigt igennem inden den sendes videre til tredjepart eller publiceres på sociale medier.`,
  rightBody: `Rapporten blive eksporteret som Word-fil. Du kan herefter åbne den, lave rettelser og eksportere til PDF`,
  // liItems: [
  //   "Jeg vil nå mine kunder gennem (kanaler, medier, SoMe)…",
  //   "jeg vil optimere mine kunders oplevelse ved at…",
  //   "Mine hoved salgstale er…",
  //   "Jeg vil anvende følgende marketing mix…",
  // ],
  leftInputLabelOne: "Administratorer",
  leftInputLabelTwo: "Læsning",
  rightInputLabel: "Format",
  listtitle: "Følgende spørgsmål kan hjælpe dit arbejde:",
  inputLabel: "Overskrift",
  textareaLabel: "Beskrivelse",
  SubmitButtonText: "Luk",
};