import React, { InputHTMLAttributes, useMemo } from "react";

import { INPUTS } from "../../../constants/ui";
import { Input, Props as InputProps } from "../Input";

export interface Props extends InputProps {
  placeHolder: string;
  styleType: typeof INPUTS[keyof typeof INPUTS];
  disabled: boolean;
}

export const CustomizedInput: React.FC<Props> = ({
  placeHolder,
  styleType = INPUTS.DEFAULT_FULL_WIDTH,
  disabled = false,
  ...props
}: Props) => {
  const getArgs = () => {
    const commons = {
      ...props,
      placeHolder,
      disabled,
      height: "h-82",
      fontSize: "text-26",
      fontWeight: "font-medium",
      paddingX: "p-21",
      width: "w-full",
      fontFamily: "font-quickStand",
      borderSize: "border-2",
    };
    switch (styleType) {
      case INPUTS.DEFAULT_HALF_WIDTH:
        return {
          backgroundColor: "bg-gray-150",
          borderColor: "outline-gray-500",
          textColor: "text-black-900",
          ...commons,
        };
      case INPUTS.MEDIUM_LIGHT_BLUE:
        return {
          backgroundColor: "bg-[#ffffff21]",
          borderColor: "outline-[#0000001f]",
          textColor: "text-white",
          placeHolderColor: "placeholder-white",
          ...commons,
        };
      case INPUTS.MEDIUM_LIGHT_GREEN:
        return {
          backgroundColor: "bg-green-200",
          borderColor: "outline-green-200",
          textColor: "text-white",
          placeHolderColor: "placeholder-white",
          ...commons,
        };
      case INPUTS.MEDIUM_LIGHT_YELLOW:
        return {
          backgroundColor: "bg-yellow-200",
          borderColor: "outline-yellow-200",
          textColor: "text-white",
          placeHolderColor: "placeholder-white",
          ...commons,
        };
      case INPUTS.ERROR:
        return {
          backgroundColor: "bg-gray-150",
          borderColor: "border-[#FF0E0E]",
          textColor: "text-red",
          placeHolderColor: "placeholder-red",
          ...commons,
        };
      default:
        return {
          backgroundColor: "bg-gray-150",
          borderColor: "outline-gray-500",
          textColor: "text-black-900",
          ...commons,
        };
    }
  };
  const args = getArgs();
  return (
    <>
      <Input {...args} />
    </>
  );
};
