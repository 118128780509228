import React from "react";

export interface Props {
  title: string;
  introduction: string;
}

export const LeftComponent: React.FC<Props> = ({
  title,
  introduction,
  ...props
}: Props) => {
  return (
    <>
      <style>{"\
        ol {\
          padding-left: 1rem;\
        }\
      "}</style>
      <div className="text-[32px] font-quickStand font-mediumPlusPlus leading-58 mb-6">
        {title}
      </div>
      <div className="font-quickStand overflow-auto max-h-[69vh]" dangerouslySetInnerHTML={{ __html: introduction }} />
    </>
  );
};
