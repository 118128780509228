import {   RED_LAEYR_ROUTE, SIGN_UP_ROUTE } from "../store/constants/route-constants";

export enum LAYER {
  BLUE_LAYER = "blue_layer",
  GREEN_LAYER = "green_layer",
  YELLOW_LAEYR = "yellow_layer",
  RED_LAEYR = "red_layer",
}
export interface IBPLAYER {
  idx: string;
  title: string;
  color: string;
  modalColor: string;
  type: LAYER;
  route: string;
  hexColor: string;
}

export const BP_LAYERS: IBPLAYER[] = [
  {
    idx: "1",
    title: "Det økonomiske lag",
    color: "bg-blueLayer",
    modalColor: "#1999CC",
    type: LAYER.BLUE_LAYER,
    hexColor: "#37B4E6",
    route: SIGN_UP_ROUTE,
  },
  {
    idx: "2",
    title: "Det sociale lag",
    color: "bg-yellowLayer",
    modalColor: "#E5AA00",
    hexColor: "#FAB900",
    type: LAYER.YELLOW_LAEYR,
    route: SIGN_UP_ROUTE,
  },
  {
    idx: "3",
    title: "Det miljømæssige lag",
    color: "bg-greenLayer",
    modalColor: "#4E9F69",
    hexColor: "#5AB478",
    type: LAYER.GREEN_LAYER,
    route: SIGN_UP_ROUTE,
  },
  {
    idx: "4",
    title: "FNs 17 verdensmål",
    color: "bg-redLayer",
    modalColor: "",
    hexColor: "#E44F4C",
    type: LAYER.RED_LAEYR,
    route: RED_LAEYR_ROUTE,
  },
];

export const getLyaerRouteByType = (type: string): string => {
  const route = BP_LAYERS.find((l) => l.type === type)?.route || '';
  return route;
}

export const getCardColorByType = (type: string): string => {
  const color = BP_LAYERS.find((l) => l.type === type)?.color || '';
  return color;
}

export const getHexCardColorByType = (type: string): string => {
  const color = BP_LAYERS.find((l) => l.type === type)?.hexColor || '';
  return color;
}

export const getModalColorByType = (type: string): string => {
  const color = BP_LAYERS.find((l) => l.type === type)?.modalColor || '';
  return color;
}

export const getModalColor = (colorStr: string): string => {
  const color = BP_LAYERS.find((l) => l.color === colorStr)?.modalColor || '';
  return color;
}

export const LAYER_GRID_VALUES = [
  {style: "box row-start-1 row-end-3 col-start-1 col-end-3"},
  {style: "box row-start-1 row-end-1 col-start-3 col-end-5"},
  {style: "box row-start-2 row-end-2 col-start-3 col-end-5"},
  {style: "box row-start-1 row-end-3 col-start-5 col-end-7"},
  {style: "box row-start-1 row-end-1 col-start-7 col-end-9"},
  {style: "box row-start-2 row-end-2 col-start-7 col-end-9"},
  {style: "box row-start-1 row-end-3 col-start-9 col-end-11"},
  {style: "box row-start-3 row-end-3 col-start-1 col-end-6"},
  {style: "box row-start-3 row-end-3 col-start-6 col-end-11"},
];


