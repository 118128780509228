import classnames from "classnames";
import React, { TextareaHTMLAttributes, useMemo } from "react";

export interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  placeHolder: string;
  placeHolderColor?: string;
  backgroundColor?: string;
  textColor?: string;
  disabled: boolean;
  borderRadius?: string;
  borderSize?: string;
  borderColor?: string;
  paddingX?: string;
  paddingL?: string;
  paddingR?: string;
  paddingY?: string;
  paddingT?: string;
  paddingB?: string;
  width?: string;
  height?: string;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: string;
  maxHeight?: string;
}

export const Textarea: React.FC<Props> = ({
  placeHolder = "Enter",
  placeHolderColor,
  backgroundColor,
  textColor = "text-white",
  disabled = false,
  borderRadius = "rounded-10",
  borderSize,
  borderColor,
  paddingX,
  paddingL,
  paddingR,
  paddingY,
  paddingT,
  paddingB,
  width,
  height,
  fontFamily,
  fontSize,
  fontWeight,
  maxHeight,
  ...props
}: Props) => {
  const classList = () => {
    const list = [
      backgroundColor,
      placeHolderColor,
      textColor,
      borderRadius,
      borderSize,
      borderColor,
      paddingX,
      paddingL,
      paddingR,
      paddingY,
      paddingT,
      paddingB,
      width,
      height,
      fontFamily,
      fontSize,
      fontWeight,
      maxHeight,
    ];
    return list
      .filter((i) => !["undefined", "null", "true", "false"].includes(`${i}`))
      .join(" ");
  };
  return (
    <>
      <textarea
        {...props}
        className={`${classnames(`${classList()}`, {
          "cursor-not-allowed": disabled,
        })} border-0 outline-0`}
        placeholder={placeHolder}
      ></textarea>
    </>
  );
};
