import React, {FormEvent, SyntheticEvent } from "react";
import { Modal } from "../../Modal";
import LeftComponent from "./LeftComponent";
import { RightComponent } from "./RightComponent";

export interface Props {
  showModal: boolean;
  leftTitle: string;
  rightTitle: string;
  closeModalTwo: (e: SyntheticEvent) => void;
  setShowModal?: (e: SyntheticEvent) => void;
  leftInputLabelOne: string,
  leftInputLabelTwo: string,
  rightInputLabel: string,
  body: string;
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
  closeModal?: (e: SyntheticEvent) => void;
  submitDelete?: () => void;
  cancel?: () => void;
}

export const ShareAccessModal: React.FC<Props> = ({showModal , closeModal, ...props}: Props) => {

  return (
    <Modal
      LeftComponent={LeftComponent}
      RightComponent={RightComponent}
      rightcomponentbackgroundcolor={"bg-gray-100"}
      showModal={showModal}
      innerPadding="p-0"
      {...props}
    />
  );
};

