import React from "react";

export interface Props {
  isActive: boolean;
}

export const IconEleven: React.FC<Props> = ({ isActive = false }: Props) => {
  return (
    <>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 170.08 170.08" style={{ ['enableBackground' as any]: 'new 0 0 170.08 170.08', 'filter': 'none' }} xmlSpace="preserve">
        <style type="text/css">{`
            .st10{fill:${isActive ? '#f89d2a' : '#B2B2B2'};}
          svg:hover #SVGID_1_11{fill:#f89d2a;}
          .st1{clip-path:url(#SVGID_00000004508476681659159330000013369167686466703241_);fill:#FFFFFF;}
          .st2{fill:#FFFFFF;}
        `}</style>
        <rect className="st0" width="170.08" height="170.08" />
        <g>
          <defs>
            <rect id="SVGID_1_11" x="0.01" y="0" width="170.07" height="170.07" />
          </defs>
          <use xlinkHref="#SVGID_1_11" style={{ 'overflow': 'visible', 'fill': isActive ? '#f89d2a' : '#B2B2B2' }} />
          <clipPath id="SVGID_00000004508476681659159330000013369167686466703241_">
            <use xlinkHref="#SVGID_1_11" style={{ 'overflow': 'visible' }} />
          </clipPath>
          <path className="st1" d="M29.26,129.49h0.26
		h25.32c0.35,0,0.63-0.28,0.63-0.63c0-0.16-0.07-0.31-0.17-0.42l-0.03-0.04l-12.73-16.47l-0.12-0.14c-0.12-0.13-0.28-0.2-0.46-0.2
		c-0.16,0-0.3,0.07-0.41,0.16l-0.08,0.09l-0.12,0.13l-12.42,16.3l-0.19,0.23c-0.07,0.1-0.11,0.22-0.11,0.35
		C28.64,129.2,28.92,129.49,29.26,129.49"/>
          <path className="st1" d="M54.77,131.81H29.14
		c-0.35,0-0.63,0.28-0.63,0.63v20.06c0,0.35,0.28,0.63,0.63,0.63h8.25v-12.62c0-0.35,0.28-0.63,0.63-0.63h7.66
		c0.35,0,0.63,0.28,0.63,0.63v12.62h8.47c0.35,0,0.63-0.28,0.63-0.63v-20.06C55.4,132.09,55.12,131.81,54.77,131.81"/>
          <path className="st1" d="M143.36,126.56h-25.37
		c-0.34,0-0.62,0.27-0.62,0.6v25.62c0,0.33,0.28,0.6,0.62,0.6h25.37c0.34,0,0.62-0.27,0.62-0.6v-25.62
		C143.99,126.82,143.71,126.56,143.36,126.56 M129.48,143.28c0,0.34-0.28,0.61-0.62,0.61h-7.58c-0.34,0-0.62-0.27-0.62-0.61v-4.61
		c0-0.34,0.28-0.61,0.62-0.61h7.58c0.34,0,0.62,0.27,0.62,0.61V143.28z M129.48,134.77c0,0.33-0.28,0.61-0.62,0.61h-7.58
		c-0.34,0-0.62-0.27-0.62-0.61v-4.61c0-0.33,0.28-0.6,0.62-0.6h7.58c0.34,0,0.62,0.27,0.62,0.6V134.77z M141.07,143.28
		c0,0.34-0.28,0.61-0.62,0.61h-7.58c-0.34,0-0.62-0.27-0.62-0.61v-4.61c0-0.34,0.28-0.61,0.62-0.61h7.58c0.35,0,0.62,0.27,0.62,0.61
		V143.28z M141.07,134.77c0,0.33-0.28,0.61-0.62,0.61h-7.58c-0.34,0-0.62-0.27-0.62-0.61v-4.61c0-0.33,0.28-0.6,0.62-0.6h7.58
		c0.35,0,0.62,0.27,0.62,0.6V134.77z"/>
          <path className="st1" d="M84.39,67.09H59.56
		c-0.33,0-0.61,0.27-0.61,0.61v84.86c0,0.33,0.27,0.61,0.61,0.61h24.83c0.33,0,0.61-0.27,0.61-0.61V67.7
		C85,67.36,84.72,67.09,84.39,67.09 M69.62,143.49c0,0.18-0.15,0.33-0.33,0.33h-5.68c-0.18,0-0.33-0.15-0.33-0.33v-6.33
		c0-0.18,0.15-0.32,0.33-0.32h5.68c0.18,0,0.33,0.14,0.33,0.32V143.49z M69.62,128.94c0,0.18-0.15,0.33-0.33,0.33h-5.68
		c-0.18,0-0.33-0.14-0.33-0.33v-6.33c0-0.18,0.15-0.33,0.33-0.33h5.68c0.18,0,0.33,0.14,0.33,0.33V128.94z M69.62,114.4
		c0,0.18-0.15,0.33-0.33,0.33h-5.68c-0.18,0-0.33-0.15-0.33-0.33v-6.34c0-0.18,0.15-0.33,0.33-0.33h5.68c0.18,0,0.33,0.15,0.33,0.33
		V114.4z M69.62,99.84c0,0.18-0.15,0.33-0.33,0.33h-5.68c-0.18,0-0.33-0.15-0.33-0.33v-6.34c0-0.18,0.15-0.32,0.33-0.32h5.68
		c0.18,0,0.33,0.14,0.33,0.32V99.84z M69.62,85.3c0,0.18-0.15,0.32-0.33,0.32h-5.68c-0.18,0-0.33-0.15-0.33-0.32v-6.34
		c0-0.18,0.15-0.33,0.33-0.33h5.68c0.18,0,0.33,0.14,0.33,0.33V85.3z M80.77,143.49c0,0.18-0.15,0.33-0.33,0.33h-5.68
		c-0.18,0-0.33-0.15-0.33-0.33v-6.33c0-0.18,0.14-0.32,0.33-0.32h5.68c0.18,0,0.33,0.14,0.33,0.32V143.49z M80.77,128.94
		c0,0.18-0.15,0.33-0.33,0.33h-5.68c-0.18,0-0.33-0.14-0.33-0.33v-6.33c0-0.18,0.14-0.33,0.33-0.33h5.68c0.18,0,0.33,0.14,0.33,0.33
		V128.94z M80.77,114.4c0,0.18-0.15,0.33-0.33,0.33h-5.68c-0.18,0-0.33-0.15-0.33-0.33v-6.34c0-0.18,0.14-0.33,0.33-0.33h5.68
		c0.18,0,0.33,0.15,0.33,0.33V114.4z M80.77,99.84c0,0.18-0.15,0.33-0.33,0.33h-5.68c-0.18,0-0.33-0.15-0.33-0.33v-6.34
		c0-0.18,0.14-0.32,0.33-0.32h5.68c0.18,0,0.33,0.14,0.33,0.32V99.84z M80.77,85.3c0,0.18-0.15,0.32-0.33,0.32h-5.68
		c-0.18,0-0.33-0.15-0.33-0.32v-6.34c0-0.18,0.14-0.33,0.33-0.33h5.68c0.18,0,0.33,0.14,0.33,0.33V85.3z"/>
          <path className="st1" d="M143.96,123.98
		c0,0.29-0.23,0.51-0.51,0.51h-25.54c-0.28,0-0.51-0.23-0.51-0.51v-5.11c0-0.28,0.23-0.51,0.51-0.51h25.54
		c0.28,0,0.51,0.23,0.51,0.51V123.98z"/>
          <path className="st1" d="M114.38,83.11
		c-0.08-0.27-0.32-0.47-0.62-0.47c-0.13,0-0.25,0.05-0.35,0.12c-0.02,0.01-0.03,0.02-0.05,0.03l-0.21,0.26l-24.4,30.96l-0.11,0.15
		c-0.13,0.12-0.21,0.29-0.21,0.48v0.04v38c0,0.33,0.27,0.61,0.61,0.61h24.8c0.34,0,0.61-0.27,0.61-0.61V83.37
		C114.44,83.28,114.41,83.19,114.38,83.11 M104.1,99.92l0.17-0.22c1.67-2.14,4.48-5.61,5.85-7.29l0.18-0.22
		c0.13-0.16,0.33-0.26,0.55-0.26c0.34,0,0.61,0.25,0.68,0.57v0.27v0.22v7.33c0,0.38-0.3,0.68-0.67,0.7c-0.01,0-0.02,0.01-0.03,0.01
		c-0.01,0-0.02-0.01-0.03-0.01H105h-0.3c-0.01,0-0.02,0.01-0.03,0.01c-0.01,0-0.02-0.01-0.03-0.01c-0.37-0.02-0.67-0.32-0.67-0.7
		C103.97,100.19,104.02,100.04,104.1,99.92 M100.24,143.28c0,0.34-0.27,0.61-0.61,0.61h-7.4c-0.34,0-0.61-0.27-0.61-0.61v-4.63
		c0-0.33,0.27-0.6,0.61-0.6h7.4c0.34,0,0.61,0.27,0.61,0.6V143.28z M100.24,134.72c0,0.34-0.27,0.61-0.61,0.61h-7.4
		c-0.34,0-0.61-0.27-0.61-0.61v-4.63c0-0.34,0.27-0.61,0.61-0.61h7.4c0.34,0,0.61,0.27,0.61,0.61V134.72z M100.24,126.16
		c0,0.33-0.27,0.6-0.61,0.6h-7.4c-0.34,0-0.61-0.27-0.61-0.6v-4.63c0-0.34,0.27-0.61,0.61-0.61h7.4c0.34,0,0.61,0.27,0.61,0.61
		V126.16z M100.26,107.41v0.22v9.98c0,0.34-0.27,0.61-0.61,0.61h-7.4c-0.34,0-0.61-0.27-0.61-0.61v-1.23v-0.05
		c0-0.08,0.02-0.16,0.05-0.23c0.01-0.02,0.01-0.03,0.04-0.07c0.01-0.03,0.04-0.06,0.05-0.08c0.02-0.03,0.05-0.07,0.07-0.1
		c0.95-1.35,5.21-6.61,7.01-8.81l0.18-0.22c0.13-0.16,0.33-0.26,0.55-0.26c0.34,0,0.61,0.25,0.68,0.57V107.41z M111.55,143.28
		c0,0.34-0.27,0.61-0.61,0.61h-7.4c-0.34,0-0.61-0.27-0.61-0.61v-4.63c0-0.33,0.27-0.6,0.61-0.6h7.4c0.33,0,0.61,0.27,0.61,0.6
		V143.28z M111.55,134.72c0,0.34-0.27,0.61-0.61,0.61h-7.4c-0.34,0-0.61-0.27-0.61-0.61v-4.63c0-0.34,0.27-0.61,0.61-0.61h7.4
		c0.33,0,0.61,0.27,0.61,0.61V134.72z M111.55,126.16c0,0.33-0.27,0.6-0.61,0.6h-7.4c-0.34,0-0.61-0.27-0.61-0.6v-4.63
		c0-0.34,0.27-0.61,0.61-0.61h7.4c0.33,0,0.61,0.27,0.61,0.61V126.16z M111.55,117.6c0,0.33-0.27,0.6-0.61,0.6h-7.4
		c-0.34,0-0.61-0.27-0.61-0.6v-4.63c0-0.34,0.27-0.61,0.61-0.61h7.4c0.33,0,0.61,0.27,0.61,0.61V117.6z M111.55,109.04
		c0,0.34-0.27,0.61-0.61,0.61h-7.4c-0.34,0-0.61-0.27-0.61-0.61v-4.63c0-0.34,0.27-0.61,0.61-0.61h7.4c0.33,0,0.61,0.27,0.61,0.61
		V109.04z"/>
        </g>
        <g>
          <path className="st2" d="M48.88,15.82h0.82c0.8,0,1.16,0.38,1.16,1.16v1.75c0,0.76-0.36,1.16-1.14,1.16h-0.84V15.82z M49.92,21.72
		c0.86,0,1.22,0.4,1.22,1.16v2.15c0,0.76-0.39,1.16-1.2,1.16h-1.05v-4.47H49.92z M46.48,28.16h3.73c2.42,0,3.33-1.12,3.33-3.14v-1.9
		c0-1.31-0.59-2.11-1.79-2.38c0.98-0.32,1.41-1.08,1.41-2.28V17c0-2.02-0.8-3.14-3.21-3.14h-3.48V28.16z"/>
          <path className="st2" d="M58.67,16.07h0.02v7.55H56.9L58.67,16.07z M55.84,28.16l0.61-2.62h2.24v2.62h5.74V26.2h-3.33v-4.34h2.38v-1.94
		h-2.38v-4.09h3.33v-1.96h-7.21l-3.65,14.3H55.84z"/>
          <path className="st2" d="M68.92,15.82c0.82,0,1.18,0.38,1.18,1.16v2.11c0,0.76-0.36,1.18-1.18,1.18h-0.8v-4.45H68.92z M70.37,28.16
		h2.53l-2.3-6.5c1.37-0.32,1.92-1.22,1.92-2.76v-1.83c0-2-0.89-3.21-3.31-3.21h-3.5v14.3h2.4V21.6L70.37,28.16z"/>
          <polygon className="st2" points="74.25,28.16 79.99,28.16 79.99,26.2 76.66,26.2 76.66,21.85 79.04,21.85 79.04,19.91 76.66,19.91
		76.66,15.82 79.99,15.82 79.99,13.86 74.25,13.86 	"/>
          <path className="st2" d="M84.99,13.86h-3.71v14.3h3.71c2.57,0,3.48-1.64,3.48-3.84V17.7C88.47,15.48,87.56,13.86,84.99,13.86
		 M83.68,26.2V15.82h1.01c0.99,0,1.37,0.59,1.37,1.43v7.49c0,0.86-0.38,1.46-1.37,1.46H83.68z"/>
          <polygon className="st2" points="96.65,13.86 94.35,13.86 92.9,19.28 92.88,19.28 91.42,13.86 88.91,13.86 91.59,22.72 91.59,28.16
		93.99,28.16 93.99,22.72 	"/>
          <path className="st2" d="M100.72,22.42h1.14v2.53c0,0.93-0.4,1.37-1.18,1.37c-0.82,0-1.18-0.61-1.18-1.48v-7.68
		c0-0.86,0.38-1.48,1.24-1.48c0.86,0,1.2,0.51,1.2,1.39v1.46h2.19v-1.27c0-2.11-0.78-3.59-3.44-3.59c-2.53,0-3.61,1.62-3.61,3.86
		v6.92c0,2.21,0.95,3.86,3.02,3.86c1.31,0,1.98-0.65,2.3-1.73v1.56h1.73v-7.63h-3.42V22.42z"/>
          <polygon className="st2" points="104.81,15.82 106.8,15.82 106.8,28.16 109.22,28.16 109.22,15.82 111.2,15.82 111.2,13.86
		104.81,13.86 	"/>
          <rect x="112.32" y="13.86" className="st2" width="2.4" height="14.3" />
          <path className="st2" d="M119.74,22.42h1.14v2.53c0,0.93-0.4,1.37-1.18,1.37c-0.82,0-1.18-0.61-1.18-1.48v-7.68
		c0-0.86,0.38-1.48,1.24-1.48c0.86,0,1.2,0.51,1.2,1.39v1.46h2.19v-1.27c0-2.11-0.78-3.59-3.44-3.59c-2.53,0-3.61,1.62-3.61,3.86
		v6.92c0,2.21,0.95,3.86,3.02,3.86c1.31,0,1.98-0.65,2.3-1.73v1.56h1.73v-7.63h-3.42V22.42z"/>
          <polygon className="st2" points="124.53,28.16 130.27,28.16 130.27,26.2 126.94,26.2 126.94,21.85 129.32,21.85 129.32,19.91
		126.94,19.91 126.94,15.82 130.27,15.82 130.27,13.86 124.53,13.86 	"/>
          <path className="st2" d="M136.17,15.82H137c0.8,0,1.16,0.38,1.16,1.16v1.75c0,0.76-0.36,1.16-1.14,1.16h-0.84V15.82z M137.21,21.72
		c0.86,0,1.22,0.4,1.22,1.16v2.15c0,0.76-0.39,1.16-1.2,1.16h-1.05v-4.47H137.21z M133.77,28.16h3.73c2.42,0,3.33-1.12,3.33-3.14
		v-1.9c0-1.31-0.59-2.11-1.79-2.38c0.98-0.32,1.41-1.08,1.41-2.28V17c0-2.02-0.8-3.14-3.21-3.14h-3.48V28.16z"/>
          <polygon className="st2" points="148.3,13.86 146,13.86 144.55,19.28 144.52,19.28 143.07,13.86 140.56,13.86 143.24,22.72
		143.24,28.16 145.64,28.16 145.64,22.72 	"/>
          <polygon className="st2" points="148.43,28.16 154.16,28.16 154.16,26.2 150.83,26.2 150.83,21.85 153.21,21.85 153.21,19.91
		150.83,19.91 150.83,15.82 154.16,15.82 154.16,13.86 148.43,13.86 	"/>
          <path className="st2" d="M158.65,15.82c0.82,0,1.18,0.38,1.18,1.16v2.11c0,0.76-0.36,1.18-1.18,1.18h-0.8v-4.45H158.65z M160.11,28.16
		h2.53l-2.3-6.5c1.37-0.32,1.92-1.22,1.92-2.76v-1.83c0-2-0.89-3.21-3.31-3.21h-3.5v14.3h2.4V21.6L160.11,28.16z"/>
          <path className="st2" d="M50.11,48.74c2.55,0,3.65-1.64,3.65-3.86v-6.92c0-2.24-1.1-3.86-3.65-3.86c-2.53,0-3.63,1.62-3.63,3.86v6.92
		C46.48,47.1,47.58,48.74,50.11,48.74 M50.11,46.74c-0.84,0-1.22-0.61-1.22-1.48v-7.68c0-0.86,0.38-1.48,1.22-1.48
		c0.86,0,1.24,0.61,1.24,1.48v7.68C51.35,46.13,50.97,46.74,50.11,46.74"/>
          <path className="st2" d="M58.75,42.84h1.14v2.53c0,0.93-0.4,1.37-1.18,1.37c-0.82,0-1.18-0.61-1.18-1.48v-7.68
		c0-0.86,0.38-1.48,1.24-1.48c0.86,0,1.2,0.51,1.2,1.39v1.46h2.19v-1.27c0-2.11-0.78-3.59-3.44-3.59c-2.53,0-3.61,1.62-3.61,3.86
		v6.92c0,2.21,0.95,3.86,3.02,3.86c1.31,0,1.98-0.65,2.3-1.73v1.56h1.73v-7.63h-3.42V42.84z"/>
          <polygon className="st2" points="65.75,48.57 71.03,48.57 71.03,46.61 68.16,46.61 68.16,34.27 65.75,34.27 	" />
          <path className="st2" d="M74.8,48.74c2.55,0,3.65-1.64,3.65-3.86v-6.92c0-2.24-1.1-3.86-3.65-3.86c-2.53,0-3.63,1.62-3.63,3.86v6.92
		C71.17,47.1,72.27,48.74,74.8,48.74 M74.8,46.74c-0.84,0-1.22-0.61-1.22-1.48v-7.68c0-0.86,0.38-1.48,1.22-1.48
		c0.86,0,1.24,0.61,1.24,1.48v7.68C76.05,46.13,75.67,46.74,74.8,46.74"/>
          <polygon className="st2" points="84.74,48.57 87.27,48.57 84.42,40.71 87.2,34.27 84.84,34.27 82.23,40.56 82.23,34.27 79.82,34.27
		79.82,48.57 82.23,48.57 82.23,41.61 	"/>
          <path className="st2" d="M90.49,38.3h0.02l0.95,5.74h-1.92L90.49,38.3z M88.81,48.57l0.42-2.62h2.55l0.44,2.62h2.47l-2.72-14.3h-2.74
		l-2.7,14.3H88.81z"/>
          <polygon className="st2" points="95.68,48.57 100.95,48.57 100.95,46.61 98.08,46.61 98.08,34.27 95.68,34.27 	" />
          <path className="st2" d="M104.79,48.74c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.68-1.14-1.14-1.14-1.94v-0.3c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.49c0,1.64,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.02
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96h-2.24v1.92C101.4,47.37,102.35,48.74,104.79,48.74"/>
          <path className="st2" d="M112.09,38.3h0.02l0.95,5.74h-1.92L112.09,38.3z M110.4,48.57l0.42-2.62h2.55l0.44,2.62h2.47l-2.72-14.3h-2.74
		l-2.7,14.3H110.4z"/>
          <polygon className="st2" points="123.9,48.57 126.09,48.57 126.09,34.27 123.06,34.27 121.66,42.33 121.64,42.33 120.08,34.27
		117.28,34.27 117.28,48.57 119.26,48.57 119.26,39.29 119.3,39.29 121.09,48.57 122.21,48.57 123.84,39.29 123.9,39.29 	"/>
          <polygon className="st2" points="127.88,48.57 130.29,48.57 130.29,42.27 132.69,42.27 132.69,40.33 130.29,40.33 130.29,36.23
		133.52,36.23 133.52,34.27 127.88,34.27 	"/>
          <path className="st2" d="M138.91,34.27v11.05c0,0.84-0.34,1.41-1.18,1.41c-0.84,0-1.18-0.57-1.18-1.41V34.27h-2.4v10.82
		c0,2.21,1.05,3.65,3.58,3.65c2.45,0,3.4-1.43,3.4-3.65V34.27H138.91z"/>
          <polygon className="st2" points="147.35,48.57 149.46,48.57 149.46,34.27 147.46,34.27 147.46,42.48 144.86,34.27 142.5,34.27
		142.5,48.57 144.52,48.57 144.52,39.59 	"/>
          <path className="st2" d="M154.54,34.27h-3.71v14.3h3.71c2.57,0,3.48-1.64,3.48-3.84v-6.62C158.02,35.9,157.11,34.27,154.54,34.27
		 M153.23,46.61V36.23h1.01c0.99,0,1.37,0.59,1.37,1.43v7.49c0,0.86-0.38,1.46-1.37,1.46H153.23z"/>
        </g>
        <polygon className="st2" points="16.33,48.55 16.33,22.3 16.28,22.3 12.24,26.49 12.19,26.49 12.19,20.48 17.5,14.25 22.1,14.25
	22.1,48.55 "/>
        <polygon className="st2" points="29.48,48.55 29.48,22.3 29.43,22.3 25.39,26.49 25.34,26.49 25.34,20.48 30.65,14.25 35.25,14.25
	35.25,48.55 "/>
      </svg>
    </>
  );
};
