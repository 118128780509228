import React from "react";

export interface Props {
  isActive: boolean;
}

export const IconTwo: React.FC<Props> = ({ isActive = false }: Props) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 170.08 170.08" style={{ ['enableBackground' as any]: 'new 0 0 170.08 170.08', 'filter': 'none' }} xmlSpace="preserve">
      <style type="text/css">
        {`  .st02{fill:${isActive ? '#dea739' : '#B2B2B2'};}
            svg:hover .st02{fill:#dea739;}
        .st12{fill:#FFFFFF;}`}
      </style>
      <rect className="st02" width="170.08" height="170.08" />
      <g>
        <path className="st12" d="M31.81,43.84h-9.46v-1.62c0-2.68,0.96-3.69,2.43-5.26l3.69-3.89c2.98-3.19,3.64-5.21,3.64-8.65v-3.34
		c0-4.9-2.38-7.23-7.54-7.23c-5.06,0-7.64,2.68-7.64,7.49v4.15h5.26v-4.3c0-2.07,1.01-2.68,2.23-2.68c1.11,0,2.07,0.46,2.07,2.58
		v2.83c0,2.83-0.25,3.95-2.12,5.92l-3.24,3.44c-3.13,3.34-4.3,5.57-4.3,8.95v6.32h14.97V43.84z"/>
        <path className="st12" d="M67.84,144.52v2.39c0,0.02-0.01,0.04-0.01,0.05c0,0.02,0.01,0.04,0.01,0.06c0.03,0.59,0.51,1.06,1.1,1.07
		c0.01,0,0.01,0,0.02,0c0.01,0,0.02,0,0.03,0h28.06c0.01,0,0.02,0,0.02,0c0.01,0,0.02,0,0.03,0c0.61-0.01,1.1-0.51,1.11-1.12
		c0,0,0,0,0-0.01c0,0,0,0,0-0.01v-2.71c15.79-5.18,27.62-17.98,30.23-33.58c0.01-0.03,0.01-0.06,0.01-0.09
		c0.01-0.05,0.02-0.1,0.02-0.16c0-0.63-0.51-1.13-1.13-1.13H38.45h-0.54c-0.63,0-1.14,0.51-1.14,1.13l0.06,0.51
		C39.56,126.68,51.72,139.55,67.84,144.52"/>
        <path className="st12" d="M98.18,71.13c-0.37,0.52-9.01,12.96,0.19,23.02c4.22,4.62,3.85,8.69,2.65,11.44h5.9
		c1.19-4.06,0.7-9.16-4.09-14.39c-6.78-7.41,0.09-17.43,0.19-17.58c0.81-1.14,0.38-2.62-0.96-3.3
		C100.73,69.63,98.99,69.99,98.18,71.13"/>
        <path className="st12" d="M83.23,105.58h5.9c1.19-4.06,0.7-9.15-4.09-14.39c-6.78-7.41,0.08-17.42,0.19-17.59
		c0.81-1.14,0.38-2.61-0.96-3.3c-1.33-0.68-3.07-0.32-3.88,0.82c-0.37,0.52-9.02,12.96,0.19,23.02
		C84.8,98.76,84.43,102.83,83.23,105.58"/>
        <path className="st12" d="M62.6,71.13c-0.37,0.52-9.01,12.96,0.19,23.02c4.22,4.62,3.86,8.69,2.66,11.44h5.9
		c1.19-4.06,0.7-9.15-4.1-14.39c-6.78-7.41,0.08-17.43,0.19-17.59c0.8-1.14,0.38-2.61-0.96-3.3C65.14,69.62,63.41,69.99,62.6,71.13"
        />
      </g>
      <g>
        <path className="st12" d="M52.15,28.32c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.68-1.14-1.14-1.14-1.94v-0.3c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.49c0,1.64,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.02
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96h-2.24v1.92C48.76,26.95,49.71,28.32,52.15,28.32"/>
        <polygon className="st12" points="56.33,15.82 58.31,15.82 58.31,28.15 60.74,28.15 60.74,15.82 62.72,15.82 62.72,13.86 56.33,13.86
		"/>
        <path className="st12" d="M67.15,28.32c2.55,0,3.65-1.64,3.65-3.86v-6.92c0-2.23-1.1-3.86-3.65-3.86c-2.53,0-3.63,1.62-3.63,3.86v6.92
		C63.52,26.68,64.62,28.32,67.15,28.32 M67.15,26.32c-0.84,0-1.22-0.61-1.22-1.48v-7.68c0-0.87,0.38-1.48,1.22-1.48
		c0.87,0,1.24,0.61,1.24,1.48v7.68C68.39,25.71,68.01,26.32,67.15,26.32"/>
        <path className="st12" d="M75.88,15.82c0.82,0,1.18,0.38,1.18,1.16v3.29c0,0.78-0.36,1.18-1.18,1.18h-0.89v-5.63H75.88z M72.59,28.15
		h2.4V23.3h1.16c2.42,0,3.31-1.22,3.31-3.23v-3.02c0-2-0.89-3.21-3.31-3.21h-3.56V28.15z"/>
        <path className="st12" d="M52.15,48.74c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.67-1.14-1.14-1.14-1.94v-0.3c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.49c0,1.64,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.02
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96h-2.24v1.92C48.76,47.37,49.71,48.74,52.15,48.74"/>
        <path className="st12" d="M61.69,34.27v11.05c0,0.84-0.34,1.41-1.18,1.41c-0.84,0-1.18-0.57-1.18-1.41V34.27h-2.4v10.82
		c0,2.21,1.05,3.65,3.59,3.65c2.45,0,3.4-1.43,3.4-3.65V34.27H61.69z"/>
        <polygon className="st12" points="66.12,48.57 71.39,48.57 71.39,46.61 68.52,46.61 68.52,34.27 66.12,34.27 	" />
        <polygon className="st12" points="70.84,36.23 72.82,36.23 72.82,48.57 75.25,48.57 75.25,36.23 77.23,36.23 77.23,34.27 70.84,34.27
		"/>
      </g>
    </svg>

  )
};
