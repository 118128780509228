import React from "react";

export interface Props {
  isActive: boolean;
}

export const IconSeven: React.FC<Props> = ({ isActive = false }: Props) => {
  return (
    <>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 170.08 170.08" style={{ ['enableBackground' as any]: 'new 0 0 170.08 170.08', 'filter': 'none' }} xmlSpace="preserve">
        <style type="text/css">{`
          .st07{fill:${isActive ? '#f9c412' : '#B2B2B2'};}
          svg:hover #SVGID_1_7{fill:#f9c412;}
          .st1{clip-path:url(#SVGID_00000094593350879451060770000017590039654882165907_);fill:#FFFFFF;}
          .st2{fill:#FFFFFF;}
        `}</style>
        <rect className="st07" width="170.08" height="170.08" />
        <g>
          <defs>
            <rect id="SVGID_1_7" x="0" y="0" width="170.07" height="170.07" />
          </defs>
          <use xlinkHref="#SVGID_1_7" style={{ 'overflow': 'visible', 'fill': isActive ? '#f9c412' : '#B2B2B2' }} />
          <clipPath id="SVGID_00000037690742866047055220000010761338968332644763_">
            <use xlinkHref="#SVGID_1_7" style={{ 'overflow': 'visible' }} />
          </clipPath>
          <path style={{ clipPath: 'url(#SVGID_00000037690742866047055220000010761338968332644763_)', fill: '#FFFFFF' }} d="M85.1,82.4
		c-13.77,0-24.92,11.16-24.92,24.93c0,13.77,11.16,24.93,24.92,24.93c13.77,0,24.92-11.16,24.92-24.93
		C110.03,93.56,98.87,82.4,85.1,82.4 M83.26,92.25c0-0.83,0.83-1.74,1.86-1.74c1.02,0,1.85,0.91,1.85,1.74v8.56
		c0,0.83-0.83,1.73-1.85,1.73c-1.02,0-1.86-0.9-1.86-1.73V92.25z M85.11,120.1c-7.16,0-12.96-5.8-12.96-12.96
		c0-5.82,3.83-10.74,9.11-12.38v4.83c-2.75,1.4-4.63,4.26-4.63,7.56c0,4.69,3.8,8.49,8.49,8.49c4.69,0,8.49-3.8,8.49-8.49
		c0-3.31-1.9-6.17-4.66-7.57v-4.82c5.29,1.63,9.14,6.56,9.14,12.39C98.07,114.3,92.27,120.1,85.11,120.1"/>
          <path style={{ clipPath: 'url(#SVGID_00000037690742866047055220000010761338968332644763_)', fill: '#FFFFFF' }} d="M56.49,107.11
		c0-1.13-1-2.05-1.92-2.05H43.66c-0.92,0-1.93,0.92-1.93,2.05c0,1.13,1.01,2.05,1.93,2.05h10.92
		C55.49,109.16,56.49,108.24,56.49,107.11"/>
          <path style={{ clipPath: 'url(#SVGID_00000037690742866047055220000010761338968332644763_)', fill: '#FFFFFF' }} d="M128.49,107.11
		c0-1.13-1-2.05-1.92-2.05h-10.92c-0.92,0-1.93,0.92-1.93,2.05c0,1.13,1.01,2.05,1.93,2.05h10.92
		C127.49,109.16,128.49,108.24,128.49,107.11"/>
          <path style={{ clipPath: 'url(#SVGID_00000037690742866047055220000010761338968332644763_)', fill: '#FFFFFF' }} d="M85.11,78.5
		c1.13,0,2.04-1,2.04-1.91V65.66c0-0.92-0.92-1.93-2.04-1.93c-1.13,0-2.05,1.01-2.05,1.93v10.92C83.06,77.49,83.98,78.5,85.11,78.5"
          />
          <path style={{ clipPath: 'url(#SVGID_00000037690742866047055220000010761338968332644763_)', fill: '#FFFFFF' }} d="M85.11,150.49
		c1.13,0,2.04-1,2.04-1.92v-10.91c0-0.92-0.92-1.93-2.04-1.93c-1.13,0-2.05,1.01-2.05,1.93v10.91
		C83.06,149.49,83.98,150.49,85.11,150.49"/>
          <path style={{ clipPath: 'url(#SVGID_00000037690742866047055220000010761338968332644763_)', fill: '#FFFFFF' }} d="M60.27,92.9
		c0.56-0.98,0.15-2.27-0.65-2.73l-9.47-5.42c-0.8-0.46-2.13-0.17-2.69,0.82c-0.56,0.98-0.14,2.28,0.66,2.74l9.47,5.42
		C58.38,94.18,59.71,93.88,60.27,92.9"/>
          <path style={{ clipPath: 'url(#SVGID_00000037690742866047055220000010761338968332644763_)', fill: '#FFFFFF' }} d="M122.76,128.66
		c0.56-0.98,0.15-2.27-0.65-2.73l-9.47-5.42c-0.8-0.46-2.13-0.16-2.69,0.82c-0.56,0.98-0.14,2.28,0.66,2.74l9.47,5.42
		C120.87,129.94,122.2,129.64,122.76,128.66"/>
          <path style={{ clipPath: 'url(#SVGID_00000037690742866047055220000010761338968332644763_)', fill: '#FFFFFF' }} d="M99.33,82.28
		c0.98,0.56,2.27,0.15,2.73-0.65l5.42-9.47c0.46-0.8,0.16-2.13-0.82-2.69c-0.98-0.56-2.29-0.14-2.74,0.66L98.5,79.6
		C98.04,80.39,98.34,81.72,99.33,82.28"/>
          <path style={{ clipPath: 'url(#SVGID_00000037690742866047055220000010761338968332644763_)', fill: '#FFFFFF' }} d="M63.56,144.77
		c0.98,0.56,2.27,0.15,2.73-0.65l5.42-9.47c0.46-0.8,0.16-2.13-0.82-2.69c-0.98-0.56-2.29-0.14-2.74,0.66l-5.42,9.47
		C62.28,142.88,62.58,144.21,63.56,144.77"/>
          <path style={{ clipPath: 'url(#SVGID_00000037690742866047055220000010761338968332644763_)', fill: '#FFFFFF' }} d="M70.84,82.3
		c0.98-0.56,1.27-1.89,0.82-2.68l-5.44-9.46c-0.46-0.8-1.76-1.22-2.74-0.65c-0.98,0.56-1.28,1.9-0.82,2.7l5.44,9.46
		C68.57,82.46,69.86,82.87,70.84,82.3"/>
          <path style={{ clipPath: 'url(#SVGID_00000037690742866047055220000010761338968332644763_)', fill: '#FFFFFF' }} d="M106.74,144.72
		c0.98-0.56,1.27-1.89,0.82-2.68l-5.44-9.46c-0.46-0.8-1.76-1.22-2.74-0.65c-0.98,0.56-1.28,1.9-0.82,2.7l5.44,9.46
		C104.46,144.88,105.75,145.28,106.74,144.72"/>
          <path style={{ clipPath: 'url(#SVGID_00000037690742866047055220000010761338968332644763_)', fill: '#FFFFFF' }} d="M109.92,92.85
		c0.56,0.98,1.89,1.27,2.68,0.82l9.46-5.44c0.8-0.46,1.22-1.76,0.66-2.74c-0.57-0.98-1.9-1.28-2.7-0.82l-9.46,5.44
		C109.76,90.57,109.35,91.87,109.92,92.85"/>
          <path style={{ clipPath: 'url(#SVGID_00000037690742866047055220000010761338968332644763_)', fill: '#FFFFFF' }} d="M47.5,128.74
		c0.56,0.98,1.89,1.27,2.68,0.82l9.46-5.44c0.8-0.46,1.22-1.76,0.65-2.74c-0.56-0.98-1.9-1.28-2.7-0.82l-9.46,5.44
		C47.35,126.46,46.94,127.76,47.5,128.74"/>
        </g>
        <g>
          <path className="st2" d="M51.29,15.82h0.82c0.8,0,1.16,0.38,1.16,1.16v1.75c0,0.76-0.36,1.16-1.14,1.16h-0.84V15.82z M52.32,21.72
		c0.86,0,1.22,0.4,1.22,1.16v2.15c0,0.76-0.39,1.16-1.2,1.16h-1.05v-4.47H52.32z M48.89,28.16h3.73c2.42,0,3.33-1.12,3.33-3.14v-1.9
		c0-1.31-0.59-2.11-1.79-2.38c0.98-0.32,1.41-1.08,1.41-2.28V17c0-2.02-0.8-3.14-3.21-3.14h-3.48V28.16z"/>
          <path className="st2" d="M61.08,16.07h0.02v7.55H59.3L61.08,16.07z M58.25,28.16l0.61-2.62h2.24v2.62h5.74v-1.96H63.5v-4.34h2.38v-1.94
		H63.5v-4.09h3.33v-1.96h-7.21l-3.65,14.3H58.25z"/>
          <path className="st2" d="M71.33,15.82c0.82,0,1.18,0.38,1.18,1.16v2.11c0,0.76-0.36,1.18-1.18,1.18h-0.8v-4.45H71.33z M72.78,28.16
		h2.53l-2.3-6.5c1.37-0.32,1.92-1.22,1.92-2.76v-1.83c0-2-0.89-3.21-3.31-3.21h-3.5v14.3h2.4V21.6L72.78,28.16z"/>
          <polygon className="st2" points="76.66,28.16 82.4,28.16 82.4,26.19 79.07,26.19 79.07,21.85 81.45,21.85 81.45,19.91 79.07,19.91
		79.07,15.82 82.4,15.82 82.4,13.86 76.66,13.86 	"/>
          <path className="st2" d="M87.82,13.86h-3.71v14.3h3.71c2.57,0,3.48-1.64,3.48-3.84V17.7C91.3,15.48,90.39,13.86,87.82,13.86
		 M86.51,26.19V15.82h1.01c0.99,0,1.37,0.59,1.37,1.43v7.49c0,0.86-0.38,1.45-1.37,1.45H86.51z"/>
          <polygon className="st2" points="99.48,13.86 97.18,13.86 95.73,19.28 95.7,19.28 94.25,13.86 91.74,13.86 94.42,22.71 94.42,28.16
		96.82,28.16 96.82,22.71 	"/>
          <path className="st2" d="M103.55,22.42h1.14v2.53c0,0.93-0.4,1.37-1.18,1.37c-0.82,0-1.18-0.61-1.18-1.48v-7.68
		c0-0.86,0.38-1.48,1.24-1.48c0.86,0,1.2,0.51,1.2,1.39v1.45h2.19v-1.27c0-2.11-0.78-3.59-3.44-3.59c-2.53,0-3.61,1.62-3.61,3.86
		v6.92c0,2.21,0.95,3.86,3.02,3.86c1.31,0,1.98-0.65,2.3-1.73v1.56h1.73v-7.63h-3.42V22.42z"/>
          <polygon className="st2" points="107.64,15.82 109.62,15.82 109.62,28.16 112.05,28.16 112.05,15.82 114.03,15.82 114.03,13.86
		107.64,13.86 	"/>
          <rect x="115.15" y="13.86" className="st2" width="2.4" height="14.3" />
          <path className="st2" d="M122.99,22.42h1.14v2.53c0,0.93-0.4,1.37-1.18,1.37c-0.82,0-1.18-0.61-1.18-1.48v-7.68
		c0-0.86,0.38-1.48,1.24-1.48c0.86,0,1.2,0.51,1.2,1.39v1.45h2.19v-1.27c0-2.11-0.78-3.59-3.44-3.59c-2.53,0-3.61,1.62-3.61,3.86
		v6.92c0,2.21,0.95,3.86,3.02,3.86c1.31,0,1.98-0.65,2.3-1.73v1.56h1.73v-7.63h-3.42V22.42z"/>
          <polygon className="st2" points="48.89,48.57 54.62,48.57 54.62,46.61 51.29,46.61 51.29,42.26 53.67,42.26 53.67,40.32 51.29,40.32
		51.29,36.23 54.62,36.23 54.62,34.27 48.89,34.27 	"/>
          <polygon className="st2" points="61.18,48.57 63.29,48.57 63.29,34.27 61.29,34.27 61.29,42.47 58.69,34.27 56.33,34.27 56.33,48.57
		58.36,48.57 58.36,39.59 	"/>
          <polygon className="st2" points="65.08,48.57 70.82,48.57 70.82,46.61 67.49,46.61 67.49,42.26 69.87,42.26 69.87,40.32 67.49,40.32
		67.49,36.23 70.82,36.23 70.82,34.27 65.08,34.27 	"/>
          <path className="st2" d="M75.31,36.23c0.82,0,1.18,0.38,1.18,1.16v2.11c0,0.76-0.36,1.18-1.18,1.18h-0.8v-4.45H75.31z M76.77,48.57
		h2.53l-2.3-6.5c1.37-0.32,1.92-1.22,1.92-2.76v-1.83c0-2-0.89-3.21-3.31-3.21h-3.5v14.3h2.4v-6.56L76.77,48.57z"/>
          <path className="st2" d="M84.27,42.83h1.14v2.53c0,0.93-0.4,1.37-1.18,1.37c-0.82,0-1.18-0.61-1.18-1.48v-7.68
		c0-0.86,0.38-1.48,1.24-1.48c0.86,0,1.2,0.51,1.2,1.39v1.45h2.19v-1.27c0-2.11-0.78-3.59-3.44-3.59c-2.53,0-3.61,1.62-3.61,3.86
		v6.92c0,2.21,0.95,3.86,3.02,3.86c1.31,0,1.98-0.65,2.3-1.73v1.56h1.73v-7.63h-3.42V42.83z"/>
          <rect x="89.48" y="34.27" className="st2" width="2.4" height="14.3" />
        </g>
        <polygon className="st2" points="18.66,48.54 26.35,18.96 17.44,18.96 17.44,14.25 31.91,14.25 31.91,19.56 24.38,48.54 " />
      </svg>

    </>
  );
};
