import { useFormik } from "formik";
import React, { FC } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

import { CustomizedLabelInput } from "../../components/core/inputs/CustomizedLabelInput";
import { INPUTS } from "../../constants/ui";
import { Dispatch } from "../../store/store";
import { RegisterPageWrapper } from "../RegisterPageWrapper";

type IPasswordValues = Record<string, string>;

type IForgotPasswordPage = ReturnType<typeof mapDispatch>;
const ForgotPasswordPage: FC<IForgotPasswordPage> = ({
  forgotPassword
}) => {

  const passwordValues: IPasswordValues = {
    email: "",
  };

  const formik = useFormik({
    initialValues: passwordValues,
    validationSchema: Yup.object({
      email: Yup.string()
        .email()
        .required("Required"),
    }),
    onSubmit: (values) => {
      forgotPassword({
        username: values.email,
      });
    },
  });

  return (
    <RegisterPageWrapper
      title="Indstil nyt kodeord"
      action={{
        title: "Indstil",
        handler: formik.submitForm,
        disabled: !formik.isValid,
      }}
    >
      <CustomizedLabelInput
        autoFocus
        id="email"
        label="Email"
        value={formik.values.email}
        placeHolder="Din mailadresse"
        onChange={formik.handleChange}
        type="email"
        error={formik.values.email && formik.errors.email}
        styleType={formik.values.email && formik.errors.email ? INPUTS.ERROR : INPUTS.DEFAULT_FULL_WIDTH}
        disabled={false}
      />
    </RegisterPageWrapper>
  );
};

const mapDispatch = (dispatch: Dispatch) => ({
  forgotPassword: dispatch.user.forgotPassword
});

export default connect(null, mapDispatch)(ForgotPasswordPage);
