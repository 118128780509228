import React, { FormEvent, SyntheticEvent } from "react";
import { Modal } from "../../Modal";
import { LeftComponent } from "./LeftComponent";
import { RightComponent } from "./RightComponent";

export interface Props {
  showModal: boolean;
  title: string;
  body: string;
  redBtnLabel: string;
  greenBtnLabel: string;
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
  setShowModal?: (e: SyntheticEvent) => void;
  submitRed?: () => void;
  submitGreen?: () => void;
}

export const ConfirmationModal: React.FC<Props> = ({
  showModal,
  ...props
}: Props) => {
  return (
    <Modal
      LeftComponent={LeftComponent}
      RightComponent={RightComponent}
      rightcomponentbackgroundcolor={"bg-gray-100"}
      showModal={showModal}
      innerPadding="p-0"
      {...props}
    />
  );
};
