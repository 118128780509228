import React, { FC } from "react";

import logo from "../../assets/images/logo.png";
import mainImage from "../../assets/images/main-image.png";

const RegisteredMailPage: FC = () => {
  return (
    <div className="relative flex h-full w-full min-w-7xl max-w-screen-2xl m-auto">
      <div className="h-screen flex items-center justify-center">
        <div className="w-1/2 m-auto">
          <div className="">
            <img className="ml-auto" src={logo} />
          </div>
          <h1 className="text-4xl font-semibold mb-8">Kære Carsten</h1>
          <p className="text-2xl mb-5">
            Vi har registreret din oprettelse af en bruger, som nu afventer
            godkendelse af en administrator. Godkendelsesprocennsen kan tage op
            til et par hverdage. Vi sender dig en mail når din bruger er blevet
            godkendt og du kan logge på platformen.
          </p>
          <p className="text-2xl">God dag!</p>
        </div>
      </div>
    </div>
  );
};

export default RegisteredMailPage;
