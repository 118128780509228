import React from "react";

import { FORM_BUTTONS, INPUTS, SELECT_TYPES } from "../../../../constants/ui";
import { CustomizedFormButton } from "../../buttons/CustomizedFormButton";
import { CustomizedInput } from "../../inputs/CustomizedInput";
import { SelectOptionProps } from "../../Select";
import { CustomizedSelect } from "../../select/CustomizedSelect";
import { CustomizedTextarea } from "../../textarea/CustomizedTextarea";

export interface Props {
  inputLabel: string;
  selectLabel: string;
  optionList: SelectOptionProps[];
  textareaLabel: string;
  SubmitButtonText: string;
  FormSubmitBtnDisabled: boolean;
  CancelButtonText: string;
  FormCancelBtnDisabled: boolean;
  onSubmit?: (e: any) => void;
  onSelectChange?: (e: any) => void;
  data: any;
  selectedoption: SelectOptionProps[];
}

export const RightComponent: React.FC<Props> = ({
  inputLabel,
  selectLabel,
  optionList,
  textareaLabel,
  SubmitButtonText,
  FormSubmitBtnDisabled,
  CancelButtonText,
  FormCancelBtnDisabled,
  onSubmit,
  onSelectChange,
  selectedoption,
  data,
  ...props
}: Props) => {
  const isCreate = !!data?.id;
  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="mb-6">
          <div className="text-2xl mb-2 flex">
            <label className="font-quickStand font-medium text-26 leading-32 text-white">
              {inputLabel}
            </label>
          </div>
          <CustomizedInput
            autoFocus
            defaultValue={data?.title || ''}
            placeHolder={""}
            disabled={false}
            styleType={INPUTS.MEDIUM_LIGHT_BLUE}
            {...props}
          />
        </div>
        <div className="mb-6">
          <div className="text-2xl mb-2 flex">
            <label className="font-quickStand font-medium text-26 leading-32 text-white">
              {selectLabel}
            </label>
          </div>
          <CustomizedSelect
            type={SELECT_TYPES.LIGHT_BLUE}
            optionList={optionList}
            selectedOption={selectedoption}
            onChange={onSelectChange}
          />
        </div>
        <div className="mb-6">
          <div className="text-2xl mb-2 flex">
            <label className="font-quickStand font-medium text-26 leading-32 text-white">
              {textareaLabel}
            </label>
          </div>
          <CustomizedTextarea
            defaultValue={data?.description || ''}
            placeHolder={""}
            disabled={false}
            type={INPUTS.MEDIUM_LIGHT_BLUE}
            {...props}
          />
        </div>
        <div className="flex justify-between">
         {isCreate ? <div className="w-[35%]">
            <CustomizedFormButton
              text={CancelButtonText}
              btn_type={FORM_BUTTONS.DANGER}
              disabled={FormCancelBtnDisabled}
              type="submit"
              id="delete"
              {...props}
            />
          </div> : null}
          <div className={isCreate ? "w-[55%]" : "w-[100%]"}>
            <CustomizedFormButton
              text={SubmitButtonText}
              btn_type={FORM_BUTTONS.PRIMARY}
              disabled={FormSubmitBtnDisabled}
              type="submit"
              id="save"
              {...props}
            />
          </div>
        </div>
      </form>
    </>
  );
};
