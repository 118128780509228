import React from "react";

import mainImage from "../../../../assets/images/main-image.png";

export const RightComponent: React.FC<any> = () => {
  return (
    <div className="h-96">
      <img src={mainImage}
        className="h-auto w-full object-cover rounded-r-lg"
        alt=""
      />
    </div>
  );
};
