import React, { FC, ReactElement } from "react";

interface ILayerActivity {
  title: string;
  color: string;
  icon?: ReactElement;
  onClick: () => void;
  isSelected?: boolean;
  onMouseEnter?: (value: any) => void;
  onMouseLeave?: (value: any) => void;
}

export const LayerActivity: FC<ILayerActivity> = ({ title, color, icon, onClick, isSelected, onMouseEnter, onMouseLeave }) => {
  return (
    <button
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{backgroundColor: color}}
      className={`ml-3 px-4 py-2 font-semibold text-sm text-white rounded-full shadow-sm flex items-center ${isSelected ? "shadow-[0px_0px_6px_rgba(0,0,0,0.6)]" : ""}`}
    >{title} {icon}</button>
  )
}
