import React, { FC, SyntheticEvent } from "react";

import { BP_ADD_MODAL_DATA } from "../../store/constants/bp-constants";
import { Default } from "../core/modals/default/Default";

interface IAddBusinessPlanModal {
  onSubmitForm: (event: any) => void;
  closeModal: (e: SyntheticEvent) => void;
  data: any;
}

export const AddBusinessPlanModal: FC<IAddBusinessPlanModal> = ({
  onSubmitForm,
  closeModal,
  data,
}) => {
  return (
    <Default
      onSubmit={onSubmitForm}
      setShowModal={closeModal}
      showModal={data.visible}
      title={BP_ADD_MODAL_DATA.title}
      introduction={BP_ADD_MODAL_DATA.introduction}
      inputLabel={BP_ADD_MODAL_DATA.inputLabel}
      textareaLabel={BP_ADD_MODAL_DATA.textareaLabel}
      SubmitButtonText={BP_ADD_MODAL_DATA.SubmitButtonText}
      FormSubmitBtnDisabled={false}
      data={data}
    />
  );
};
