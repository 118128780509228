import React from "react";

export interface Props {
  isActive: boolean;
}

export const IconSixTen: React.FC<Props> = ({ isActive = false }: Props) => {
  return (
    <>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 170.08 170.08" style={{ ['enableBackground' as any]: 'new 0 0 170.08 170.08', 'filter': 'none' }} xmlSpace="preserve">
        <style type="text/css">{`
          svg:hover #SVGID_1_16{fill:#226a9f;}
          .st1{clip-path:url(#SVGID_00000056422307779823453150000012872795102946376876_);fill:#FFFFFF;}
          .st2{fill:#FFFFFF;}
        `}</style>
        <rect className="st0" width="170.08" height="170.08" />
        <g>
          <defs>
            <rect id="SVGID_1_16" width="170.08" height="170.08" />
          </defs>
          <use xlinkHref="#SVGID_1_16" style={{ 'overflow': 'visible', 'fill': isActive ? '#226a9f' : '#B2B2B2' }} />
          <clipPath id="SVGID_00000056422307779823453150000012872795102946376876_">
            <use xlinkHref="#SVGID_1_16" style={{ 'overflow': 'visible' }}/>
          </clipPath>
          <path className="st1" d="M43.18,40.31v-5.06
		c0-5.61-2.23-8.45-6.37-8.45c-2.43,0-3.89,1.11-4.75,2.78h-0.05V22.2c0-2.07,0.65-3.54,2.73-3.54c2.07,0,2.68,1.21,2.68,3.34v2.28
		h5.26v-1.82c0-5.06-1.67-8.6-8.04-8.6c-6.07,0-8.35,3.89-8.35,9.26v16.69c0,5.31,2.33,9.15,8.4,9.15
		C40.8,48.96,43.18,45.62,43.18,40.31 M37.47,40.82c0,2.17-0.81,3.44-2.78,3.44c-1.92,0-2.68-1.31-2.68-3.44v-6.27
		c0.1-1.92,0.91-3.14,2.73-3.14c1.97,0,2.73,1.22,2.73,3.44V40.82z"/>
          <path className="st1" d="M119.04,57.99
		c0,0-1.96,2.6-1.39,5.14c0.57,2.54,3.47,4.05,3.47,4.05s1.96-2.6,1.39-5.14C121.93,59.5,119.04,57.99,119.04,57.99"/>
          <path className="st1" d="M115.84,72.86
		c0,0,1.96-2.61,1.39-5.14c-0.57-2.54-3.47-4.04-3.47-4.04s-1.96,2.6-1.39,5.14C112.95,71.35,115.84,72.86,115.84,72.86"/>
          <path className="st1" d="M123.9,70.07
		c0,0,2.07,2.09,4.43,1.88c2.36-0.21,4.1-2.63,4.1-2.63s-2.07-2.09-4.43-1.88C125.65,67.64,123.9,70.07,123.9,70.07"/>
          <path className="st1" d="M127.29,58.92
		c-1.92,1.95-1.65,5.39-1.65,5.39s3.38,0.29,5.3-1.65c1.92-1.94,1.65-5.39,1.65-5.39S129.21,56.97,127.29,58.92"/>
          <path className="st1" d="M123.06,77.81
		c2.35,0.28,4.56-1.74,4.56-1.74s-1.6-2.47-3.95-2.75c-2.35-0.28-4.56,1.74-4.56,1.74S120.71,77.53,123.06,77.81"/>
          <path className="st1" d="M125.16,118.62H107.6
		c-1.37,0-2.49,1.11-2.49,2.48c0,1.37,1.11,2.49,2.49,2.49h17.56c1.37,0,2.48-1.11,2.48-2.49
		C127.64,119.73,126.53,118.62,125.16,118.62"/>
          <path className="st1" d="M125.16,146H107.6
		c-1.37,0-2.49,1.11-2.49,2.48c0,1.37,1.11,2.48,2.49,2.48h17.56c1.37,0,2.48-1.11,2.48-2.48C127.64,147.11,126.53,146,125.16,146"
          />
          <path className="st1" d="M46.19,112.59
		c-0.41-0.13-0.82-0.24-1.22-0.31C44.97,112.27,45.42,112.41,46.19,112.59"/>
          <path className="st1" d="M124.51,125.32h-16.27
		c-0.78,0-1.41,0.63-1.41,1.41v4.93l-10.62,1.17l-15.52-0.35v-9.48c2.06-0.26,4.13-0.67,6.17-1.25
		c14.94-4.21,18.19-16.56,17.87-25.11c-0.32-8.7,2.46-12.08,6.46-13.58c1.15-0.43,2.28-0.7,3.32-0.88c0.04,2.14,0.48,4.62,1.43,7.58
		c0.35-0.02,0.71-0.05,1.06-0.09c-1.01-3.03-1.44-5.52-1.46-7.64c2.07-0.25,3.54-0.11,3.54-0.11s-1.4-0.75-3.37-2.4
		c0.67-4.12,3.13-6.65,5.65-9.24c1.39-1.43,2.83-2.9,3.96-4.65l-0.88-0.57c-1.07,1.67-2.48,3.1-3.83,4.48
		c-2.47,2.52-4.96,5.1-5.8,9.19c-0.8-0.72-1.67-1.58-2.56-2.58c-3.68-4.13-8.43-1.1-15.19,5.2c-6.77,6.29-15.27,6.51-23.58-1.99
		C62.53,68.15,52.97,68.21,46.41,68.85c4.49,1.91,6.05,6.1,8.33,16.76c2.38,11.18,8.38,16.24,16.59,16.93
		c-4.57,1.61-4.79,4.19-10.91,8.41c-4.78,3.3-11.5,2.25-14.22,1.64c5,1.56,10.93,6.81,16.23,8.63c3.09,1.06,6.34,1.72,9.67,1.98
		v9.07l-27.8-0.63v6.33l51.91-1.19l10.62,1.17v4.92c0,0.78,0.63,1.42,1.41,1.42h16.27c0.78,0,1.42-0.64,1.42-1.42v-16.14
		C125.93,125.95,125.29,125.32,124.51,125.32 M74.65,123.33c1.16,0.02,2.32-0.01,3.48-0.09v9.17l-3.48-0.08V123.33z"/>
          <path className="st1" d="M42.77,136.92
		c0,1.33-0.93,2.42-2.08,2.42h-1.25c-1.14,0-2.08-1.08-2.08-2.42v-4.38c0-1.34,0.93-2.42,2.08-2.42h1.25c1.15,0,2.08,1.08,2.08,2.42
		V136.92z"/>
        </g>
        <polygon className="st2" points="48.78,28.15 50.72,28.15 50.72,23.07 52.66,23.07 52.66,21.5 50.72,21.5 50.72,18.2 53.32,18.2
	53.32,16.62 48.78,16.62 "/>
        <g>
          <path className="st2" d="M56.42,18.2c0.66,0,0.95,0.31,0.95,0.94v1.7c0,0.61-0.29,0.95-0.95,0.95h-0.65V18.2H56.42z M57.59,28.15h2.04
		l-1.85-5.24c1.11-0.25,1.55-0.99,1.55-2.23V19.2c0-1.62-0.71-2.59-2.67-2.59h-2.82v11.53h1.94v-5.29L57.59,28.15z"/>
          <polygon className="st2" points="60.04,28.15 64.67,28.15 64.67,26.57 61.98,26.57 61.98,23.07 63.9,23.07 63.9,21.5 61.98,21.5
		61.98,18.2 64.67,18.2 64.67,16.62 60.04,16.62 	"/>
          <path className="st2" d="M68.36,16.62h-2.99v11.53h2.99c2.07,0,2.81-1.33,2.81-3.1v-5.34C71.17,17.93,70.43,16.62,68.36,16.62
		 M67.3,26.57V18.2h0.82c0.8,0,1.11,0.48,1.11,1.16v6.04c0,0.7-0.31,1.17-1.11,1.17H67.3z"/>
          <polygon className="st2" points="73.92,26.3 72.08,26.3 71.85,29.05 72.93,29.05 73.92,26.45 	" />
          <path className="st2" d="M78.38,18.2c0.66,0,0.95,0.31,0.95,0.94v1.7c0,0.61-0.29,0.95-0.95,0.95h-0.65V18.2H78.38z M79.55,28.15h2.04
		l-1.85-5.24c1.11-0.25,1.55-0.99,1.55-2.23V19.2c0-1.62-0.71-2.59-2.67-2.59h-2.82v11.53h1.94v-5.29L79.55,28.15z"/>
          <polygon className="st2" points="82.34,28.15 86.97,28.15 86.97,26.57 84.28,26.57 84.28,23.07 86.2,23.07 86.2,21.5 84.28,21.5
		84.28,18.2 86.97,18.2 86.97,16.62 82.34,16.62 	"/>
          <polygon className="st2" points="87.44,18.2 89.04,18.2 89.04,28.15 91,28.15 91,18.2 92.6,18.2 92.6,16.62 87.44,16.62 	" />
          <polygon className="st2" points="93.5,28.15 95.44,28.15 95.44,23.07 97.38,23.07 97.38,21.5 95.44,21.5 95.44,18.2 98.04,18.2
		98.04,16.62 93.5,16.62 	"/>
          <path className="st2" d="M101.05,18.4h0.02v6.09h-1.45L101.05,18.4z M98.77,28.15l0.49-2.11h1.8v2.11h4.63v-1.58H103v-3.5h1.92V21.5
		H103v-3.3h2.69v-1.58h-5.82l-2.94,11.53H98.77z"/>
          <path className="st2" d="M109.32,18.2c0.66,0,0.95,0.31,0.95,0.94v1.7c0,0.61-0.29,0.95-0.95,0.95h-0.65V18.2H109.32z M110.49,28.15
		h2.04l-1.85-5.24c1.11-0.25,1.55-0.99,1.55-2.23V19.2c0-1.62-0.71-2.59-2.67-2.59h-2.82v11.53h1.94v-5.29L110.49,28.15z"/>
          <path className="st2" d="M116.27,16.62h-2.99v11.53h2.99c2.07,0,2.81-1.33,2.81-3.1v-5.34C119.08,17.93,118.35,16.62,116.27,16.62
		 M115.22,26.57V18.2h0.82c0.8,0,1.11,0.48,1.11,1.16v6.04c0,0.7-0.31,1.17-1.11,1.17H115.22z"/>
          <rect x="120.18" y="16.62" className="st2" width="1.94" height="11.53" />
          <path className="st2" d="M126.17,23.52h0.92v2.04c0,0.75-0.32,1.11-0.95,1.11c-0.66,0-0.95-0.49-0.95-1.19v-6.19
		c0-0.7,0.31-1.19,1-1.19c0.7,0,0.97,0.41,0.97,1.12v1.17h1.77v-1.02c0-1.7-0.63-2.89-2.77-2.89c-2.04,0-2.91,1.31-2.91,3.11v5.58
		c0,1.79,0.77,3.11,2.43,3.11c1.05,0,1.6-0.53,1.85-1.39v1.26h1.39v-6.16h-2.76V23.52z"/>
          <polygon className="st2" points="133.84,28.15 135.8,28.15 135.8,16.62 133.84,16.62 133.84,21.5 131.97,21.5 131.97,16.62
		130.03,16.62 130.03,28.15 131.97,28.15 131.97,23.07 133.84,23.07 	"/>
          <polygon className="st2" points="136.9,28.15 141.53,28.15 141.53,26.57 138.84,26.57 138.84,23.07 140.76,23.07 140.76,21.5
		138.84,21.5 138.84,18.2 141.53,18.2 141.53,16.62 136.9,16.62 	"/>
          <path className="st2" d="M145.56,16.62h-2.99v11.53h2.99c2.07,0,2.81-1.33,2.81-3.1v-5.34C148.37,17.93,147.64,16.62,145.56,16.62
		 M144.51,26.57V18.2h0.82c0.8,0,1.11,0.48,1.11,1.16v6.04c0,0.7-0.31,1.17-1.11,1.17H144.51z"/>
          <path className="st2" d="M154.86,28.29c2.06,0,2.94-1.33,2.94-3.11V19.6c0-1.8-0.88-3.11-2.94-3.11c-2.04,0-2.93,1.31-2.93,3.11v5.58
		C151.94,26.96,152.82,28.29,154.86,28.29 M154.86,26.67c-0.68,0-0.99-0.49-0.99-1.19v-6.19c0-0.7,0.31-1.19,0.99-1.19
		c0.7,0,1,0.49,1,1.19v6.19C155.87,26.18,155.56,26.67,154.86,26.67"/>
          <path className="st2" d="M161.84,23.52h0.92v2.04c0,0.75-0.32,1.11-0.95,1.11c-0.66,0-0.95-0.49-0.95-1.19v-6.19
		c0-0.7,0.31-1.19,1-1.19c0.7,0,0.97,0.41,0.97,1.12v1.17h1.77v-1.02c0-1.7-0.63-2.89-2.77-2.89c-2.04,0-2.91,1.31-2.91,3.11v5.58
		c0,1.79,0.77,3.11,2.43,3.11c1.05,0,1.6-0.53,1.85-1.39v1.26h1.39v-6.16h-2.76V23.52z"/>
          <path className="st2" d="M51.42,48.7c1.97,0,2.82-1.12,2.82-2.7v-0.56c0-1.28-0.51-1.92-1.56-2.99l-1.24-1.23
		c-0.56-0.54-0.92-0.92-0.92-1.56v-0.24c0-0.65,0.37-0.95,0.92-0.95c0.62,0,0.94,0.32,0.94,0.99v1h1.77v-0.94
		c0-1.67-0.77-2.62-2.72-2.62c-1.89,0-2.72,1.11-2.72,2.53v0.39c0,1.33,0.51,1.99,1.62,3.08l1.14,1.14c0.6,0.56,0.87,0.97,0.87,1.63
		v0.44c0,0.61-0.27,1.02-0.88,1.02c-0.66,0-0.95-0.37-0.95-1.02v-1.58h-1.8v1.55C48.68,47.59,49.45,48.7,51.42,48.7"/>
          <polygon className="st2" points="54.11,38.61 55.7,38.61 55.7,48.56 57.66,48.56 57.66,38.61 59.26,38.61 59.26,37.03 54.11,37.03 	" />
          <path className="st2" d="M62.92,38.82h0.02v6.09h-1.45L62.92,38.82z M60.64,48.56l0.49-2.11h1.8v2.11h4.63v-1.58h-2.69v-3.5h1.92v-1.57
		h-1.92v-3.3h2.69v-1.58h-5.82L58.8,48.56H60.64z"/>
          <path className="st2" d="M71.18,38.61c0.66,0,0.95,0.31,0.95,0.94v1.7c0,0.61-0.29,0.95-0.95,0.95h-0.65v-3.59H71.18z M72.36,48.56
		h2.04l-1.85-5.24c1.11-0.25,1.55-0.99,1.55-2.23v-1.48c0-1.62-0.71-2.59-2.67-2.59H68.6v11.53h1.94v-5.29L72.36,48.56z"/>
          <polygon className="st2" points="79.11,48.56 81.15,48.56 78.85,42.22 81.1,37.03 79.19,37.03 77.08,42.1 77.08,37.03 75.15,37.03
		75.15,48.56 77.08,48.56 77.08,42.95 	"/>
          <polygon className="st2" points="81.88,48.56 86.51,48.56 86.51,46.98 83.82,46.98 83.82,43.48 85.74,43.48 85.74,41.91 83.82,41.91
		83.82,38.61 86.51,38.61 86.51,37.03 81.88,37.03 	"/>
          <rect x="90.35" y="37.03" className="st2" width="1.94" height="11.53" />
          <polygon className="st2" points="98,48.56 99.71,48.56 99.71,37.03 98.09,37.03 98.09,43.65 96,37.03 94.09,37.03 94.09,48.56
		95.73,48.56 95.73,41.32 	"/>
          <path className="st2" d="M103.45,48.7c1.97,0,2.82-1.12,2.82-2.7v-0.56c0-1.28-0.51-1.92-1.56-2.99l-1.24-1.23
		c-0.56-0.54-0.92-0.92-0.92-1.56v-0.24c0-0.65,0.37-0.95,0.92-0.95c0.62,0,0.94,0.32,0.94,0.99v1h1.77v-0.94
		c0-1.67-0.77-2.62-2.72-2.62c-1.89,0-2.72,1.11-2.72,2.53v0.39c0,1.33,0.51,1.99,1.62,3.08l1.14,1.14c0.6,0.56,0.87,0.97,0.87,1.63
		v0.44c0,0.61-0.27,1.02-0.88,1.02c-0.66,0-0.95-0.37-0.95-1.02v-1.58h-1.8v1.55C100.71,47.59,101.47,48.7,103.45,48.7"/>
          <polygon className="st2" points="106.13,38.61 107.73,38.61 107.73,48.56 109.69,48.56 109.69,38.61 111.29,38.61 111.29,37.03
		106.13,37.03 	"/>
          <rect x="112.19" y="37.03" className="st2" width="1.94" height="11.53" />
          <polygon className="st2" points="115.03,38.61 116.63,38.61 116.63,48.56 118.59,48.56 118.59,38.61 120.18,38.61 120.18,37.03
		115.03,37.03 	"/>
          <path className="st2" d="M124.93,37.03v8.91c0,0.68-0.27,1.14-0.95,1.14c-0.68,0-0.95-0.46-0.95-1.14v-8.91h-1.94v8.73
		c0,1.79,0.85,2.94,2.89,2.94c1.97,0,2.74-1.16,2.74-2.94v-8.73H124.93z"/>
          <polygon className="st2" points="127.26,38.61 128.86,38.61 128.86,48.56 130.81,48.56 130.81,38.61 132.41,38.61 132.41,37.03
		127.26,37.03 	"/>
          <rect x="133.31" y="37.03" className="st2" width="1.94" height="11.53" />
          <path className="st2" d="M139.3,48.7c2.06,0,2.94-1.33,2.94-3.11v-5.58c0-1.8-0.88-3.11-2.94-3.11c-2.04,0-2.93,1.31-2.93,3.11v5.58
		C136.38,47.37,137.26,48.7,139.3,48.7 M139.3,47.08c-0.68,0-0.99-0.49-0.99-1.19V39.7c0-0.7,0.31-1.19,0.99-1.19
		c0.7,0,1,0.49,1,1.19v6.19C140.31,46.59,140,47.08,139.3,47.08"/>
          <polygon className="st2" points="147.26,48.56 148.96,48.56 148.96,37.03 147.35,37.03 147.35,43.65 145.25,37.03 143.35,37.03
		143.35,48.56 144.98,48.56 144.98,41.32 	"/>
          <polygon className="st2" points="150.41,48.56 155.03,48.56 155.03,46.98 152.35,46.98 152.35,43.48 154.27,43.48 154.27,41.91
		152.35,41.91 152.35,38.61 155.03,38.61 155.03,37.03 150.41,37.03 	"/>
          <path className="st2" d="M158.66,38.61c0.66,0,0.95,0.31,0.95,0.94v1.7c0,0.61-0.29,0.95-0.95,0.95h-0.65v-3.59H158.66z M159.83,48.56
		h2.04l-1.85-5.24c1.11-0.25,1.55-0.99,1.55-2.23v-1.48c0-1.62-0.71-2.59-2.67-2.59h-2.82v11.53h1.94v-5.29L159.83,48.56z"/>
        </g>
        <polygon className="st2" points="13.09,20.48 13.09,26.5 13.14,26.5 17.18,22.31 17.23,22.31 17.23,48.56 23,48.56 23,14.26 18.4,14.26
	"/>
      </svg>
    </>
  );
};
