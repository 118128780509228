import React, { FC } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../store/store";
import { GOAL_ICONS } from '../core/icons/goals';

const RedLayerGoal: FC<any> = ({ isEditor, layerGoal, openGoalModal, deleteLayerGoal }) => {
  const GoalIcon = GOAL_ICONS[layerGoal.goals[0]?.icon];
  return (
    <div className="xl:w-[31.7%] md:w-[48.5%] sm:w-[48.5%] h-fit flex bg-[#C84441] rounded-l-[10px] rounded-xl overflow-hidden">
      <div id="layer_goal_icon" className="w-28 h-full flex items-center">{GoalIcon && <GoalIcon isActive />}</div>
      <div className="w-2/3 h-[100px] p-2 text-sm relative overflow-hidden">
      <div className="font-quickStand text-2xl whitespace-nowrap font-bold xl:mb-0 mb-[3px]">{layerGoal.title}</div>
        <p className="font-quickStand text-[1rem] xl:text-[14px] whitespace-nowrap leading-none font-medium">{layerGoal.category?.iconTitle}</p>
        <p className="font-quickStand text-[1rem] xl:text-[14px] font-medium">{layerGoal.goals[0]?.title}</p>
        {isEditor && (
          <div className="font-quickStand text-1xl absolute flex left-2 right-2 bottom-2 justify-between font-semibold">
            <button onClick={openGoalModal}>Rediger</button>
            <button className="ml-2" onClick={() => deleteLayerGoal(layerGoal.id)}>Slet</button>
          </div>
        )}
      </div>
    </div>
  );
}

const mapState = (state: RootState) => ({
  isEditor: state.user.isEditor,
});

export default connect(mapState)(RedLayerGoal);

