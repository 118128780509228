import React, {ChangeEvent, FC, useEffect} from "react";
import { connect } from "react-redux";

import { Dispatch, RootState } from "../../store/store";
import ProfilePageForm from "./form";

const ProfilePage: FC<any> = ({ signOut, getOwner, user }) => {

  useEffect(() => {
    getOwner();
  },[] );

  return (
    <div className="overflow-auto py-50 px-[110px]">
      {user && <ProfilePageForm user={user}/>}
    </div>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.data,
});

const mapDispatch = (dispatch: Dispatch) => ({
  signOut: dispatch.user.signOut,
  updateProfile: dispatch.user.updateProfile,
  uploadProfileImage: dispatch.user.uploadProfileImage,
  fullSignOut: dispatch.user.fullSignOut,
  getOwner: dispatch.user.getOwner
});

export default connect(mapState, mapDispatch)(ProfilePage);
