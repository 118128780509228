import { IconEight } from "./IconEight";
import { IconFive } from "./IconFive";
import { IconFour } from "./IconFour";
import { IconNine } from "./IconNine";
import { IconOne } from "./IconOne";
import { IconSeven } from "./IconSeven";
import { IconSix } from "./IconSix";
import { IconThree } from "./IconThree";
import { IconTwo } from "./IconTwo";
import { IconTen } from "./IconTen";
import { IconEleven } from "./IconEleven";
import { IconTwelve } from "./IconTwelve";
import { IconThreeTen } from "./IconThreeTen";
import { IconFourTen } from "./IconFourTen";
import { IconSevenTen } from "./IconSevenTen";
import { IconFiveTen } from "./IconFiveTen";
import { IconSixTen } from "./IconSixTen";

export const RedLayerIcons: any = {
  IconOne,
  IconTwo,
  IconThree,
  IconFour,
  IconFive,
  IconSix,
  IconSeven,
  IconEight,
  IconNine,
  IconTen,
  IconEleven,
  IconTwelve,
  IconThreeTen,
  IconFourTen,
  IconSevenTen,
  IconSixTen,
  IconFiveTen
}
