import classNames from "classnames";
import { useFormik } from "formik";
import React, { FC } from "react";
import { connect } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { CustomizedLabelInput } from "../../components/core/inputs/CustomizedLabelInput";
import { INPUTS } from "../../constants/ui";
import { SIGN_IN_ROUTE } from "../../store/constants/route-constants";
import { Dispatch, RootState } from "../../store/store";
import { RegisterPageWrapper } from "../RegisterPageWrapper";

type IPasswordValues = Record<string, string>;

type ResetPasswordProps = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch>;
const ResetPasswordPage: FC<ResetPasswordProps> = ({
  isAuthenticated,
  resetPassword,
}) => {
  const navigate = useNavigate();

  const passwordValues: IPasswordValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const formik = useFormik({
    initialValues: passwordValues,
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .min(6, "Minimum 6 characters required")
        .required("Required"),
      newPassword: Yup.string()
        .min(6, "Minimum 6 characters required")
        .required("Required"),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("newPassword"), null],
        "Passwords must match"
      ),
    }),
    onSubmit: async (values) => {
      await resetPassword({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
      });
      navigate(SIGN_IN_ROUTE);
    },
  });

  const inputs = [
    { id: 0, label: "Old Password", name: "oldPassword" },
    { id: 1, label: "New Password", name: "newPassword" },
    { id: 2, label: "Confirm Password", name: "confirmPassword" },
  ];

  return (
    <RegisterPageWrapper
      title="Indstil nyt kodeord"
      action={{
        title: "Indstil ny kode",
        handler: formik.submitForm,
        disabled: !formik.isValid,
      }}
    >
      <div className="mb-6">
        <CustomizedLabelInput
          id="oldPassword"
          value={formik.values.oldPassword}
          onChange={formik.handleChange}
          type="password"
          error={formik.values.oldPassword && formik.errors.oldPassword}
          label="Gammelt kodeord"
          placeHolder="Din kodeord…"
          styleType={formik.values.oldPassword && formik.errors.oldPassword ? INPUTS.ERROR : INPUTS.DEFAULT_FULL_WIDTH}
          disabled={false}
        />
      </div>
      <div className="mb-6">
        <CustomizedLabelInput
          id="newPassword"
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          type="password"
          error={formik.values.newPassword && formik.errors.newPassword}
          label="Nyt kodeord"
          placeHolder="Din kodeord…"
          styleType={formik.values.newPassword && formik.errors.newPassword ? INPUTS.ERROR : INPUTS.DEFAULT_FULL_WIDTH}
          disabled={false}
        />
      </div>
      <div className="mb-6">
        <CustomizedLabelInput
          id="confirmPassword"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          type="password"
          error={formik.values.confirmPassword && formik.errors.confirmPassword}
          label="Bekræft nyt kodeord"
          placeHolder="Din kodeord…"
          styleType={formik.values.confirmPassword && formik.errors.confirmPassword ? INPUTS.ERROR : INPUTS.DEFAULT_FULL_WIDTH}
          disabled={false}
        />
      </div>
    </RegisterPageWrapper>
  );
};

const mapState = (state: RootState) => ({
  isAuthenticated: state.user?.isAuthenticated,
});

const mapDispatch = (dispatch: Dispatch) => ({
  resetPassword: dispatch.user.resetPassword,
});

export default connect(mapState, mapDispatch)(ResetPasswordPage);
