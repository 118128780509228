export const HOME_ROUTE = "/";
export const SIGN_IN_ROUTE = "/sign-in";
export const SIGN_UP_ROUTE = "/sign-up";
export const RESET_PASSWORD_ROUTE = "/reset-password";
export const SIGN_UP_COMPANY_ROUTE = "/sign-up-company";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password";
export const USER_REGISTERED_ROUTE = "/user-registered";
export const REGISTERED_MAIL_ROUTE = "/registered-mail";
export const APPROVED_MAIL_ROUTE = "/approved-mail";
export const BUSINESS_PLAN_ROUTE = "/business-plan";
export const BUSINESS_PLAN_ID_ROUTE = BUSINESS_PLAN_ROUTE + "/:id";
export const EMPTY_BUSINESS_PLAN_ROUTE = "/business-plan/new";
export const LAEYR_ROUTE = BUSINESS_PLAN_ID_ROUTE + "/layer/:layer_id";
export const RED_LAEYR_ROUTE = BUSINESS_PLAN_ID_ROUTE + "/red-layer/:layer_id";
export const COMPANY_INVITE_LINK = "/invite/:inviteLink";
export const PROFILE_ROUTE = "/profile";

export const getBPUrl = (id: string) => `${BUSINESS_PLAN_ROUTE}/${id}`;
export const getLayerUrl = (bpId: string, layerId: string, type: string) => `${BUSINESS_PLAN_ROUTE}/${bpId}/${type}/${layerId}`;

