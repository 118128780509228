import React from "react";

import { Modal } from "../../Modal";
import { SelectOptionProps } from "../../Select";
import { LeftComponent } from "../common/LeftComponent";
import { RightComponent } from "./RightComponent";

export interface Props {
  showModal: boolean;
  title: string;
  introduction: string;
  body: string;
  listtitle: string;
  liitems: string[] | null;
  inputLabel: string;
  selectLabel: string;
  optionList: SelectOptionProps[];
  textareaLabel: string;
  SubmitButtonText: string;
  FormSubmitBtnDisabled: boolean;
  CancelButtonText: string;
  FormCancelBtnDisabled: boolean;
  data?: any;
  setShowModal?: () => void;
  onSubmit?: (e: any) => void;
  selectedoption: SelectOptionProps;
  onSelectChange?: (e: any) => void;
  bgColor: string;
}

export const LayerTagModal: React.FC<Props> = ({ showModal, bgColor, ...props }: Props) => {
  return (
    <>
      <Modal
        LeftComponent={LeftComponent}
        RightComponent={RightComponent}
        rightcomponentbackgroundcolor={bgColor}
        showModal={showModal}
        {...props}
      />
    </>
  );
};
