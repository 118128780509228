import { IconOneOne } from "./IconOneOne";
import { IconOneTwo } from "./IconOneTwo";
import { IconOneThree } from "./IconOneThree";
import { IconOneFour } from "./IconOneFour";
import { IconOneFive } from "./IconOneFive";
import { IconOneSix } from "./IconOneSix";
import { IconOneSeven } from "./IconOneSeven";
import { IconTwoOne } from "./IconTwoOne";
import { IconTwoTwo } from "./IconTwoTwo";
import { IconTwoThree } from "./IconTwoThree";
import { IconTwoFour } from "./IconTwoFour";
import { IconTwoFive } from "./IconTwoFive";
import { IconTwoSix } from "./IconTwoSix";
import { IconTwoSeven } from "./IconTwoSeven";
import { IconTwoEight } from "./IconTwoEight";
import { IconSeventeenOne } from "./IconSeventeenOne";
import { IconSeventeenTwo } from "./IconSeventeenTwo";
import { IconSeventeenThree } from "./IconSeventeenThree";
import { IconSeventeenFour } from "./IconSeventeenFour";
import { IconSeventeenFive } from "./IconSeventeenFive";
import { IconSeventeenSix } from "./IconSeventeenSix";
import { IconSeventeenEight } from "./IconSeventeenEight";
import { IconSeventeenNine } from "./IconSeventeenNine";
import { IconSeventeenTen } from "./IconSeventeenTeen";
import { IconSeventeenEleven } from "./IconSeventeenEleven";
import { IconSeventeenTwelve } from "./IconSeventeenTwelve";
import { IconSeventeenThirteen } from "./IconSeventeenThirteen";
import { IconSeventeenFourteen } from "./IconSeventeenFourteen";
import { IconSeventeenFifteen } from "./IconSeventeenFiveteen";
import { IconSeventeenSixteen } from "./IconSeventeenSixteen";
import { IconSeventeenSeventeen } from "./IconSeventeenSeventeen";
import { IconSeventeenEighteen } from "./IconSeventeenEightteen";
import { IconSeventeenNineteen } from "./IconSeventeenNineteen";
import { IconSeventeenSeven } from "./IconSeventeenSeven";
import { IconSixteenOne } from "./IconSixteenOne";
import { IconSixteenTwo } from "./IconSixteenTwo";
import { IconSixteenThree } from "./IconSixteenThree";
import { IconSixteenFour } from "./IconSixteenFour";
import { IconSixteenFive } from "./IconSixteenFive";
import { IconSixteenSix } from "./IconSixteenSix";
import { IconSixteenSeven } from "./IconSixteenSeven";
import { IconSixteenEight } from "./IconSixteenEight";
import { IconSixteenNine } from "./IconSixteenNine";
import { IconSixteenTen } from "./IconSixteenTeen";
import { IconSixteenEleven } from "./IconSixteenTeenA";
import { IconSixteenTwelve } from "./IconSixteenTeenB";
import { IconFifteenOne } from "./IconFiveteenOne";
import { IconFifteenTwo } from "./IconFiveteenTwo";
import { IconFifteenThree } from "./IconFiveteenThree";
import { IconFifteenFour } from "./IconFiveteenFour";
import { IconFifteenFive } from "./IconFiveteenFive";
import { IconFifteenSix } from "./IconFiveteenSix";
import { IconFifteenSeven } from "./IconFiveteenSeven";
import { IconFifteenEight } from "./IconFiveteenEight";
import { IconFifteenNine } from "./IconFiveteenNine";
import { IconFifteenTen } from "./IconFiveteenA";
import { IconFifteenEleven } from "./IconFiveteenB";
import { IconFifteenTwelve } from "./IconFiveteenC";
import { IconFourteenOne } from "./IconFourteenOne";
import { IconFourteenTwo } from "./IconFourteenTwo";
import { IconFourteenThree } from "./IconFourteenThree";
import { IconFourteenFour } from "./IconFourteenFour";
import { IconFourteenFive } from "./IconFourteenFive";
import { IconFourteenSix } from "./IconFourteenSix";
import { IconFourteenSeven } from "./IconFourteenSeven";
import { IconFourteenEight } from "./IconFourteenEight";
import { IconFourteenNine } from "./IconFourteenNine";
import { IconFourteenTen } from "./IconFourteenTeen";
import { IconThirteenOne } from "./IconThirteenOne";
import { IconThirteenTwo } from "./IconThirteenTwo";
import { IconThirteenThree } from "./IconThirteenThree";
import { IconThirteenFour } from "./IconThirteenFour";
import { IconThirteenFive } from "./IconThirteenFive";
import { IconTwelveOne } from "./IconTwelveOne";
import { IconTwelveTwo } from "./IconTwelveTwo";
import { IconTwelveThree } from "./IconTwelveThree";
import { IconTwelveFour } from "./IconTwelveFour";
import { IconTwelveFive } from "./IconTwelveFive";
import { IconTwelveSix } from "./IconTwelveSix";
import { IconTwelveSeven } from "./IconTwelveSeven";
import { IconTwelveEight } from "./IconTwelveEight";
import { IconTwelveNine } from "./IconTwelveNine";
import { IconTwelveTen } from "./IconTwelveTen";
import { IconTwelveEleven } from "./IconTwelveEleven";
import { IconElevenTen } from "./IconElevenTen";
import { IconElevenNine } from "./IconElevenNine";
import { IconElevenEight } from "./IconElevenEight";
import { IconElevenSeven } from "./IconElevenSeven";
import { IconElevenSix } from "./IconElevenSix";
import { IconElevenFive } from "./IconElevenFive";
import { IconThreeOne } from "./IconThreeOne";
import { IconThreeTwo } from "./IconThreeTwo";
import { IconThreeThree } from "./IconThreeThree";
import { IconThreeFour } from "./IconThreeFour";
import { IconThreeFive } from "./IconThreeFive";
import { IconThreeSix } from "./IconThreeSix";
import { IconThreeSeven } from "./IconThreeSeven";
import { IconThreeEight } from "./IconThreeEight";
import { IconThreeNine } from "./IconThreeNine";
import { IconThreeTen } from "./IconThreeTen";
import { IconThreeEleven } from "./IconThreeEleven";
import { IconThreeTwelve } from "./IconThreeTwelve";
import { IconThreeThirteen } from "./IconThreeThirteen";
import { IconFourOne } from "./IconFourOne";
import { IconFourTwo } from "./IconFourTwo";
import { IconFourThree } from "./IconFourThree";
import { IconFourFour } from "./IconFourFour";
import { IconFourFive } from "./IconFourFive";
import { IconFourSix } from "./IconFourSix";
import { IconFourSeven } from "./IconFourSeven";
import { IconFourEight } from "./IconFourEight";
import { IconFourNine } from "./IconFourNine";
import { IconFourTen } from "./IconFourTen";
import { IconFiveOne } from "./IconFiveOne";
import { IconFiveTwo } from "./IconFiveTwo";
import { IconFiveThree } from "./IconFiveThree";
import { IconFiveFour } from "./IconFiveFour";
import { IconFiveFive } from "./IconFiveFive";
import { IconFiveSix } from "./IconFiveSix";
import { IconFiveSeven } from "./IconFiveSeven";
import { IconFiveEight } from "./IconFiveEight";
import { IconFiveNine } from "./IconFiveNine";
import { IconSixOne } from "./IconSixOne";
import { IconSixTwo } from "./IconSixTwo";
import { IconSixThree } from "./IconSixThree";
import { IconSixFour } from "./IconSixFour";
import { IconSixFive } from "./IconSixFive";
import { IconSixSix } from "./IconSixSix";
import { IconSixSeven } from "./IconSixSeven";
import { IconSixEight } from "./IconSixEight";
import { IconSevenOne } from "./IconSevenOne";
import { IconSevenTwo } from "./IconSevenTwo";
import { IconSevenThree } from "./IconSevenThree";
import { IconSevenFour } from "./IconSevenFour";
import { IconSevenFive } from "./IconSevenFive";
import { IconEightOne } from "./IconEightOne";
import { IconEightTwo } from "./IconEightTwo";
import { IconEightThree } from "./IconEightThree";
import { IconEightFour } from "./IconEightFour";
import { IconEightFive } from "./IconEightFive";
import { IconEightSix } from "./IconEightSix";
import { IconEightSeven } from "./IconEightSeven";
import { IconEightEight } from "./IconEightEight";
import { IconEightNine } from "./IconEightNine";
import { IconEightTen } from "./IconEightTen";
import { IconEightEleven } from "./IconEightEleven";
import { IconEightTwelve } from "./IconEightTwelve";
import { IconNineOne } from "./IconNineOne";
import { IconNineTwo } from "./IconNineTwo";
import { IconNineThree } from "./IconNineThree";
import { IconNineFour } from "./IconNineFour";
import { IconNineFive } from "./IconNineFive";
import { IconNineSix } from "./IconNineSix";
import { IconNineSeven } from "./IconNineSeven";
import { IconNineEight } from "./IconNineEight";
import { IconTenOne } from "./IconTenOne";
import { IconTenTwo } from "./IconTenTwo";
import { IconTenThree } from "./IconTenThree";
import { IconTenFour } from "./IconTenFour";
import { IconTenFive } from "./IconTenFive";
import { IconTenSix } from "./IconTenSix";
import { IconTenSeven } from "./IconTenSeven";
import { IconTenEight } from "./IconTenEight";
import { IconTenNine } from "./IconTenNine";
import { IconTenTen } from "./IconTenTen";
import { IconElevenOne } from "./IconElevenOne";
import { IconElevenTwo } from "./IconElevenTwo";
import { IconElevenThree } from "./IconElevenThree";
import { IconElevenFour } from "./IconElevenFour";

export const GOAL_ICONS: any = {
  IconOneOne,
  IconOneTwo,
  IconOneThree,
  IconOneFour,
  IconOneFive,
  IconOneSix,
  IconOneSeven,
  IconTwoOne,
  IconTwoTwo,
  IconTwoThree,
  IconTwoFour,
  IconTwoFive,
  IconTwoSix,
  IconTwoSeven,
  IconTwoEight,
  IconSeventeenOne,
  IconSeventeenTwo,
  IconSeventeenThree,
  IconSeventeenFour,
  IconSeventeenFive,
  IconSeventeenSix,
  IconSeventeenSeven,
  IconSeventeenEight,
  IconSeventeenNine,
  IconSeventeenTen,
  IconSeventeenEleven,
  IconSeventeenTwelve,
  IconSeventeenThirteen,
  IconSeventeenFourteen,
  IconSeventeenFifteen,
  IconSeventeenSixteen,
  IconSeventeenSeventeen,
  IconSeventeenEighteen,
  IconSeventeenNineteen,
  IconSixteenOne,
  IconSixteenTwo,
  IconSixteenThree,
  IconSixteenFour,
  IconSixteenFive,
  IconSixteenSix,
  IconSixteenSeven,
  IconSixteenEight,
  IconSixteenNine,
  IconSixteenTen,
  IconSixteenEleven,
  IconSixteenTwelve,
  IconFifteenOne,
  IconFifteenTwo,
  IconFifteenThree,
  IconFifteenFour,
  IconFifteenFive,
  IconFifteenSix,
  IconFifteenSeven,
  IconFifteenEight,
  IconFifteenNine,
  IconFifteenTen,
  IconFifteenEleven,
  IconFifteenTwelve,
  IconFourteenOne,
  IconFourteenTwo,
  IconFourteenThree,
  IconFourteenFour,
  IconFourteenFive,
  IconFourteenSix,
  IconFourteenSeven,
  IconFourteenEight,
  IconFourteenNine,
  IconFourteenTen,
  IconThirteenOne,
  IconThirteenTwo,
  IconThirteenThree,
  IconThirteenFour,
  IconThirteenFive,
  IconTwelveOne,
  IconTwelveTwo,
  IconTwelveThree,
  IconTwelveFour,
  IconTwelveFive,
  IconTwelveSix,
  IconTwelveSeven,
  IconTwelveEight,
  IconTwelveNine,
  IconTwelveTen,
  IconTwelveEleven,
  IconElevenTen,
  IconElevenNine,
  IconElevenEight,
  IconElevenSeven,
  IconElevenSix,
  IconElevenFive,
  IconThreeOne,
  IconThreeTwo,
  IconThreeThree,
  IconThreeFour,
  IconThreeFive,
  IconThreeSix,
  IconThreeSeven,
  IconThreeEight,
  IconThreeNine,
  IconThreeTen,
  IconThreeEleven,
  IconThreeTwelve,
  IconThreeThirteen,
  IconFourOne,
  IconFourTwo,
  IconFourThree,
  IconFourFour,
  IconFourFive,
  IconFourSix,
  IconFourSeven,
  IconFourEight,
  IconFourNine,
  IconFourTen,
  IconFiveOne,
  IconFiveTwo,
  IconFiveThree,
  IconFiveFour,
  IconFiveFive,
  IconFiveSix,
  IconFiveSeven,
  IconFiveEight,
  IconFiveNine,
  IconSixOne,
  IconSixTwo,
  IconSixThree,
  IconSixFour,
  IconSixFive,
  IconSixSix,
  IconSixSeven,
  IconSixEight,
  IconSevenOne,
  IconSevenTwo,
  IconSevenThree,
  IconSevenFour,
  IconSevenFive,
  IconEightOne,
  IconEightTwo,
  IconEightThree,
  IconEightFour,
  IconEightFive,
  IconEightSix,
  IconEightSeven,
  IconEightEight,
  IconEightNine,
  IconEightTen,
  IconEightEleven,
  IconEightTwelve,
  IconNineOne,
  IconNineTwo,
  IconNineThree,
  IconNineFour,
  IconNineFive,
  IconNineSix,
  IconNineSeven,
  IconNineEight,
  IconTenOne,
  IconTenTwo,
  IconTenThree,
  IconTenFour,
  IconTenFive,
  IconTenSix,
  IconTenSeven,
  IconTenEight,
  IconTenNine,
  IconTenTen,
  IconElevenOne,
  IconElevenTwo,
  IconElevenThree,
  IconElevenFour,
};
