import React from "react";

export interface Props {
  isActive: boolean;
}

export const IconSix: React.FC<Props> = ({ isActive = false }: Props) => {
  return (
    <>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 170.08 170.08" style={{ ['enableBackground' as any]: 'new 0 0 170.08 170.08', 'filter': 'none' }} xmlSpace="preserve">
        <style type="text/css">
          {`  .st06{fill:${isActive ? '#40bfe6' : '#B2B2B2'};}
            svg:hover .st06{fill:#40bfe6;}
        .st1{fill:#FFFFFF;}`}
        </style>
        <rect className="st06" width="170.08" height="170.08" />
        <g>
          <path className="st1" d="M25.73,49c6.12,0,8.5-3.34,8.5-8.65v-5.06c0-5.61-2.23-8.45-6.37-8.45c-2.43,0-3.89,1.11-4.75,2.78h-0.05
		v-7.39c0-2.08,0.66-3.54,2.73-3.54s2.68,1.22,2.68,3.34v2.28h5.26v-1.82c0-5.06-1.67-8.6-8.04-8.6c-6.07,0-8.35,3.89-8.35,9.25
		v16.69C17.34,45.15,19.66,49,25.73,49 M23.05,34.58c0.1-1.92,0.91-3.13,2.73-3.13c1.97,0,2.73,1.21,2.73,3.44v5.97
		c0,2.17-0.81,3.44-2.78,3.44c-1.92,0-2.68-1.31-2.68-3.44V34.58z"/>
          <path className="st1" d="M116.36,68.77c-0.15-0.16-0.34-0.24-0.56-0.24H54.1c-0.21,0-0.41,0.09-0.55,0.24
		c-0.13,0.17-0.19,0.38-0.15,0.58l10.37,62.7c0.06,0.35,0.36,0.6,0.71,0.6h12.72l0.87,10.21l-5.35,0.01l12.46,12.46l12.49-12.49
		l-5.35,0.01l0.87-10.2h12.95c0.36,0,0.66-0.26,0.71-0.61l9.7-62.69C116.56,69.14,116.5,68.94,116.36,68.77 M92.52,118.97
		c0,0-2.8,2.88-7.36,2.57c-4.57,0.31-7.37-2.57-7.37-2.57c-4.62-4.62-2.97-10.36-1.36-13.33c0.99-1.86,8.1-12.53,8.71-13.43v-0.07
		c0,0,0.01,0.01,0.03,0.03l0.03-0.03v0.07c0.59,0.91,7.71,11.57,8.7,13.43C95.49,108.6,97.14,114.35,92.52,118.97 M112.09,81.96
		c-1.67-0.74-3.79-0.47-5.83,0.35c-7.66,3.1-12.87,4.22-20.92,1.7c-5.07-1.59-9.82-4.91-15.42-2.89c-3.72,1.34-7.19,2.62-11.4,2.58
		l-2.43-12.46h57.75L112.09,81.96z"/>
          <path className="st1" d="M52.09,15.82c0.82,0,1.18,0.38,1.18,1.16v2.11c0,0.76-0.36,1.18-1.18,1.18h-0.8v-4.45H52.09z M53.55,28.15
		h2.53l-2.3-6.5c1.37-0.32,1.92-1.22,1.92-2.76v-1.83c0-2-0.89-3.21-3.31-3.21h-3.5v14.3h2.4V21.6L53.55,28.15z"/>
          <polygon className="st1" points="57,28.15 62.74,28.15 62.74,26.19 59.41,26.19 59.41,21.85 61.79,21.85 61.79,19.91 59.41,19.91
		59.41,15.82 62.74,15.82 62.74,13.85 57,13.85 	"/>
          <polygon className="st1" points="68.88,28.15 70.99,28.15 70.99,13.85 68.98,13.85 68.98,22.06 66.39,13.85 64.03,13.85 64.03,28.15
		66.05,28.15 66.05,19.17 	"/>
          <polygon className="st1" points="72.08,15.82 74.07,15.82 74.07,28.15 76.49,28.15 76.49,15.82 78.47,15.82 78.47,13.86 72.08,13.86
		"/>
          <polygon className="st1" points="87.25,13.86 85.56,23.83 85.54,23.83 83.89,13.86 81.45,13.86 84.17,28.15 86.78,28.15 89.5,13.86
		"/>
          <path className="st1" d="M92.82,17.88h0.02l0.95,5.74h-1.92L92.82,17.88z M91.13,28.15l0.42-2.62h2.55l0.44,2.62h2.47l-2.72-14.3h-2.74
		l-2.7,14.3H91.13z"/>
          <polygon className="st1" points="102.85,28.15 104.96,28.15 104.96,13.85 102.96,13.85 102.96,22.06 100.37,13.85 98,13.85 98,28.15
		100.03,28.15 100.03,19.17 	"/>
          <path className="st1" d="M110.47,13.86h-3.71v14.3h3.71c2.57,0,3.48-1.64,3.48-3.84v-6.62C113.95,15.48,113.04,13.86,110.47,13.86
		 M109.16,26.19V15.82h1.01c0.99,0,1.37,0.59,1.37,1.43v7.49c0,0.87-0.38,1.46-1.37,1.46H109.16z"/>
          <path className="st1" d="M52.51,48.74c2.55,0,3.65-1.64,3.65-3.86v-6.92c0-2.23-1.1-3.86-3.65-3.86c-2.53,0-3.63,1.62-3.63,3.86v6.92
		C48.89,47.09,49.98,48.74,52.51,48.74 M52.51,46.73c-0.84,0-1.22-0.61-1.22-1.48v-7.68c0-0.87,0.38-1.48,1.22-1.48
		c0.86,0,1.24,0.61,1.24,1.48v7.68C53.76,46.12,53.38,46.73,52.51,46.73"/>
          <path className="st1" d="M61.1,42.83h1.14v2.53c0,0.93-0.4,1.37-1.18,1.37c-0.82,0-1.18-0.61-1.18-1.48v-7.68
		c0-0.87,0.38-1.48,1.24-1.48c0.87,0,1.2,0.51,1.2,1.39v1.46h2.19v-1.27c0-2.11-0.78-3.59-3.44-3.59c-2.53,0-3.61,1.62-3.61,3.86
		v6.92c0,2.21,0.95,3.86,3.02,3.86c1.31,0,1.98-0.65,2.3-1.73v1.56h1.73v-7.63H61.1V42.83z"/>
          <path className="st1" d="M71.24,48.74c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.67-1.14-1.14-1.14-1.94v-0.3c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.49c0,1.64,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.02
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96h-2.24v1.92C67.84,47.37,68.79,48.74,71.24,48.74"/>
          <path className="st1" d="M79.53,38.3h0.02l0.95,5.74h-1.92L79.53,38.3z M77.84,48.57l0.42-2.62h2.55l0.44,2.62h2.47L81,34.27h-2.74
		l-2.7,14.3H77.84z"/>
          <polygon className="st1" points="89.61,48.57 91.72,48.57 91.72,34.27 89.72,34.27 89.72,42.47 87.12,34.27 84.76,34.27 84.76,48.57
		86.78,48.57 86.78,39.58 	"/>
          <rect x="93.51" y="34.27" className="st1" width="2.4" height="14.3" />
          <polygon className="st1" points="96.61,36.23 98.59,36.23 98.59,48.57 101.02,48.57 101.02,36.23 103,36.23 103,34.27 96.61,34.27 	" />
          <polygon className="st1" points="104.12,48.57 109.86,48.57 109.86,46.61 106.52,46.61 106.52,42.26 108.91,42.26 108.91,40.32
		106.52,40.32 106.52,36.23 109.86,36.23 109.86,34.27 104.12,34.27 	"/>
          <polygon className="st1" points="110.87,36.23 112.85,36.23 112.85,48.57 115.28,48.57 115.28,36.23 117.26,36.23 117.26,34.27
		110.87,34.27 	"/>
        </g>
      </svg>
    </>
  );
};
