import React, { FC } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/store";

interface IBPModalPRops {
  isEditor: boolean;
  visible: boolean;
  data: any;
  close?: () => void;
  deleteBp?: () => void;
  updateBp?: () => void;
}

const TopLeftModal: FC<IBPModalPRops> = ({
  isEditor,
  visible,
  close,
  data,
  deleteBp,
  updateBp,
}) => {
  const getLayerCount = () => {
    return (
      Object.keys(data).filter((i: string) => i.includes("layer") && data[i]?.id)?.length || 0
    );
  };
  const getDate = (date: string) => date ? new Date(date).toLocaleDateString() : "-";

  return (
    <>
      <div
        className={`${
          !visible && "hidden"
        } w-96 h-[auto!important] overflow-y-auto overflow-x-hidden absolute top-32 left-4 z-50 w-full h-modal md:h-full drop-shadow-md`}
      >
        <div className="max-h-[60vh] p-4 bg-secondBg rounded-lg shadow overflow-y-auto">
          <p className="space-y-1 text-2xl font-bold mb-2">
            {data?.name || "-"}
          </p>
          <div className="space-y-1 text-1xl font-semibold flex items-center w-full justify-between">
            <div>Oprettet</div> <div>{getDate(data?.created_at)}</div>
          </div>
          <div className="space-y-1 text-1xl font-semibold flex items-center w-full justify-between">
            <div>Sidst ændret</div> <div>{getDate(data?.updated_at)}</div>
          </div>
          <div className="space-y-1 text-1xl font-semibold flex items-center w-full justify-between">
            <div>Lag udfyldt</div> <div>{getLayerCount()}</div>
          </div>

          <p className="mt-2 space-y-1 text-2xl font-bold mb-2">Beskrivelse</p>
          <p>{data?.description || ""}</p>

          {isEditor && (
            <div className="mt-6 w-full flex space-x-4 text-2xl text-white font-medium h-12">
              <button
                onClick={deleteBp}
                className="w-2/5 bg-redLayer rounded-l-lg rounded-r-lg"
              >
                Slet
              </button>
              <button
                onClick={updateBp}
                className="w-3/5 bg-greenLayer rounded-l-lg rounded-r-lg"
              >
                Redigér
              </button>
            </div>
          )}
        </div>
      </div>
      <div onClick={close} className="absolute inset-0 z-1" />
    </>
  );
};

const mapState = (state: RootState) => ({
  isEditor: state.user.isEditor,
});

export default connect(mapState)(TopLeftModal);
