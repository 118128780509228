import React from "react";

export interface Props {
  isActive: boolean;
}

export const IconSevenTen: React.FC<Props> = ({ isActive = false }: Props) => {
  return (
    <>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 170.08 170.08" style={{ ['enableBackground' as any]: 'new 0 0 170.08 170.08', 'filter': 'none' }} xmlSpace="preserve">
        <style type="text/css">
          {`  .st017{fill:${isActive ? '#14496b' : '#B2B2B2'};}
            svg:hover .st017{fill:#14496b;}
        .st1{fill:#FFFFFF;}`}
        </style>
        <rect className="st017" width="170.08" height="170.08" />
        <polygon className="st1" points="19.68,48.55 19.68,22.3 19.63,22.3 15.59,26.49 15.54,26.49 15.54,20.48 20.85,14.25 25.45,14.25
	25.45,48.55 "/>
        <polygon className="st1" points="29.29,48.55 36.98,18.96 28.08,18.96 28.08,14.25 42.55,14.25 42.55,19.56 35.01,48.55 " />
        <g>
          <path className="st1" d="M128.27,103.15c0-11.94-9.5-21.7-21.34-22.13c-3.05-8.6-11.26-14.78-20.89-14.78
		c-9.63,0-17.84,6.18-20.89,14.78c-11.84,0.43-21.33,10.19-21.33,22.13c0,7.09,3.35,13.4,8.55,17.46c-0.56,1.95-0.88,4.02-0.88,6.15
		c0,12.21,9.94,22.15,22.15,22.15c4.64,0,8.94-1.43,12.5-3.88c3.52,2.36,7.75,3.73,12.29,3.73c12.21,0,22.15-9.94,22.15-22.15
		c0-2.09-0.3-4.11-0.84-6.03C124.93,116.53,128.27,110.22,128.27,103.15 M103.74,88.39c0,8.87-6.56,16.21-15.08,17.48
		c-0.14-0.89-0.23-1.79-0.23-2.72c0-8.87,6.56-16.21,15.08-17.48C103.65,86.56,103.74,87.47,103.74,88.39 M86.13,139.29
		c-3.31-3.22-5.38-7.71-5.38-12.67c0-4.89,2-9.32,5.21-12.53c3.31,3.22,5.38,7.71,5.38,12.68
		C91.34,131.65,89.35,136.08,86.13,139.29 M68.59,85.67c8.52,1.27,15.08,8.61,15.08,17.48c0,0.93-0.09,1.83-0.23,2.72
		c-8.52-1.27-15.08-8.61-15.08-17.48C68.35,87.47,68.45,86.56,68.59,85.67 M81.78,111.07c-2.91,5.79-8.89,9.78-15.8,9.78
		c-2.93,0-5.69-0.73-8.13-2c2.91-5.79,8.89-9.78,15.8-9.78C76.58,109.07,79.34,109.8,81.78,111.07 M90.26,110.95
		c2.45-1.28,5.23-2.02,8.18-2.02c6.96,0,12.97,4.05,15.86,9.9c-2.45,1.29-5.23,2.02-8.18,2.02
		C99.16,120.84,93.15,116.8,90.26,110.95 M86.05,70.7c7.25,0,13.49,4.38,16.22,10.64c-7.2,1.27-13.2,6.02-16.22,12.45
		c-3.01-6.43-9.02-11.18-16.22-12.45C72.56,75.09,78.8,70.7,86.05,70.7 M48.29,103.15c0-9.12,6.93-16.64,15.79-17.59
		c-0.12,0.93-0.19,1.87-0.19,2.83c0,6.48,2.79,12.31,7.24,16.36c-7.35,0.84-13.62,5.28-16.98,11.51
		C50.56,113.03,48.29,108.36,48.29,103.15 M55.96,126.76c0-1.26,0.13-2.48,0.39-3.67c2.91,1.41,6.18,2.21,9.63,2.21
		c3.88,0,7.52-1,10.7-2.76c-0.25,1.32-0.38,2.68-0.38,4.07c0,5.98,2.39,11.42,6.26,15.41c-2.62,1.53-5.65,2.43-8.9,2.43
		C63.89,144.45,55.96,136.52,55.96,126.76 M116.14,126.62c0,9.75-7.94,17.69-17.69,17.69c-3.17,0-6.15-0.85-8.72-2.32
		c3.76-3.97,6.08-9.33,6.08-15.22c0-1.45-0.15-2.87-0.41-4.24c3.18,1.77,6.84,2.78,10.73,2.78c3.46,0,6.74-0.8,9.66-2.22
		C116.01,124.22,116.14,125.41,116.14,126.62 M101.1,104.63c4.36-4.05,7.1-9.83,7.1-16.23c0-0.96-0.07-1.9-0.19-2.83
		c8.87,0.95,15.8,8.47,15.8,17.59c0,5.18-2.25,9.83-5.81,13.07C114.66,109.98,108.43,105.51,101.1,104.63"/>
        </g>
        <g>
          <path className="st1" d="M57.45,15.82c0.82,0,1.18,0.38,1.18,1.16v3.29c0,0.78-0.36,1.18-1.18,1.18h-0.89v-5.63H57.45z M54.16,28.16
		h2.4v-4.85h1.16c2.43,0,3.31-1.22,3.31-3.23v-3.02c0-2-0.89-3.21-3.31-3.21h-3.56V28.16z"/>
          <path className="st1" d="M65.02,17.89h0.02l0.95,5.74h-1.92L65.02,17.89z M63.34,28.16l0.42-2.62h2.55l0.44,2.62h2.47l-2.72-14.3h-2.74
		l-2.7,14.3H63.34z"/>
          <path className="st1" d="M73.42,15.82c0.82,0,1.18,0.38,1.18,1.16v2.11c0,0.76-0.36,1.18-1.18,1.18h-0.8v-4.45H73.42z M74.87,28.16
		h2.53l-2.3-6.5c1.37-0.32,1.92-1.22,1.92-2.76v-1.83c0-2-0.89-3.21-3.31-3.21h-3.5v14.3h2.4V21.6L74.87,28.16z"/>
          <polygon className="st1" points="77.42,15.82 79.41,15.82 79.41,28.16 81.83,28.16 81.83,15.82 83.81,15.82 83.81,13.86 77.42,13.86
		"/>
          <polygon className="st1" points="89.36,28.16 91.47,28.16 91.47,13.86 89.47,13.86 89.47,22.06 86.87,13.86 84.51,13.86 84.51,28.16
		86.53,28.16 86.53,19.17 	"/>
          <polygon className="st1" points="93.26,28.16 99,28.16 99,26.2 95.67,26.2 95.67,21.85 98.05,21.85 98.05,19.91 95.67,19.91
		95.67,15.82 99,15.82 99,13.86 93.26,13.86 	"/>
          <path className="st1" d="M103.49,15.82c0.82,0,1.18,0.38,1.18,1.16v2.11c0,0.76-0.36,1.18-1.18,1.18h-0.8v-4.45H103.49z M104.95,28.16
		h2.53l-2.3-6.5c1.37-0.32,1.92-1.22,1.92-2.76v-1.83c0-2-0.89-3.21-3.31-3.21h-3.5v14.3h2.4V21.6L104.95,28.16z"/>
          <path className="st1" d="M111.99,28.33c2.45,0,3.5-1.39,3.5-3.35v-0.7c0-1.58-0.63-2.38-1.94-3.71l-1.54-1.52
		c-0.7-0.68-1.14-1.14-1.14-1.94v-0.3c0-0.8,0.46-1.18,1.14-1.18c0.77,0,1.16,0.4,1.16,1.22v1.24h2.19v-1.16
		c0-2.07-0.96-3.25-3.37-3.25c-2.34,0-3.37,1.37-3.37,3.14v0.49c0,1.64,0.63,2.47,2,3.82l1.41,1.41c0.74,0.7,1.08,1.2,1.08,2.02
		v0.55c0,0.76-0.34,1.27-1.1,1.27c-0.82,0-1.18-0.46-1.18-1.27v-1.96h-2.24v1.92C108.59,26.95,109.54,28.33,111.99,28.33"/>
          <polygon className="st1" points="121.67,28.16 124.2,28.16 121.35,20.29 124.14,13.86 121.78,13.86 119.16,20.14 119.16,13.86
		116.76,13.86 116.76,28.16 119.16,28.16 119.16,21.2 	"/>
          <path className="st1" d="M128.69,17.89h0.02l0.95,5.74h-1.92L128.69,17.89z M127.01,28.16l0.42-2.62h2.55l0.44,2.62h2.47l-2.72-14.3
		h-2.74l-2.7,14.3H127.01z"/>
          <path className="st1" d="M136.28,15.82h0.82c0.8,0,1.16,0.38,1.16,1.16v1.75c0,0.76-0.36,1.16-1.14,1.16h-0.84V15.82z M137.32,21.72
		c0.86,0,1.22,0.4,1.22,1.16v2.15c0,0.76-0.39,1.16-1.2,1.16h-1.05v-4.47H137.32z M133.88,28.16h3.73c2.42,0,3.33-1.12,3.33-3.14
		v-1.9c0-1.31-0.59-2.11-1.79-2.38c0.98-0.32,1.41-1.08,1.41-2.28V17c0-2.02-0.8-3.14-3.21-3.14h-3.48V28.16z"/>
          <polygon className="st1" points="142.13,28.16 147.86,28.16 147.86,26.2 144.53,26.2 144.53,21.85 146.91,21.85 146.91,19.91
		144.53,19.91 144.53,15.82 147.86,15.82 147.86,13.86 142.13,13.86 	"/>
          <path className="st1" d="M152.35,15.82c0.82,0,1.18,0.38,1.18,1.16v2.11c0,0.76-0.36,1.18-1.18,1.18h-0.8v-4.45H152.35z M153.81,28.16
		h2.53l-2.3-6.5c1.37-0.32,1.92-1.22,1.92-2.76v-1.83c0-2-0.89-3.21-3.31-3.21h-3.5v14.3h2.4V21.6L153.81,28.16z"/>
          <polygon className="st1" points="54.16,48.57 56.57,48.57 56.57,42.27 58.97,42.27 58.97,40.33 56.57,40.33 56.57,36.23 59.79,36.23
		59.79,34.27 54.16,34.27 	"/>
          <path className="st1" d="M63.84,48.74c2.55,0,3.65-1.64,3.65-3.86v-6.92c0-2.24-1.1-3.86-3.65-3.86c-2.53,0-3.63,1.62-3.63,3.86v6.92
		C60.21,47.1,61.31,48.74,63.84,48.74 M63.84,46.74c-0.84,0-1.22-0.61-1.22-1.48v-7.68c0-0.86,0.38-1.48,1.22-1.48
		c0.86,0,1.24,0.61,1.24,1.48v7.68C65.09,46.13,64.71,46.74,63.84,46.74"/>
          <path className="st1" d="M72.07,36.23c0.82,0,1.18,0.38,1.18,1.16v2.11c0,0.76-0.36,1.18-1.18,1.18h-0.8v-4.45H72.07z M73.52,48.57
		h2.53l-2.3-6.5c1.37-0.32,1.92-1.22,1.92-2.76v-1.83c0-2-0.89-3.21-3.31-3.21h-3.5v14.3h2.4v-6.56L73.52,48.57z"/>
          <polygon className="st1" points="85.19,48.57 87.61,48.57 87.61,34.27 85.19,34.27 85.19,40.33 82.87,40.33 82.87,34.27 80.46,34.27
		80.46,48.57 82.87,48.57 82.87,42.27 85.19,42.27 	"/>
          <path className="st1" d="M91.72,38.3h0.02l0.95,5.74h-1.92L91.72,38.3z M90.04,48.57l0.42-2.62h2.55l0.44,2.62h2.47l-2.72-14.3h-2.74
		l-2.7,14.3H90.04z"/>
          <polygon className="st1" points="101.76,48.57 103.87,48.57 103.87,34.27 101.87,34.27 101.87,42.48 99.27,34.27 96.91,34.27
		96.91,48.57 98.94,48.57 98.94,39.59 	"/>
          <path className="st1" d="M109.37,34.27h-3.71v14.3h3.71c2.57,0,3.48-1.64,3.48-3.84v-6.62C112.85,35.9,111.95,34.27,109.37,34.27
		 M108.07,46.61V36.23h1.01c0.99,0,1.37,0.59,1.37,1.43v7.49c0,0.86-0.38,1.46-1.37,1.46H108.07z"/>
          <polygon className="st1" points="114.23,48.57 119.5,48.57 119.5,46.61 116.63,46.61 116.63,34.27 114.23,34.27 	" />
          <rect x="120.17" y="34.27" className="st1" width="2.4" height="14.3" />
          <polygon className="st1" points="128.82,48.57 130.93,48.57 130.93,34.27 128.92,34.27 128.92,42.48 126.33,34.27 123.97,34.27
		123.97,48.57 125.99,48.57 125.99,39.59 	"/>
          <path className="st1" d="M135.93,42.84h1.14v2.53c0,0.93-0.4,1.37-1.18,1.37c-0.82,0-1.18-0.61-1.18-1.48v-7.68
		c0-0.86,0.38-1.48,1.24-1.48c0.86,0,1.2,0.51,1.2,1.39v1.46h2.19v-1.27c0-2.11-0.78-3.59-3.44-3.59c-2.53,0-3.61,1.62-3.61,3.86
		v6.92c0,2.21,0.95,3.86,3.02,3.86c1.31,0,1.98-0.65,2.3-1.73v1.56h1.73v-7.63h-3.42V42.84z"/>
        </g>
      </svg>
    </>
  );
};
